import React from 'react'
import { Container, Image, Table } from 'react-bootstrap'
import { useCompanyContext } from '../../context/CompanyContext';

const CompaniesPage = () => {
    const { companies } = useCompanyContext();

    return (
			<Container>
        <b className='companies-header'>Your Companies</b>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th></th>
							<th>Company Name</th>
							<th>Location</th>
							<th>Manager Name</th>
							<th>Manager Email</th>
							<th>Description</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{companies.map(company => (
							<tr>
								<td><Image src={company.company.logo}></Image></td>
								<td>{company.company.name}</td>
								<td>{company.company.location}</td>
								<td>{company.company.manager.first_name + " " + company.company.manager.last_name}</td>
								<td>{company.company.manager.email}</td>
								<td>{company.company.description}</td>
								<td>{company.company.role}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Container>
    )
}

export default CompaniesPage