import React, { useState } from "react";
import { ReactComponent as Select } from "../assets/Select.svg";
import { ReactComponent as Cable } from "../assets/Cable.svg";
import { ReactComponent as Pole } from "../assets/Pole.svg";
import { ReactComponent as Pop } from "../assets/Pop.svg";
import { ReactComponent as Customer } from "../assets/Customer.svg";
import { ReactComponent as Search } from "../assets/Search.svg";
import { ReactComponent as SatelliteView } from "../assets/Satellite.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Toolbar = (props) => {
    const { selectedTool, setSelectedTool, discardTempChanges, isWindowOpen } = props;
    const [isToolbarShrink, setIsToolbarShrink] = useState(true);

    const handleToolSelect = (tool) => {
        if (isWindowOpen) {
            console.log("cannot select a tool when window is open");
        } else {
            setSelectedTool(tool);
            discardTempChanges();
        }
    };

    const toggleToolbar = () => {
        setIsToolbarShrink(prev => !prev);
    };

    const renderTooltip = (displayText) => (
        <Tooltip style={isToolbarShrink ? {} : {display: "none"}} className="button-tooltip">
            {displayText}
        </Tooltip>
    );

    return (
        <aside className={`toolbar ${isToolbarShrink ? 'shrink' : ''}`}>
            <div className="toolbar-header">
                <div className="company-info">
                    <h1>Company</h1>
                    <p>Admin</p>
                    <div className="support-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" id="mdi-chat" viewBox="0 0 24 24">
                            <path d="M12,3C17.5,3 22,6.58 22,11C22,15.42 17.5,19 12,19C10.76,19 9.57,18.82 8.47,18.5C5.55,21 2,21 2,21C4.33,18.67 4.7,17.1 4.75,16.5C3.05,15.07 2,13.13 2,11C2,6.58 6.5,3 12,3Z" />
                        </svg>
                        <span>Ask for Support</span>
                    </div>
                </div>
                <hr className="tinyline"></hr>
            </div>
            <button id="toggle-toolbar" className="toggle-btn" onClick={toggleToolbar}>
                {isToolbarShrink ? '>' : '<'}
            </button>
            <div className="toolbar-tools">
                <div className="toolbtnscontainer">
                    {['Select', 'Cable', 'Pole', 'POP', 'Customer', 'Search'].map((tool, index) => (
                        <OverlayTrigger placement={"right"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip(tool)}>
                            <div 
                                key={index} 
                                className={`toolbtns ${selectedTool === tool ? 'pressed' : ''} ${isWindowOpen ? 'disabled' : ''}`} 
                                onClick={() => handleToolSelect(tool)}
                            >
                                {tool === 'Select' && <Select></Select>}
                                {tool === 'Cable' && <Cable></Cable>}
                                {tool === 'Pole' && <Pole></Pole>}
                                {tool === 'POP' && <Pop></Pop>}
                                {tool === 'Customer' && <Customer></Customer>}
                                {tool === 'Search' && <Search></Search>}
                                <span>{tool}</span>
                            </div>
                        </OverlayTrigger>
                    ))}
                </div>
                <hr className="tinyline"></hr>
                <div className="toolbtnscontainer">
                    {['Satellite'].map((tool, index) => (
                        <OverlayTrigger placement={"right"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip(tool)}>
                            <div 
                                key={index} 
                                className={`toolbtns ${selectedTool === tool ? 'pressed' : ''} ${isWindowOpen ? 'disabled' : ''}`} 
                                // onClick={() => handleToolSelect(tool)} // removed for now as it doesnt have properties
                            >
                                {tool === 'Satellite' && <SatelliteView></SatelliteView>}
                                <span>{tool}</span>
                            </div>
                        </OverlayTrigger>
                    ))}
                </div>
            </div>
        </aside>
    );
};

export default Toolbar;
