import React, { useEffect, useState, useRef } from 'react';
import { Container, Modal, OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap';
import { Stage, Layer, Rect, Line } from 'react-konva';
import { ReactComponent as ConnectCable } from "../assets/connect.svg";
import { ReactComponent as DisconnectCable } from "../assets/disconnect.svg";
import { ReactComponent as AddBox } from "../assets/add-box.svg";
import DraggableModalDialog from './DraggableModalDialog';
import { Resizable } from "re-resizable";
import { handleWheel as handleZoom } from './ZoomHandler';
import ContextMenu from './ContextMenu';

const BASE_URL = process.env.REACT_APP_API_URL;

const KonvaPoleWindow = (props) => {
    const { pole, boxes, tempBoxes, showBoxWindow, closeKonvaPoleWindow, setShowBoxWindowBackButton, setIsEdittingBox, setSelectedBox, zIndex, selectedTool, setSelectedTool, discardTempChanges, submitTempBox } = props;
    const startX = 5;
    const startYs = [100, 150, 200, 250, 300, 350];
    const defaultWindowWidth = window.innerWidth / 2;
    const defaultWindowHeight = window.innerHeight / 2;
    const [connectMode, setConnectMode] = useState(false); // State to track Connect mode
    const [removeMode, setRemoveMode] = useState(false);
    const [addBoxMode, setAddBoxMode] = useState(false);
    const [inspectBoxMode, setInspectBoxMode] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [cableEnds, setCableEnds] = useState([]);
    const nodeRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(defaultWindowWidth);
    const [windowHeight, setWindowHeight] = useState(defaultWindowHeight);
    const [stageScale, setStageScale] = useState(1);
    const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
    const filteredBoxes = boxes.filter(box => box.connected_pole === pole.id);
    const filteredTempBoxes = tempBoxes.filter(box => box.connected_pole === pole.id);

    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        options: []
    });

    const handleShapeContextMenu = (e, box, cable) => {
        e.evt.preventDefault();

		const isBox = box !== null;

		setContextMenu({
            visible: true,
            x: e.evt.layerX + 5,
            y: e.evt.layerY + 45,
            options: isBox ? [
                { label: 'Open', onClick: () => {
                    openBoxWindow(box);
                }},
                { label: 'Open In New Window', onClick: () => {
                    setSelectedBox(box);
                    setTimeout(() => {
                        showBoxWindow();
                    }, 0);
                }},
                { label: 'Edit', onClick: () => {
                    setSelectedTool("Box");
                    setTimeout(() => {
                        setIsEdittingBox(true);
                        setSelectedBox(box);
                    }, 0);
                }},
                { label: 'Delete', onClick: () => console.log('Delete', box) }
            ] :
            [
                { label: 'Auto Connect', onClick: () => console.log('Auto Connect', cable) },
                { label: 'Edit', onClick: () => console.log('Edit', cable) },
                { label: 'Delete', onClick: () => console.log('Delete', cable) }
            ]
		});
	};

    const renderTooltip = (displayText) => (
        <Tooltip className="button-tooltip">
            {displayText}
        </Tooltip>
    );

    const onResize = (event, direction, ref, delta) => {
        const newWidth = ref.style.width;
        const newHeight = ref.style.height;

        const newWindowHeight = parseInt(newHeight, 10) - 50;
        const newWindowWidth = parseInt(newWidth, 10) - 10;
    
        if (newWindowHeight >= 250) {
            setWindowHeight(newWindowHeight);
        }

        if (newWindowWidth >= 390) {
            setWindowWidth(newWindowWidth);
        }
    };

    useEffect(() => {
        getCableEnds();
    }, [pole]);

    useEffect(() => {
        setSelectedTool(addBoxMode ? "Box" : "Select");
    }, [addBoxMode]);

    const handleConnectModeToggle = () => {
        setConnectMode(!connectMode);
        setRemoveMode(false);
        setAddBoxMode(false);
        setInspectBoxMode(false);
    };

    const handleRemoveModeToggle = () => {
        setRemoveMode(!removeMode);
        setConnectMode(false);
        setAddBoxMode(false);
        setInspectBoxMode(false);
    };

    const handleAddBoxModeToggle = () => {
        setAddBoxMode(!addBoxMode);
        setSelectedBox(null);
        setInspectBoxMode(false);
        setRemoveMode(false);
        setConnectMode(false);
    };

    const handleInspectBoxModeToggle = () => {
        setInspectBoxMode(!inspectBoxMode);
        setAddBoxMode(false);
        setRemoveMode(false);
        setConnectMode(false);
    };

    const openBoxWindow = (box) => {
        setSelectedBox(box);
        setTimeout(() => {
            closeKonvaPoleWindow();
            setShowBoxWindowBackButton(true);
            showBoxWindow();
        }, 0);
    }

    const handleObjectDoubleClick = (box, cableEnd, index) => {

        const isBox = box !== null;

        if (isBox) {
            // box logic
            openBoxWindow(box);
        } else {
            // cableEnd logic
            if (removeMode) {
                const cableEndId = cableEnds[index].id;
                console.log(cableEndId);
                console.log(cableEnds[index]);
                updateBoxConnection(cableEndId, null);
            }
        }
    };

    const getCableEnds = async () => {
        try {
            const response = await fetch(`${BASE_URL}/map/pole_cable_ends/${pole.id}/`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCableEnds(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching boxes:', error);
            setIsLoading(false);
        }
    }

    const handleDragEnd = (index, event) => {

        // Calculate the offset between the starting position and the dropped position
        const offsetX = startX + 150 // Adjust this value based on the initial x position of the line
        const offsetY = startYs[index]  // Adjust this value based on the initial y position of the line

        const x = event.target.x() + offsetX;
        const y = event.target.y() + offsetY;

        console.log(x,y);
        
        // Find the box the line is dropped onto
        const droppedBox = boxes.find((box, boxIndex) => {
            // const { x: boxX, y: boxY, width, height } = box;

            return (
                x >= 500 && x <= 500 + 100 &&
                y >= (50+(boxIndex * 150)) && y <= (50+(boxIndex * 150)) + 100
            );
        });

        if (droppedBox) {
            const boxIndex = boxes.indexOf(droppedBox);
            const lineId = cableEnds[index].id;
            const bid = droppedBox.id;
            console.log(boxIndex);
            console.log(bid);
            console.log(lineId);
            updateBoxConnection(lineId, bid);
        }
    };

    const updateBoxConnection = async (cable, box) => {

        const requestBody = {
            cable_end_id: cable,
            box_id: box
        };

        try {
            const response = await fetch(`${BASE_URL}/map/update_box_connection/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Update the cableEnds state after successful API call
        const updatedCableEnds = cableEnds.map(cableEnd => {
            if (cableEnd.id === cable) {
                return { ...cableEnd, box_connection: box };
            }
            return cableEnd;
        });
        setCableEnds(updatedCableEnds);

    } catch (error) {
        console.error('Error updating cable connection:', error);
        }
    };

    const handleWheel = (e) => {
        handleZoom(e, setStageScale, setStagePosition);
    };

    const handleStageClick = (e) => {
        if (selectedTool === "Box") {
            submitTempBox();
        }
    }

    return (
        <Modal 
            className="konva-modal"
            id="konva-pole-modal"
            show={props.show}
            onHide={props.onHide}
            backdrop={false}
            keyboard={false}
            size="lg"
            animation={false}
            style={{width: "fit-content", height: "fit-content", zIndex: zIndex}}
            dialogAs={DraggableModalDialog}
            nodeRef={nodeRef}
        >
            <Resizable className="konva-window-resizable" id="konva-pole-window-resizable" onResize={onResize}>
                <Modal.Header className="konva-window-header drag-handle" closeButton ref={nodeRef}>
                    <Container className="konva-window-toolbar">
                        <OverlayTrigger placement={"top"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip("Connect Cable")}>
                            <div className={connectMode ? "toolbar-button pressed" : "toolbar-button"} onClick={handleConnectModeToggle}>
                                <ConnectCable></ConnectCable>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger placement={"top"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip("Disconnect Cable")}>
                            <div className={removeMode ? "toolbar-button pressed" : "toolbar-button"} onClick={handleRemoveModeToggle}>
                                <DisconnectCable></DisconnectCable>
                            </div>
                        </OverlayTrigger>
                        <OverlayTrigger placement={"top"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip("Add Box")}>
                            <div className={addBoxMode ? "toolbar-button pressed" : "toolbar-button"} onClick={handleAddBoxModeToggle}>
                                <AddBox></AddBox>
                            </div>
                        </OverlayTrigger>
                    </Container>
                </Modal.Header>

                <Modal.Body className="konva-window-body">
                    {isLoading ? (
                        <Placeholder animation="glow">
                            <Placeholder style={{width: windowWidth, height: windowHeight, backgroundColor: "white"}} xs={12}/>
                        </Placeholder>
                    ) : (
                    <Stage
                        className="konva-stage"
                        width={windowWidth}
                        height={windowHeight}
                        draggable
                        scaleX={stageScale}
                        scaleY={stageScale}
                        x={stagePosition.x}
                        y={stagePosition.y}
                        onWheel={handleWheel}
                        onClick={handleStageClick}
                    >
                        <Layer className="konva-layer">
                            {/* Render grid lines */}
                            {[...Array(200).keys()].map((x, index) => (
                                <Line
                                    x={-5000}
                                    y={-5000}
                                    key={`vertical-${index}`}
                                    points={[index * 50, 0, index * 50, 9950]} // value should be 50 * number in array - 50
                                    stroke="#ccc"
                                    strokeWidth={0.5}
                                />
                            ))}
                            {[...Array(200).keys()].map((y, index) => (
                                <Line
                                    x={-5000}
                                    y={-5000}
                                    key={`horizontal-${index}`}
                                    points={[0, index * 50, 9950, index * 50]} // value should be 50 * number in array - 50
                                    stroke="#ccc"
                                    strokeWidth={0.5}
                                />
                            ))}

                            {filteredBoxes.map((box, index) => (
                                <Rect
                                    key={index}
                                    x={550}
                                    y={50+(index * 150)}
                                    width={100}
                                    height={100}
                                    fill="lightgray"
                                    onDblClick={() => handleObjectDoubleClick(box, null, index)} // Handle double click event
                                    onContextMenu={(e) => handleShapeContextMenu(e, box, null)}
                                />
                            ))}

                            {filteredTempBoxes.map((box, index) => (
                                <Rect
                                    key={filteredBoxes.length + index}
                                    x={550}
                                    y={50+((filteredBoxes.length + index) * 150)}
                                    width={100}
                                    height={100}
                                    fill="lightgray"
                                />
                            ))}

                            {cableEnds.map((cableEnd, index) => (
                                <React.Fragment key={index}>
                                    {cableEnd.box_connection ? (
                                        // Render line connecting cable end to box if there's a connection
                                        <Line
                                            // 500 because it's the x coordinates of the rects == boxes 
                                            // boxIndex * 150 + 50 is the first point of the rect and it has a height of 100 so if add a 50 it will be the center
                                            points={[startX, startYs[index], 550, 50 + (filteredBoxes.findIndex(box => box.id === cableEnd.box_connection) * 200 + 50)]}
                                            stroke="black"
                                            tension={0.5}
                                            strokeWidth={5}
                                            // draggable
                                            onDblClick={() => handleObjectDoubleClick(null, cableEnd, index)} // Handle double click event
                                            onContextMenu={(e) => handleShapeContextMenu(e, null, null)} // TODO add cable info
                                        />
                                    ) : (
                                        // Render cable End
                                        <Line
                                            points={[startX, startYs[index], 150, 100 + (index * 50)]}
                                            stroke="black"
                                            tension={0.5}
                                            strokeWidth={5}
                                            draggable={connectMode}
                                            onDragEnd={event => handleDragEnd(index, event)}
                                            onContextMenu={(e) => handleShapeContextMenu(e, null, null)} // TODO add cable info
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </Layer>
                    </Stage>
                    )}
                </Modal.Body>
            </Resizable>
            {contextMenu.visible && 
                <ContextMenu 
                    x={contextMenu.x} 
                    y={contextMenu.y}
                    options={contextMenu.options}
                    onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                />
            }
        </Modal>
    );
};

export default KonvaPoleWindow
