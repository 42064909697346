import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ConnectorSpecificationWindow = ({ show, onHide, onSubmit, connectorTypes, paintTypes }) => {
  const [selectedConnectorType, setSelectedConnectorType] = useState('');
  const [selectedPaintType, setSelectedPaintType] = useState('');
  const [canClose, setCanClose] = useState(false);

  const handleConnectorTypeChange = (e) => {
    setSelectedConnectorType(e.target.value);
  };

  const handlePaintTypeChange = (e) => {
    setSelectedPaintType(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedConnectorType && selectedPaintType) {
      onSubmit(selectedConnectorType, selectedPaintType);
      onHide(); // This will only happen if both fields are filled
    }
  };

  useEffect(() => {
    // Check if both fields are filled
    if (selectedConnectorType && selectedPaintType) {
      setCanClose(true);
    } else {
      setCanClose(false);
    }
  }, [selectedConnectorType, selectedPaintType]);

  return (
    <Modal 
        show={show} 
        onHide={() => canClose && onHide()} // Only allow closing if both fields are filled
        centered 
        style={{ zIndex: 2000 }} // Increase z-index to ensure it appears above the BoxContentWindow
        size="md" // Set size to medium; you can adjust this to "sm" or "lg" as needed
        backdrop="static" // Prevent closing the modal by clicking outside of it
        keyboard={false} // Prevent closing the modal with the ESC key
    >
      <Modal.Header closeButton={canClose}> {/* Close button only if both fields are filled */}
        <Modal.Title>Select Connector Specifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formConnectorType">
            <Form.Label>Connector Type</Form.Label>
            <Form.Control as="select" value={selectedConnectorType} onChange={handleConnectorTypeChange}>
              <option value="">Select a type</option>
              {connectorTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPaintType" className="mt-3">
            <Form.Label>Connector Paint Type</Form.Label>
            <Form.Control as="select" value={selectedPaintType} onChange={handlePaintTypeChange}>
              <option value="">Select a paint type</option>
              {paintTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.type} - {type.color}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={canClose ? onHide : null} disabled={!canClose}>
          Cancel
        </Button> */}
        <Button variant="primary" onClick={handleSubmit} disabled={!canClose}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectorSpecificationWindow;
