import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";

import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";
import { CompanyDataProvider } from "./context/CompanyContext";

import MapPage from "./pages/Map/MapPage";
import CompaniesPage from "./pages/Companies/CompaniesPage";
import Header from "./components/Header";
import HomePage from "./pages/Home/HomePage";
import AboutUsPage from "./pages/About-us/AboutUsPage";
import ContactUsPage from "./pages/Contact-us/ContactUsPage";

// import CableLine from "./components/PatchCord";
// import CableLineTest from "./components/CableLineTest";
// import PatchCord from "./components/PatchCord";

// import Splitter from "./components/Splitter";

function App() {

  const selectedTheme = localStorage.getItem("selectedTheme");
  document.querySelector("body").setAttribute("data-theme", selectedTheme === "dark" ? "dark" : "light");

  return (
    <Router>
      <div className="App">
        <AuthProvider>
        <CompanyDataProvider>
        <DataProvider>
          <Header />
          <Routes>
          <Route element={<HomePage />} path="/" />
          <Route element={<AboutUsPage />} path="/about-us" />
          <Route element={<ContactUsPage />} path="/contact-us" />
          {/* <Route element={<PatchCord initialPoints={[50, 300, 650, 300]} />} path="/cable-line" /> */}
          {/* <Route element={<CableLineTest />} path="/cable-line" />  Add the CableLine route */}
          {/* <Route element={<Splitter />} path="/splitter" /> */}
          <Route element={<PrivateRoute />}>
            <Route element={<MapPage />} path="/map/:id" />
            <Route element={<CompaniesPage />} path="/companies" />
          </Route>
        </Routes>
        </DataProvider>
        </CompanyDataProvider>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;