import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LoginModal from './LoginModal';
import ThemeSlider from './ThemeSlider';
import { ReactComponent as Check } from "../assets/check.svg";
import { useCompanyContext } from '../context/CompanyContext';
import { Image } from 'react-bootstrap';

const Header = () => {
  const {user, logoutUser} = useContext(AuthContext)
  const { companies } = useCompanyContext();
  const [showLogin, setShowLogin] = useState(false);
  const [companiesDropdownActive, setCompaniesDropdownActive] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(localStorage.getItem("selectedCompanyId"));

	const toggleCompaniesDropdown = e => {
		if (companiesDropdownActive === true) setCompaniesDropdownActive(false);
		else setCompaniesDropdownActive(true);
	};

  const selectCompany = e => {
    const clickedCompany = e.target.getAttribute("company-id"); // should be id of the company
		if (selectedCompanyId !== clickedCompany) {
      localStorage.setItem("selectedCompanyId", clickedCompany);
      setSelectedCompanyId(clickedCompany);
      // TODO refresh data on pages
      refreshComponents(clickedCompany);
    }
	};

  const refreshComponents = (clickedCompany) => { // TODO
      // currently will only refresh the application
      window.location.reload();
  };

  useEffect(() => {
    setSelectedCompanyId(localStorage.getItem("selectedCompanyId"));
  }, [localStorage.getItem("selectedCompanyId")]);

  return (
    <Navbar expand="lg" sticky="top">
      <Container fluid className="px-5">
        <Navbar.Brand as={Link} to="/">
          <Image className="logo" fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <Nav variant="underline" defaultActiveKey="/">
            {/* <Nav.Link eventKey="/" as={Link} to="/" >Home</Nav.Link>
            <Nav.Link eventKey="/map" as={Link} to={"/map/" + localStorage.getItem("selectedCompanyId")} hidden={!user}>Map</Nav.Link>
            <Nav.Link eventKey="/about-us" as={Link} to="/about-us">Fibereon</Nav.Link>
            <Nav.Link eventKey="/contact-us" as={Link} to="/contact-us">Contact Us</Nav.Link> */}
            
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to={"/map/" + localStorage.getItem("selectedCompanyId")} hidden={!user}>Map</Nav.Link>
            <Nav.Link as={Link} to="/about-us">Fibereon</Nav.Link>
            <Nav.Link as={Link} to="/contact-us">Contact Us</Nav.Link>

            <NavDropdown title="Profile" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/settings" hidden={!user}>Settings</NavDropdown.Item>
              <NavDropdown title="Companies" className="nav-companies-dropdown" id="basic-nav-dropdown" onClick={toggleCompaniesDropdown} hidden={!user}>
              </NavDropdown>
              <NavDropdown.Divider hidden={!companiesDropdownActive || !user} />
              {companies.map(company => (
                <NavDropdown.Item onClick={selectCompany} hidden={!companiesDropdownActive || !user} company-id={company.company.id}>{company.company.name}<Check hidden={!(selectedCompanyId === company.company.id + "")}></Check></NavDropdown.Item>
              ))}
              <NavDropdown.Item as={Link} to="/companies" hidden={!companiesDropdownActive || !user}>View Companies</NavDropdown.Item>
              <NavDropdown.Divider hidden={!companiesDropdownActive || !user} />
              <ThemeSlider></ThemeSlider>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => user ? logoutUser() : setShowLogin(true)}>
                {user ? "Log Out" : "Sign In"}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <LoginModal show={showLogin} onHide={() => setShowLogin(false)}></LoginModal>
    </Navbar>
  );
}

export default Header