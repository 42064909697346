import React, { useRef, useEffect } from 'react';

const ContextMenu = ({ x, y, options, onClose }) => {
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
		className='context-menu'
      	ref={menuRef}
		style={{
			top: y,
			left: x,
		}}
    >
		<ul className='context-menu-ul'>
        	{options.map((option, index) => (
				<li
					key={index}
					className='context-menu-li'
					onClick={() => {
						option.onClick();
						onClose();
					}}
				>
					{option.label}
				</li>
        	))}
      	</ul>
    </div>
  );
};

export default ContextMenu;
