import React, { useState, useEffect } from 'react';
import { Line, Circle } from 'react-konva';

const SmartLine = ({ initialLines, stageRef, lineColor, updateLines, onClick, showCircles, isUnsaved }) => {

    const [lines, setLines] = useState(initialLines);
    //const [showCircles, setShowCircles] = useState(false);
    // const [draggedMidPoint, setDraggedMidPoint] = useState(null);
    const [tempLines, setTempLines] = useState([]);
    const [draggedPosition, setDraggedPosition] = useState(null); // position of the circle being tracked
    const [draggedIndex, setDraggedIndex] = useState(null); // index of the line containing the circle being dragged
    const [draggedPoint, setDraggedPoint] = useState(null);


    // useEffect(() => {
    //   setShowCircles(selected);
    // }, [selected]);

    useEffect(() => {
      setLines(initialLines);  // Update the lines state whenever initialLines prop changes
    }, [initialLines]);

    const calculateMidPoint = (x1, y1, x2, y2) => ({
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
    });

    // const arePointsCollinear = (x1, y1, x2, y2, x3, y3) => {
    //     return Math.abs(((y2 - y1) / (x2 - x1)) - ((y3 - y2) / (x3 - x2))) < 0.2;
    // };

    const arePointsCollinear = (x1, y1, x2, y2, x3, y3) => {
      const result = ((y1 - y2) / (x2 - x1)) - ((y2 - y3) / (x3 - x2));
      return result < 0.2 && result > 0;
  };

    const handleMidPointDragMove = (e, lineIndex) => {
        const newLines = [...lines];
        const [x1, y1, x2, y2] = newLines[lineIndex];
        const midX = e.target.x();
        const midY = e.target.y();
        
        const newTempLines = [...lines];
        
        // Split the current line into two new temporary lines at the midpoint
        newTempLines.splice(lineIndex, 1, [x1, y1, midX, midY], [midX, midY, x2, y2]);
      
        setTempLines(newTempLines);
    };
      
    const handleMidPointDragEnd = (e, lineIndex) => {
        const newLines = [...lines];
        const [x1, y1, x2, y2] = newLines[lineIndex];
        const midX = e.target.x();
        const midY = e.target.y();
    
        // Split the current line into two new lines at the midpoint
        newLines.splice(lineIndex, 1, [x1, y1, midX, midY], [midX, midY, x2, y2]);
    
        setLines(newLines);
        setTempLines([]); // Clear the temporary lines
        // setDraggedMidPoint(null);
        updateLines(newLines);
    };
    
    const handleExtremityDragEnd = (e, lineIndex, pointIndex) => {
        const newLines = [...lines];
        const [x1, y1, x2, y2] = newLines[lineIndex];
        const newX = e.target.x();
        const newY = e.target.y();
        setDraggedPosition(null);
        setDraggedIndex(null);
        setDraggedPoint(null);
    
        if (lineIndex === 0 && pointIndex === 0) {
          newLines[lineIndex] = [newX, newY, x2, y2];
        } else if (lineIndex === lines.length - 1 && pointIndex === 1) {
          newLines[lineIndex] = [x1, y1, newX, newY];
        } else if (pointIndex === 0) // the first point of the line => i should work with the line Index and before it
        {
          newLines[lineIndex] = [newX, newY, x2, y2];
          const prevLine = newLines[lineIndex - 1];
          newLines[lineIndex - 1] = [prevLine[0], prevLine[1], newX, newY];
        } else if (pointIndex === 1) // lineIndex and after it
        {
          newLines[lineIndex] = [x1, y1, newX, newY];
          const nextLine = newLines[lineIndex + 1];
          newLines[lineIndex + 1] = [newX, newY, nextLine[2], nextLine[3]];
        }
    
        // Check for collinearity and merge lines if collinear
        if (pointIndex === 1 && lineIndex < newLines.length - 1) { // aham shi ma tkoun e5ir point
          const [x1, y1] = newLines[lineIndex]; // same as: const [x1, y1] = newLines[lineIndex].slice(0, 2);
          const [x2, y2] = [newX, newY];
          const [x3, y3] = newLines[lineIndex + 1].slice(2);
    
          if (arePointsCollinear(x1, y1, x2, y2, x3, y3)) {
            newLines.splice(lineIndex, 2, [x1, y1, x3, y3]);
          }
        } else if (pointIndex === 0 && lineIndex > 0) { // aham shi mish awwal point from the whole cable
          const [x1, y1] = newLines[lineIndex - 1]; // const [x1, y1] = newLines[lineIndex - 1].slice(0, 2);
          const [x2, y2] = [newX, newY];
          const [x3, y3] = newLines[lineIndex].slice(2);
    
          if (arePointsCollinear(x1, y1, x2, y2, x3, y3)) {
            newLines.splice(lineIndex - 1, 2, [x1, y1, x3, y3]);
          }
        }
    
        setLines(newLines);
        setTempLines([]); // Clear the temporary lines
        updateLines(newLines);
    };
    
    const handleExtremityDragMove = (e, lineIndex, pointIndex) => {
        const newLines = [...lines];
        const [x1, y1, x2, y2] = newLines[lineIndex];
        const newX = e.target.x();
        const newY = e.target.y();
        let newTempLines = [...lines];
        setDraggedPosition({ newX, newY });
        setDraggedIndex(lineIndex);
        setDraggedPoint(pointIndex);
      
        if (lineIndex === 0 && pointIndex === 0) {
          newTempLines[lineIndex] = [newX, newY, x2, y2];
        } else if (lineIndex === lines.length - 1 && pointIndex === 1) {
          newTempLines[lineIndex] = [x1, y1, newX, newY];
        } else if (pointIndex === 0) {
          newTempLines[lineIndex] = [newX, newY, x2, y2];
          const prevLine = newTempLines[lineIndex - 1];
          newTempLines[lineIndex - 1] = [prevLine[0], prevLine[1], newX, newY];
        } else if (pointIndex === 1) {
          newTempLines[lineIndex] = [x1, y1, newX, newY];
          const nextLine = newTempLines[lineIndex + 1];
          newTempLines[lineIndex + 1] = [newX, newY, nextLine[2], nextLine[3]];
        }
      
        setTempLines(newTempLines);
    };

    useEffect(() => {
      console.log("Show circles is: ", showCircles);
    }, [showCircles])

    // useEffect(() => {
    //     const handleStageClick = (e) => {
    //       if (e.target === e.target.getStage()) {
    //         //setShowCircles(false);
    //         showCircles = false;
    //       }
    //     };
      
    //     const stage = stageRef.current;
    //     stage.on('click', handleStageClick);
      
    //     return () => {
    //       stage.off('click', handleStageClick);
    //     };
    //   }, [stageRef]);

    const handleLineClick = (index) => {
        //setShowCircles(true);
        //showCircles = true;
        onClick(index); // Notify the parent component about the click
    };


  return (
    <>
      {lines.map((line, index) => {
        const [x1, y1, x2, y2] = line;
        const midPoint = calculateMidPoint(x1, y1, x2, y2);

        const startCircleX = draggedPosition && ((index === draggedIndex && draggedPoint === 0) || (index === draggedIndex + 1 && draggedPoint === 1)) ? draggedPosition.newX : x1;
        const startCircleY = draggedPosition && ((index === draggedIndex && draggedPoint === 0) || (index === draggedIndex + 1 && draggedPoint === 1)) ? draggedPosition.newY : y1;
                
        {/*
            index === draggedIndex && draggedPoint === 0: The start circle of the current line is being dragged.
            index === draggedIndex + 1 && draggedPoint === 1: The end circle of the previous line is being dragged, which means the start circle of the current line should also be updated.
        */}
							
        const endCircleX = draggedPosition && ((index === draggedIndex && draggedPoint === 1) || (index === draggedIndex - 1 && draggedPoint === 0)) ? draggedPosition.newX : x2;
        const endCircleY = draggedPosition && ((index === draggedIndex && draggedPoint === 1) || (index === draggedIndex - 1 && draggedPoint === 0)) ? draggedPosition.newY : y2;
        
        {/*
            check if the current line's end circle should be updated:
            index === draggedIndex && draggedPoint === 1: The end circle of the current line is being dragged.
            index === draggedIndex - 1 && draggedPoint === 0: The start circle of the next line is being dragged, which means the end circle of the current line should also be updated.
        */}
                        
        {/*
            ya3ne ana lamma shed point of draggedIndex = 1, betkoun hiyye end circle of the current line
            and bi nafs l wa2et, start circle of the next line,
            lezim hol two circles yet8ayyaro ma3 ba3d
        
            same concept lamma shed point index = 0, hiyye start circle lal current line w end circle lal line li abla
        */}
        
        {/*
            eza ma kent 3am 7arrik l circle ma mashkal, mish 3ayzo lal code, bikoun awwal circle x1,y1
            w tene circle x2,y2
            l meshkle li 3am bet7ella hay, hiyye lamma a3mil drag lal circle, badde l two circles yalli fo2 ba3d
            yet7arrako sawa
        */}

        return (
          <React.Fragment key={index}>
            <Line
              points={line} 
              stroke={lineColor} 
              strokeWidth={3} 
              tension={0.5}
              onClick={(e) => {
                e.cancelBubble = true; // Prevent the event from bubbling up to the stage
                handleLineClick(index);
              }}
              dash={isUnsaved ? [10, 5] : []} // Dashed line if unsaved
            />

            {showCircles && (
              <>
                <Circle
                  x={startCircleX}
                  y={startCircleY}
                  radius={3}
                  fill="blue"
                  draggable={index !== 0} // Make the first point of the first line non-draggable
                  onDragMove={(e) => handleExtremityDragMove(e, index, 0)}
                  onDragEnd={(e) => handleExtremityDragEnd(e, index, 0)}
                />
                <Circle
                  x={endCircleX}
                  y={endCircleY}
                  radius={3}
                  fill="blue"
                  draggable={index !== lines.length - 1}
                  onDragMove={(e) => handleExtremityDragMove(e, index, 1)}
                  onDragEnd={(e) => handleExtremityDragEnd(e, index, 1)}
                />
                <Circle
                  x={midPoint.x}
                  y={midPoint.y}
                  radius={3}
                  fill="red"
                  draggable
                  onDragMove={(e) => handleMidPointDragMove(e, index)}
                  onDragEnd={(e) => handleMidPointDragEnd(e, index)}
                />
              </>
            )}
          </React.Fragment>
        );
      })}
      {tempLines.map((line, index) => (
        <Line
          key={`temp-${index}`}
          points={line}
          stroke="gray"
          strokeWidth={2}
          tension={0.5}
          dash={[10, 5]}
        />
      ))}
    </>
  );
};

export default SmartLine