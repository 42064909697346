import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";


const AuthContext = createContext()
export default AuthContext;

const BASE_URL = process.env.REACT_APP_API_URL;

// this is the provider that gives information to the consumers
export const AuthProvider = ({children}) => {

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    let [userJWT, setUserJWT] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null);
    let [userInfo, setUserInfo] = useState(null);

    let [loading, setLoading] = useState(true); // first loading of the page

    const [error, setError] = useState(null);

    const navigate = useNavigate();

    // const [userLoaded, setUserLoaded] = useState(false);


    const handleError = (errorMessage) => {
        setError(errorMessage);
    };

    const clearErrors = () => {
        setError(null);
    };

    // Signup Page

    const [formData, setFormData] = useState({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const signUpUser = (e) => {
        e.preventDefault();

        // Clear any previous errors
        clearErrors();
      
        // Check if password and confirm password match
        if (formData.password !== formData.confirm_password) {
            handleError("Password and Confirm Password don't match");
            return; // Prevent further execution
        }
      
        fetch(`${BASE_URL}/api/signup/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'email': e.target.email.value,
            'first_name': e.target.first_name.value,
            'last_name': e.target.last_name.value,
            'password': e.target.password.value,
            'confirm_password': e.target.confirm_password.value,
        })
        //   body: JSON.stringify(formData)
        })
        .then(response => {
          if (response.ok) {
            // Signup successful
            console.log('Signup successful');
            // navigate('/login');
            // Redirect or perform any other actions as needed
          } else {
            // Signup failed
            handleError('Signup failed');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          handleError('Error: Something went wrong');
        });
      };

    // End of Signup

    useEffect(() => {
        if (userJWT) {
          fetchUserData(userJWT.user_id);
        }
    }, [userJWT]);


    // useEffect(() => {
    //     if (userLoaded) {
    //         navigate('/companies'); // Navigate once user data is fully loaded
    //     }
    // }, [userLoaded, navigate]);


    async function fetchUserData(id) {
        try {
          const response = await fetch(`${BASE_URL}/api/users/${id}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setUserInfo(data);
        //   setUserLoaded(true); // Set the state to true once data is loaded
          console.log(data);
          //navigate('/'); // i wanna go to companies of the user later bhess i need another useEffect
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
    }

    let loginUser = async (e) => {

        e.preventDefault();
        clearErrors();

        console.log(e);
        let response = await fetch(`${BASE_URL}/api/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'email': e.target.email.value, 'password': e.target.password.value})
            // we're using name and password from the loginPage HTML name tags
        })
        let data = await response.json();
        // console.log('data:', data);
        // console.log('response:', response);

        if (response.status === 200) {
            setAuthTokens(data); // data is access and refresh tokens
            setUserJWT(jwtDecode(data.access));
            // const user_id = userJWT.user_id;
            // await fetchUserData(userJWT.user_id);
            // console.log('feshle: ', userInfo);
            localStorage.setItem('authTokens', JSON.stringify(data)); // set the authtokens once we login
            // navigate('/');
            return true;
        }
        else {
            handleError("Login failed: Please ensure your email address and password are correct")
            return false;
        }
    }

    let logoutUser = () => {
        setAuthTokens(null);
        setUserJWT(null);
        setUserInfo(null);
        localStorage.removeItem('authTokens');
        navigate('/');
    }

    let updateToken = async () => {
        console.log('Update token called!');
        let response = await fetch(`${BASE_URL}/api/token/refresh/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({'refresh': authTokens?.refresh}) // if authTokens hasn't been updated yet, we don't wanna throw an error
        })
        let data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            setUserJWT(jwtDecode(data.access));
            localStorage.setItem('authTokens', JSON.stringify(data));
        } else {
            // if refresh token failed for some reason, logout
            //logoutUser();
            setAuthTokens(null);
            setUserJWT(null);
            setUserInfo(null);
            localStorage.removeItem('authTokens');
        }
        if (loading) {
            setLoading(false); // after updating our token when we reloaded the page, make loading false so that we don't keep updating the token
        }
        // so now we either logout the user or we update the token
    }

    let contextData = {
        user: userInfo,
        authTokens: authTokens,
        signupData: formData,
        error: error,

        signUpUser: signUpUser,
        handleChange: handleChange,
        loginUser: loginUser,
        logoutUser: logoutUser,
        clearErrors: clearErrors,
    }

    useEffect(() => {

        if (loading) { // if loading is true then we still need to update our token
            // the user might still have a valid refresh token even if access token has expired
            updateToken();
        }

        // const fourMinutes = 1000 * 60 * 4;
        const fourteenMinutes = 1000 * 60 * 14;
        let interval = setInterval(() => {
            if(authTokens) {
                updateToken();
            }
        }, fourteenMinutes);

        return () => clearInterval(interval); // making sure we're not reinitializing the interval every 2 seconds

    }, [authTokens, loading]);


    return (
        // we wanna make sure that none of the children render before everything is completed here
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}