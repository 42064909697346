import React from 'react';
import { Rect, Line, Circle, Group } from 'react-konva';

const Splitter = ({
    id,
    name,
    info,
    height,
    width,
    nbOfInputs,
    nbOfOutputs,
    fiberLength = 50,
    x,
    y,
    type,
    color,
    stroke = 'black',
    connectorType,
    paintType,
    isEqual,

    inputFibers,
    outputFibers,

    onDragSplitterMove,
    onDragSplitterEnd,

    handleFiberDragStart,
    handleFiberDragMove,
    handleFiberDragEnd,
    isFiberUsed,

    // handlePigtailCut,

}) => {

    // useEffect(() => {
    //     // Update realX and realY of input fibers
    //     setInputFibers(prevInputFibers => 
    //         prevInputFibers.map(fiber => ({
    //             ...fiber,
    //             realX: fiber.x + x, // Update based on current splitter x
    //             realY: fiber.y + y, // Update based on current splitter y
    //         }))
    //     );

    //     // Update realX and realY of output fibers
    //     setOutputFibers(prevOutputFibers => 
    //         prevOutputFibers.map(fiber => ({
    //             ...fiber,
    //             realX: fiber.x + x, // Update based on current splitter x
    //             realY: fiber.y + y, // Update based on current splitter y
    //         }))
    //     );

    // }, [x, y]);

    return (
        // <Stage width={window.innerWidth} height={window.innerHeight}>
        //     <Layer>

        <React.Fragment>

            {/* Group to make the splitter and fibers draggable as a whole */}
            <Group
                x={x}
                y={y}
                draggable
                onDragMove={(e) => {
                    const newX = e.target.x();
                    const newY = e.target.y();

                    // Call the parent function to update tempLines
                    onDragSplitterMove(id, newX, newY);
                }}
                onDragEnd={(e) => {
                    const newX = e.target.x();
                    const newY = e.target.y();
            
                    // Call the parent function to update splitter coordinates
                    onDragSplitterEnd(id, newX, newY);
                }}
            >
                {/* Splitter Rectangle */}
                <Rect
                    width={width}
                    height={height}
                    fill={color}
                    stroke={stroke}
                    strokeWidth={2}
                />

                {/* Input Fibers */}
                {inputFibers.map((fiberPos) => (
                    <React.Fragment key={fiberPos.id}>
                        {!fiberPos.isPatch && (
                        <Line
                            points={[fiberPos.x , fiberPos.y , 0, fiberPos.y]} // in the group, the reference coordinate system is the base of the group => the rect of the splitter
                            // points={[ x, fiberPos.y, fiberPos.x, fiberPos.y]}
                            stroke="#0000FF"
                            strokeWidth={2}
                        />
                        )}
                        {!isFiberUsed(fiberPos.id) && (
                            <Circle
                                x={fiberPos.x}
                                y={fiberPos.y}
                                radius={3}
                                fill="#0000FF"
                                draggable
                                // onDragStart={(e) => {
                                //     // e.cancelBubble = true; // Prevent event bubbling to parent group
                                //     handleFiberDragStart(e, fiberPos.realX, fiberPos.realY, fiberPos.id);
                                // }}
                                onDragStart={handleFiberDragStart}
                                onDragMove={(e) => {
                                    e.cancelBubble = true;
                                    // i still need to solve the cableIndex
                                    handleFiberDragMove(e, fiberPos.realX, fiberPos.realY, fiberPos.id, 1, id);
                                }}
                                // onDragEnd={(e) => handleFiberDragEnd(e, fiberPos.x, fiberPos.y, fiberPos.id, '#0000FF', 1, 1)}
                                onDragEnd={(e) =>{
                                    e.cancelBubble = true;
                                    handleFiberDragEnd(e, fiberPos.realX, fiberPos.realY, fiberPos.id, '#0000FF', 1, 1, id);
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}

                {/* Output Fibers */}
                {outputFibers.map((fiberPos) => (
                    <React.Fragment key={fiberPos.id}>
                        {!fiberPos.isPatch && (
                        <Line
                            points={[width, fiberPos.y, fiberPos.x, fiberPos.y]}
                            stroke="#FF0000"
                            strokeWidth={2}
                        />
                        )}
                        {!isFiberUsed(fiberPos.id) && (
                            <Circle
                                x={fiberPos.x}
                                y={fiberPos.y}
                                radius={3}
                                fill="#FF0000"
                                draggable
                                // onDragStart={(e) => {
                                //     // e.cancelBubble = true; // Prevent event bubbling to parent group
                                //     handleFiberDragStart(e, fiberPos.realX, fiberPos.realY, fiberPos.id);
                                // }}
                                onDragStart={handleFiberDragStart}
                                onDragMove={(e) => {
                                    e.cancelBubble = true;
                                    // i still need to solve the cableIndex
                                    handleFiberDragMove(e, fiberPos.realX, fiberPos.realY, fiberPos.id, 0, id);
                                }}
                                // onDragEnd={(e) => handleFiberDragEnd(e, fiberPos.x, fiberPos.y, fiberPos.id, '#0000FF', 1, 1)}
                                onDragEnd={(e) =>{
                                    e.cancelBubble = true;
                                    handleFiberDragEnd(e, fiberPos.realX, fiberPos.realY, fiberPos.id, '#FF0000', 0, 7, id);
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </Group>

        </React.Fragment>
        //     </Layer>
        // </Stage>
    );
};

export default Splitter;
