import React from 'react';
import { Shape, Rect } from 'react-konva';

const Pigtail = ({

  // numberControlPoints = 5,
  curveColor = '#F3CB34',
  // controlPointHeight = 12, 
  cableIndex, 
  isSaved, 
  connector,

  isBlockless,
  cutMode,

  linePoints,
  controlPoints,

  tempPoints,
  tempControlPoints,
  tempLinePoints,

  snappedConnector,
  isConnectorInPatch,

  handleDragMove,
  handleDragEnd,

  RECT_WIDTH,
  RECT_HEIGHT,

  splitterId,
  handlePigtailCut,


}) => {


  // const [originalPoints, setOriginalPoints] = useState(initialPoints); // Holds start and end points for the original line
  // const [tempPoints, setTempPoints] = useState([]); // Starts as an empty array for temporary points during dragging
  // const [controlPoints, setControlPoints] = useState([]);
  // const [linePoints, setLinePoints] = useState([]);
  // const [tempControlPoints, setTempControlPoints] = useState([]);
  // const [tempLinePoints, setTempLinePoints] = useState([]);

  return (
    // <Stage width={window.innerWidth} height={window.innerHeight}>
    //   <Layer>
    <React.Fragment>
        {/* Original Quadratic Curved Line */}
        <Shape
          stroke={(isBlockless && cutMode) ? "yellow" : curveColor}
          // stroke={connector.paintType} // Use the color from the connector paint type
          strokeWidth={2}
          dash={isSaved ? [] : [10, 5]} // Dashed line if not saved
          sceneFunc={(ctx, shape) => {
            if (controlPoints && linePoints && controlPoints.length > 0 && linePoints.length > 1) {
              ctx.beginPath();
              ctx.moveTo(linePoints[0].x, linePoints[0].y);

              for (let i = 0; i < controlPoints.length; i++) {
                const cp = controlPoints[i];
                const next = linePoints[i + 1];
                ctx.quadraticCurveTo(cp.x, cp.y, next.x, next.y);
              }

              ctx.strokeShape(shape);
            }
          }}
          onDblClick={() => {
            if (isBlockless && cutMode) {
              handlePigtailCut(splitterId, connector.coreEnd);
            }
          }}
        />

        {/* Dashed Quadratic Curved Line - Preview while dragging */}
        {tempPoints && tempPoints.length > 0 && (
          <Shape
            stroke="gray" // Set the stroke color to gray for the preview
            strokeWidth={2}
            dash={[10, 5]}
            sceneFunc={(ctx, shape) => {
              if (tempControlPoints && tempLinePoints && tempControlPoints.length > 0 && tempLinePoints.length > 1) {
                ctx.beginPath();
                ctx.moveTo(tempLinePoints[0].x, tempLinePoints[0].y);

                for (let i = 0; i < tempControlPoints.length; i++) {
                  const cp = tempControlPoints[i];
                  const next = tempLinePoints[i + 1];
                  ctx.quadraticCurveTo(cp.x, cp.y, next.x, next.y);
                }

                ctx.strokeShape(shape);
              }
            }}
          />
        )}

        {/* Start Circle (fixed) */}
        {/* <Circle
          x={originalPoints[0]}
          y={originalPoints[1]}
          radius={5}
          fill="red"
        /> */}

        {/* End Circle (draggable) */}
        {/* <Circle
          x={originalPoints[2]}
          y={originalPoints[3]}
          radius={5}
          fill="red"
          draggable
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
        /> */}

        {/* End Square (draggable) */}
        {/* <Rect
          //rectX = cableIndex % 2 === 0 ? lastPoint.x - 3 : lastPoint.x - rectWidth + 3;
          // x={cableIndex % 2 === 0 ? originalPoints[2] - 3 : originalPoints[2] - RECT_WIDTH + 3} // Centering the square at the point
          // y={originalPoints[3] - RECT_HEIGHT / 2} // Centering the square at the point
          x={connector.x}
          y={connector.y}
          // x and y are the first left point of the rectangle
          width={RECT_WIDTH}
          height={RECT_HEIGHT}
          // fill= "#3369AF"
          // fill={connector.paintType.color}
          fill={null}
          stroke={connector.paintType.color}
          draggable
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          // stroke={isSaved ? null : "gray"} // Add a gray stroke if not saved
          // dash={isSaved ? [] : [5, 10]} // Apply dashed stroke if not saved
        /> */}

        {/* To be fixed */}
        {snappedConnector && (
          // Render the dashed rectangle snapped to the snappedConnector
          <Rect
            x={cableIndex % 2 === 0 ? snappedConnector.snapX - RECT_WIDTH : snappedConnector.snapX}
            y={snappedConnector.snapY}
            width={RECT_WIDTH}
            height={RECT_HEIGHT}
            // Let's see how to have these as global variables
            fill={null}
            stroke="red"
            strokeWidth={2}
            dash={[10, 5]} // Dashed border
            // draggable
            // onDragMove={handleDragMove}
            // onDragEnd={handleDragEnd}
          />
        )}

        {/* To be fixed */}
          {/* // Render the normal rectangle at its current position */}
          {!isConnectorInPatch && (
          <Rect
            x={connector.topLeftX}
            y={connector.topLeftY}
            width={RECT_WIDTH}
            height={RECT_HEIGHT}
            fill={connector.paintType.color}
            stroke={(isBlockless && cutMode) ? "yellow" : connector.paintType.color}
            // stroke={connector.paintType.color}

            // stroke={null}
            strokeWidth={0.75}
            draggable
            onDragMove={handleDragMove}
            onDragEnd={handleDragEnd}
          />
          )}

    </React.Fragment>
  );
};

export default Pigtail;
