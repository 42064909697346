import React, { useEffect, useState, createContext, useContext } from "react";

// Create a context to hold the shared state
const DataContext = createContext();
// Custom hook to access the data context
export const useDataContext = () => useContext(DataContext);

const BASE_URL = process.env.REACT_APP_API_URL;

// Data provider component
export const DataProvider = ({ children }) => {
    const [itemPoles, setItemPoles] = useState([]);
    const [itemCables, setItemCables] = useState([]);
    const [itemBoxes, setItemBoxes] = useState([]);

    // const [selectCompanyId, setSelectCompanyId] = useState(null);

    // need to be authorized l mafroud
    async function fetchItemPoles() {
        try {
            const response = await fetch(`${BASE_URL}/map/item_poles/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch item poles');
            }

            const data = await response.json();
            setItemPoles(data);
        } catch (error) {
            console.error('Error fetching item poles:', error);
        }
    }

    async function fetchItemCables() {
        try {
            const response = await fetch(`${BASE_URL}/map/item_cables/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch item cables');
            }

            const data = await response.json();
            setItemCables(data);
            // console.log('Fetched item cables:', data);
        } catch (error) {
            console.error('Error fetching item cables:', error);
        }
    }

    async function fetchItemBoxes() {
        try {
            const response = await fetch(`${BASE_URL}/map/item_boxes/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch item boxes');
            }

            const data = await response.json();
            setItemBoxes(data);
            console.log('Fetched item boxes:', data);
        } catch (error) {
            console.error('Error fetching item cables:', error);
        }
    }

    useEffect(() => {
        fetchItemPoles();
        fetchItemCables();
        fetchItemBoxes();
    }, []);

    return (
        // <DataContext.Provider value={{ itemPoles, itemCables, itemBoxes, selectCompanyId, setSelectCompanyId }}>
        <DataContext.Provider value={{ itemPoles, itemCables, itemBoxes }}>
            {children}
        </DataContext.Provider>
    );
};