import React, {useEffect, useRef, useState} from 'react';
import { Button, Container, Modal, OverlayTrigger, Placeholder, Tooltip, Alert } from 'react-bootstrap';
import { Stage, Rect, Layer, Text, Line, Circle, Shape, Group } from 'react-konva';
import DraggableModalDialog from './DraggableModalDialog';
import { Resizable } from 're-resizable';
import { ReactComponent as BackButton } from "../assets/back-arrow.svg";
import { handleWheel as handleZoom } from './ZoomHandler';
import { setResizableModalDimensions, adjustDimensionsAfterFullscreen } from './WindowResizeHandler';
import SmartLine from './SmartLine';
import Pigtail from './Pigtail';

import ConnectorSpecificationWindow from './ConnectorSpecificationWindow';
import SplitterSpecificationWindow from './SplitterSpecificationWindow';
import Splitter from './Splitter';

const BASE_URL = process.env.REACT_APP_API_URL;

const BoxContentWindow = (props) => {
  // from jawad
  // const { box, pole, showBoxWindowBackButton, closeKonvaBoxWindow, showPoleWindow, zIndex } = props;

  // from me (main)
  // const { onClose, box, zIndex, authTokens, company } = props;

  // both
  const { onClose, box, pole, showBoxWindowBackButton, closeKonvaBoxWindow, showPoleWindow, zIndex, authTokens, company } = props;
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [coreEnds, setCoreEnds] = useState([]);
  const nodeRef = useRef(null);
  const [cableTubeFiberMap, setCableTubeFiberMap] = useState({});
  const defaultWindowWidth = window.innerWidth / 2;
  const defaultWindowHeight = window.innerHeight / 2;
  const [windowWidth, setWindowWidth] = useState(defaultWindowWidth);
  const [windowHeight, setWindowHeight] = useState(defaultWindowHeight);
  const [stageScale, setStageScale] = useState(1);
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });

// from jawad bas l mafroud shelton ana
// const initialPoints = [[300, 300, 200, 200]];
// const [lines, setLines] = useState(initialPoints);
// const [showCircles, setShowCircles] = useState(false);

  // For the smart line of the fibers
  const [isDragging, setIsDragging] = useState(false);
  //const [dragStartPoint, setDragStartPoint] = useState(null);
  //const [tempLine, setTempLine] = useState(null);
  const [tempLines, setTempLines] = useState([]);

  const [usedFibers, setUsedFibers] = useState([]); // array of the ids of fibers used ( ya3ne when i include the splitter,
  // i can include its used fibers here (core end ids ))

  const [fiberPositions, setFiberPositions] = useState({}); // contains the dynamic positions of all fibers including the 
  // fibers of the splitters

  const [originalFiberPositions, setOriginalFiberPositions] = useState({}); // dict for the original fiber positions of the tubes of
  // the cables that i'm fetching in the box window, ma 5ass l splitter fibers, i add these positions to the fiberPositions dict

  const [leftOffset, setLeftOffset] = useState(25);
  const [rightOffset, setRightOffset] = useState(25);

  const [savedSplices, setSavedSplices] = useState([]);
  const [unsavedSplices, setUnsavedSplices] = useState([]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [selectedSmartLine, setSelectedSmartLine] = useState(null);

  const [unsavedSmartLines, setUnsavedSmartLines] = useState([]);
  const [savedSmartLines, setSavedSmartLines] = useState([]);

  //const [patchCords, setPatchCords] = useState([]);



  const [cableIndex, setCableIndex] = useState(null);

  const [cables, setCables] = useState({});
  const [tubes, setTubes] = useState({});
  const [fibersOrigin, setFibersOrigins] = useState({});


  // const CABLE_WIDTH = 50;
  const CABLE_WIDTH = 60;
  const FIBER_LENGTH = 70;
  const BASE_TUBE_HEIGHT = 20; // Minimum height of a tube
  const CABLE_MARGIN = 20;
  const COLUMN_MARGIN = 1000;

  const TUBE_OFFSET = 20; // the y distnance between 2 tubes => 10 would be the distance between the first tube and the start of the cable
  const TUBE_WIDTH = 40; // the x distance between the end of the cable and the end of the tube
  const FIBER_OFFSET = 13; // the y distance between two consecutive fibers

  let currentYOffset = 0; // Initialize y-offset for the cables
  let secondColumnYOffset = 0; // Initialize y-offset for the second column

  // const RECT_WIDTH = 35;
  // const RECT_HEIGHT = 25;

  const RECT_WIDTH = 13;
  const RECT_HEIGHT = 8;

  const SNAP_THRESHOLD = 20;

  // const [boxSnappedConnector, setBoxSnappedConnector] = useState(null);


  // ------------------------------------------------------------------------------------------------------------
  // UseEffect hooks at the beginning

  useEffect(() => {
    if (box && box.id && Object.keys(fiberPositions).length > 0) {
      // fetchSplicesAndSmartLines(box.id);
      // i wanna fix fetching the smart lines cause they aint working and i added the endfiberId
      fetchPigtailsAndConnections(box.id);
    }
  }, [originalFiberPositions]); // i don't wanna use fiberPositions cause it changes everytime i drag a fiber
  // let's see eza bel fetch badde l original or actual positions when i reconstruct the smart lines


  // ------------------------------------------------------------------------------------------------------------
  // API calls: save and fetch
  // I need to check all of thenm again cause I've changed the logic of most of them

  const getCoreEnds = async () => {
    try {
        const response = await fetch(`${BASE_URL}/map/core-ends/${box.id}/`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCoreEnds(data);
        setIsLoading(false);
    } catch (error) {
        console.error('Error fetching core ends :', error);
        setIsLoading(false);
    }
  }

  useEffect(() => {
    getCoreEnds();
  }, [box]);

  useEffect(() => {
    const fetchConnectorTypes = async () => {
      try {
        const response = await fetch(`${BASE_URL}/map/connector-types/`);
        const data = await response.json();
        setConnectorTypes(data);
      } catch (error) {
        console.error('Error fetching connector types:', error);
      }
    };

    const fetchPaintTypes = async () => {
      try {
        const response = await fetch(`${BASE_URL}/map/connector-paint-types/`);
        const data = await response.json();
        setPaintTypes(data);
      } catch (error) {
        console.error('Error fetching paint types:', error);
      }
    };

    const fetchSplitterTypes = async () => {
      try {
        const response = await fetch(`${BASE_URL}/map/splitter-types/`);
        const data = await response.json();
        setSplitterTypes(data);
      } catch (error) {
        console.error('Error fetching splitter types:', error);
      }
    }

    fetchConnectorTypes();
    fetchPaintTypes();
    fetchSplitterTypes();
  }, []);

  const transformDataForApi = (unsavedSplices, unsavedSmartLines, boxId) => {
    return {
      splices: unsavedSplices.map(splice => {
        const matchingSmartLine = unsavedSmartLines.find(line => line.fiberId === splice.main_core);
  
        // // Extract segments correctly
        // const segments = matchingSmartLine ? matchingSmartLine.line.slice(0, -1).map((segment, index) => {
        //   if (index !== 0) {
        //     return {
        //       point_x: segment[2], // end x of the segment
        //       point_y: segment[3], // end y of the segment
        //       order: index - 1
        //     };
        //   }
        //   return null;
        // }).filter(segment => segment !== null) : [];  // Exclude the first point and filter out nulls

        // Extract segments correctly
        const segments = matchingSmartLine ? matchingSmartLine.line.slice(0, -1).map((segment, index) => {
            return {
              point_x: segment[2], // end x of the segment
              point_y: segment[3], // end y of the segment
              order: index
            };
        }).filter(segment => segment !== null) : [];  // Exclude the first point and filter out nulls
  
        return {
          main_core: splice.main_core,
          spliced_core: splice.spliced_core,
          smart_lines: matchingSmartLine ? [{
            origin_type: 28,
            origin_id: matchingSmartLine.fiberId,
            box: boxId,
            color: matchingSmartLine.colorId,  // Use colorId here
            color_name: matchingSmartLine.color,
            segments: segments
          }] : []
        };
      })
    };
  };


  const saveSplicesAndSmartLines = async () => {
  const transformedData = transformDataForApi(unsavedSplices, unsavedSmartLines, box.id);

  // console.log('Transformed Data:', JSON.stringify(transformedData, null, 2)); // Log the transformed data

  try {
    const response = await fetch(`${BASE_URL}/map/save_splices_smartlines/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`  // Replace with your auth token
      },
      body: JSON.stringify(transformedData),
    });

    if (!response.ok) {
      throw new Error('Failed to save splices and smart lines');
    }

    const data = await response.json();
    console.log('Saved data:', data);
    setShowSuccessMessage(true);
    setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds
    setUnsavedSplices([]);  // Clear unsaved splices after saving

    // // Update usedFibers with saved splices information
    // const savedSpliceIds = data.map(splice => ({
    //   mainFiber: splice.main_core,
    //   splicedFiber: splice.spliced_core,
    //   saved: true
    // }));

    // setUsedFibers(prevState => [
    //   ...prevState.filter(fiber => fiber.saved), // retains only the fibers that were already marked as saved in the usedFibers state
    //   ...savedSpliceIds // adds the newly saved splice fibers to the usedFibers array
    // ]);

    // Set savedSplices state without the saved attribute
    const simplifiedSpliceIds = data.map(splice => ({
      mainFiber: splice.main_core,
      splicedFiber: splice.spliced_core
    }));
    setSavedSplices(simplifiedSpliceIds);

    // Move unsaved smart lines to saved smart lines
    setSavedSmartLines(prevState => [...prevState, ...unsavedSmartLines]);
    setUnsavedSmartLines([]);  // Clear unsaved smart lines after saving

  } catch (error) {
    console.error('Error:', error);
  }
};

// from jawad i wanna check them

const handleBackButtonPress = () => {
  closeKonvaBoxWindow();
  showPoleWindow();
};

// const handleLineClick = () => {
//   setShowCircles(true);
// };




const fetchSplicesAndSmartLines = async (boxId) => {
  try {
    const response = await fetch(`${BASE_URL}/map/fetch_splices_smartlines/${boxId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch splices and smart lines');
    }

    const data = await response.json();
    console.log('Fetched data:', data);
    // setSavedSplices(data);

    // Set the savedSplices state without the 'saved' attribute
    const simplifiedSpliceIds = data.map(splice => ({
      mainFiber: splice.main_core,
      splicedFiber: splice.spliced_core
    }));
    setSavedSplices(simplifiedSpliceIds);

    // const usedFibersFromSaved = data.map(splice => ({
    //   mainFiber: splice.main_core,
    //   splicedFiber: splice.spliced_core,
    //   saved: true
    // }));
    // setUsedFibers(prevState => [...prevState, ...usedFibersFromSaved]);

    // Extract mainFiber and splicedFiber IDs and add them to usedFibers
    const usedFiberIds = data.flatMap(splice => [splice.main_core, splice.spliced_core]);
    setUsedFibers(prevState => [...prevState, ...usedFiberIds]);
    // The flatMap method is used to flatten the array of arrays into a single array of IDs

    // // Handle smart lines from fetched splices
    // data.forEach(splice => {
    //   const mainFiberPosition = fiberPositions[splice.main_core];
    //   const splicedFiberPosition = fiberPositions[splice.spliced_core];

    //   splice.smart_lines.forEach(smartLine => {
    //     // Extract the segments and add the first and last points
    //     const segments = [
    //       [mainFiberPosition.x, mainFiberPosition.y], // Add the main fiber position
    //       ...smartLine.segments.map(segment => [
    //         segment.point_x, segment.point_y
    //       ]),
    //       [splicedFiberPosition.x, splicedFiberPosition.y] // Add the spliced fiber position
    //     ];

    //     const newSmartLine = {
    //       line: segments,
    //       color: smartLine.color_name,
    //       colorId: smartLine.color,
    //       fiberId: smartLine.origin_id
    //     };

    //     setSmartLines(prevSmartLines => [
    //       ...prevSmartLines,
    //       newSmartLine
    //     ]);
    //   });
    // });

    // Handle smart lines from fetched splices
    const fetchedSmartLines = [];
    data.forEach(splice => {
      const mainFiberPosition = fiberPositions[splice.main_core];
      const splicedFiberPosition = fiberPositions[splice.spliced_core];

      splice.smart_lines.forEach(smartLine => {
        // Extract the segments and construct the line array
        const segments = smartLine.segments.map(segment => [
          segment.point_x, segment.point_y
        ]);

        let line = [];
        if (segments.length === 0) {
          // Straight line connection
          line = [[mainFiberPosition.x, mainFiberPosition.y, splicedFiberPosition.x, splicedFiberPosition.y]];
        } else {
          // Add first segment from main fiber to the first intermediate point
          line.push([mainFiberPosition.x, mainFiberPosition.y, segments[0][0], segments[0][1]]);
          // Add intermediate segments
          for (let i = 0; i < segments.length - 1; i++) {
            line.push([segments[i][0], segments[i][1], segments[i + 1][0], segments[i + 1][1]]);
          }
          // Add last segment from the last intermediate point to the spliced fiber
          line.push([segments[segments.length - 1][0], segments[segments.length - 1][1], splicedFiberPosition.x, splicedFiberPosition.y]);
        }

        const newSmartLine = {
          line: line,
          color: smartLine.color_name,
          colorId: smartLine.color,
          fiberId: smartLine.origin_id
        };

        fetchedSmartLines.push(newSmartLine);
      });
    });

    setSavedSmartLines(fetchedSmartLines);

  } catch (error) {
    console.error('Error: ', error);
  }
};


  const saveConnectorsAndPigtails = async () => {
    // Transform pigtail data
    const transformedPigtails = unsavedPigtails.map(pigtail => ({
        core_end: pigtail.coreEnd,
        patch: {
            type: pigtail.connector.connectorType.id,
            paint: pigtail.connector.paintType.id,
            company: parseInt(company, 10),
            box: box.id
        },
        patch_x: pigtail.line[2], // Assuming the third value in line array is x coordinate
        patch_y: pigtail.line[3], // Assuming the fourth value in line array is y coordinate
    }));

    // Transform patch connection data using coreEnd to identify connectors
    const transformedConnections = unsavedConnectedPatches.map(patch => ({
      left_connector_core_end: patch.leftConnector.coreEnd,
      right_connector_core_end: patch.rightConnector.coreEnd
  }));

    // console.log("headers: ",{
    //               'Content-Type': 'application/json',
    //               'Authorization': `Bearer ${authTokens.access}`  // Replace with your auth token
    //           })

    // console.log("body: ", {
    //               pigtails: transformedPigtails,
    //               connections: transformedConnections  // Include the connections data
    //           });

    try {
        const response = await fetch(`${BASE_URL}/map/save_connectors_and_pigtails/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`  // Replace with your auth token
            },
            body: JSON.stringify({
                pigtails: transformedPigtails,
                connections: transformedConnections  // Include the connections data
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to save connectors, pigtails, and patch connections');
        }

        const data = await response.json();
        console.log('Saved data:', data);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds

        // Update the state for saved connectors and pigtails
        setSavedConnectors(prev => [...prev, ...unsavedConnectors]);
        setSavedPigtails(prev => [...prev, ...unsavedPigtails]);

        // Update the state for saved connections

        setSavedConnectedPatches(prev => [...prev, ...unsavedConnectedPatches]);

        setUnsavedConnectedPatches([]); // Clear unsaved connected patches

        setUnsavedConnectors([]);
        setUnsavedPigtails([]);

    } catch (error) {
        console.error('Error:', error);
    }
  };

//   const fetchPigtails = async (boxId) => {
//     try {
//       const response = await fetch(`http://127.0.0.1:8000/map/fetch_pigtails/${boxId}/`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch pigtails');
//       }

//       const pigtailsData = await response.json();
//       console.log('Fetched pigtails data:', pigtailsData);

//       const transformedPigtails = pigtailsData.map((pigtail) => {
//         const { core_end, patch_x, patch_y, patch } = pigtail;

//         const connectorType = getConnectorTypeFromID(patch.type);
//         const paintType = getConnectorPaintFromID(patch.paint);

//         const cableIndex = getCableIndexByFiberId(core_end, cableTubeFiberMap);

//         const topLeftX = cableIndex % 2 === 0 ? patch_x - 3 : patch_x - RECT_WIDTH + 3
//         const topLeftY = patch_y - (RECT_HEIGHT / 2);

//         const snapX = cableIndex % 2 === 0 ? topLeftX + RECT_WIDTH : topLeftX;
//         const snapY = topLeftY;

//         // const selectedConnectorType = connectorTypes.find(type => type.id === parseInt(selectedConnectorTypeId));
//         // const selectedPaintType = paintTypes.find(type => type.id === parseInt(selectedPaintTypeId));

//         const newConnector = {
//           connectorType: connectorType,
//           paintType: paintType,
//           topLeftX: topLeftX,
//           topLeftY: topLeftY,
//           snapX: snapX,
//           snapY: snapY,
//           coreEnd: core_end,
//           cableIndex: cableIndex
//         };

//         const startX = originalFiberPositions[core_end].x;
//         const startY = originalFiberPositions[core_end].y;
//         const patchLine = [startX, startY, patch_x, patch_y];

//         // const cableIndex = getCableIndexByFiberId(core_end, cableTubeFiberMap);

//         setUsedFibers(prevState => [...prevState, core_end]);

//         const newPigtail = {
//           coreEnd: core_end,
//           line: patchLine,
//           connector: newConnector,
//           cableIndex: cableIndex,
//         };

//         return { newPigtail, newConnector };
//       });

//       const newSavedPigtails = transformedPigtails.map((item) => item.newPigtail);
//       const newSavedConnectors = transformedPigtails.map((item) => item.newConnector);

//       setSavedPigtails((prev) => [...prev, ...newSavedPigtails]);
//       setSavedConnectors((prev) => [...prev, ...newSavedConnectors]);

//       // setTestPigtails((prev) => [...prev, ...newSavedPigtails]);
//       // setTestConnectors((prev) => [...prev, ...newSavedConnectors]);

//   } catch (error) {
//     console.error('Error fetching pigtails:', error);
//   }
// };

const fetchPigtailsAndConnections = async (boxId) => {
  try {
    const response = await fetch(`${BASE_URL}/map/fetch_pigtails_and_connections/${boxId}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch pigtails and connections');
    }

    const data = await response.json();
    console.log('Fetched data:', data);

    const { pigtails: pigtailsData, connections: connectionsData } = data;

    const transformedPigtails = pigtailsData.map((pigtail) => {
      const { core_end, patch_x, patch_y, patch } = pigtail;

      const connectorType = getConnectorTypeFromID(patch.type);
      const paintType = getConnectorPaintFromID(patch.paint);

      const cableIndex = getCableIndexByFiberId(core_end, cableTubeFiberMap);

      const topLeftX = cableIndex % 2 === 0 ? patch_x - 3 : patch_x - RECT_WIDTH + 3
      const topLeftY = patch_y - (RECT_HEIGHT / 2);

      const snapX = cableIndex % 2 === 0 ? topLeftX + RECT_WIDTH : topLeftX;
      const snapY = topLeftY;

      const newConnector = {
        connectorType: connectorType,
        paintType: paintType,
        topLeftX: topLeftX,
        topLeftY: topLeftY,
        snapX: snapX,
        snapY: snapY,
        coreEnd: core_end,
        cableIndex: cableIndex
      };

      const startX = originalFiberPositions[core_end].x;
      const startY = originalFiberPositions[core_end].y;
      const patchLine = [startX, startY, patch_x, patch_y];

      setUsedFibers(prevState => [...prevState, core_end]);

      const newPigtail = {
        coreEnd: core_end,
        line: patchLine,
        connector: newConnector,
        cableIndex: cableIndex,
      };

      return { newPigtail, newConnector };
    });

    const newSavedPigtails = transformedPigtails.map((item) => item.newPigtail);
    const newSavedConnectors = transformedPigtails.map((item) => item.newConnector);

    setSavedPigtails((prev) => [...prev, ...newSavedPigtails]);
    setSavedConnectors((prev) => [...prev, ...newSavedConnectors]);

    const transformedConnections = connectionsData.map((connection) => {
      const leftConnector = findConnectorByCoreEnd(connection.left_connector.core_end, newSavedConnectors);
      const rightConnector = findConnectorByCoreEnd(connection.right_connector.core_end, newSavedConnectors);

      // console.log("leftConnector: ", leftConnector);
      // console.log("rightConnector: ", rightConnector);
    
      return {
        leftConnector: leftConnector,
        rightConnector: rightConnector,
        x: leftConnector.topLeftX,
        y: leftConnector.topLeftY 
      };
    });

    setSavedConnectedPatches((prev) => [...prev, ...transformedConnections]);

  } catch (error) {
    console.error('Error fetching pigtails and connections:', error);
  }
};

  // const saveConnectorsAndPigtails = async () => {
  //   const transformedData = unsavedPigtails.map(pigtail => ({
  //       core_end: pigtail.coreEnd,
  //       patch: {
  //           type: pigtail.connector.connectorType.id,
  //           paint: pigtail.connector.paintType.id,
  //           company: parseInt(company, 10),
  //           box: box.id
  //       },
  //       patch_x: pigtail.line[2], // Assuming the second value in line array is x coordinate
  //       patch_y: pigtail.line[3], // Assuming the fourth value in line array is y coordinate
  //   }));

  //   // console.log(transformedData);
  //   try {
  //       const response = await fetch('http://127.0.0.1:8000/map/save_connectors_and_pigtails/', {
  //           method: 'POST',
  //           headers: {
  //               'Content-Type': 'application/json',
  //               'Authorization': `Bearer ${authTokens.access}`  // Replace with your auth token
  //           },
  //           body: JSON.stringify({ pigtails: transformedData }),
  //       });

  //       if (!response.ok) {
  //           throw new Error('Failed to save connectors and pigtails');
  //       }

  //       const data = await response.json();
  //       console.log('Saved data:', data);
  //       setShowSuccessMessage(true);
  //       setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds

  //       // Update the state for saved connectors and pigtails
  //       setSavedConnectors(prev => [...prev, ...unsavedConnectors]);
  //       setSavedPigtails(prev => [...prev, ...unsavedPigtails]);
  //       // When updating savedPigtails and savedConnectors, make sure you spread the individual items
  //       // of unsavedPigtails and unsavedConnectors instead of spreading the entire array.
  //       setUnsavedConnectors([]);
  //       setUnsavedPigtails([]);

  //   } catch (error) {
  //       console.error('Error:', error);
  //   }
  // };


  // useEffect( () => {
  //   const fetchSavedSplices = async () => {
  //     try {
  //       const coreEndIds = Object.keys(fiberPositions);
  //       console.log(coreEndIds);
  //       const response = await fetch('http://127.0.0.1:8000/map/fetch_splices/', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ core_end_ids: coreEndIds }),
  //       });
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       setSavedSplices(data);

  //       const usedFibersFromSaved = data.map(splice => ({
  //         mainFiber: splice.main_core,
  //         splicedFiber: splice.spliced_core,
  //         saved: true
  //       }));
  //       setUsedFibers(prevState => [...prevState, ...usedFibersFromSaved]);

  //     } catch (error) {
  //       console.error('Error fetching saved splices:', error);
  //     }
  //   };

  //   if (Object.keys(fiberPositions).length > 0) {
  //     fetchSavedSplices();
  //   }
  // }, [fiberPositions]);

  // const saveSplices = async () => {
  //   try {
  //     const response = await fetch('http://127.0.0.1:8000/map/save_splices/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + String(authTokens.access)
  //       },
  //       body: JSON.stringify({ splices: unsavedSplices }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data = await response.json();
  //     const savedSpliceIds = data.map(splice => ({
  //       mainFiber: splice.main_core,
  //       splicedFiber: splice.spliced_core,
  //       saved: true
  //     }));
  //     setUsedFibers(prevState => [
  //       ...prevState.filter(fiber => fiber.saved),
  //       ...savedSpliceIds
  //     ]);
  //     setUnsavedSplices([]);

  //     setShowSuccessMessage(true);
  //     setTimeout(() => setShowSuccessMessage(false), 3000); // Hide after 3 seconds

  //   } catch (error) {
  //     console.error('Error saving splices:', error);
  //   }
  // };

  // ------------------------------------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------------------------------------
  // Everything related to Pigtails and Connectors :

  const [savedConnectors, setSavedConnectors] = useState([]);
  const [unsavedConnectors, setUnsavedConnectors] = useState([]);
  const [savedPigtails, setSavedPigtails] = useState([]);
  const [unsavedPigtails, setUnsavedPigtails] = useState([]);

  const [connectorTypes, setConnectorTypes] = useState([]);
  const [paintTypes, setPaintTypes] = useState([]);

  const [isPatchMode, setIsPatchMode] = useState(false);
  const [showConnectorWindow, setShowConnectorWindow] = useState(false);
  const [selectedConnectorType, setSelectedConnectorType] = useState(null);
  const [selectedPaintType, setSelectedPaintType] = useState(null);

  const [tempCpPoints, setTempCpPoints] = useState([]);
  const [tempLnPoints, setTempLnPoints] = useState([]);

  const [snappedConnector, setSnappedConnector] = useState(null);

  // states for the connected patches
  const [unsavedConnectedPatches, setUnsavedConnectedPatches] = useState([]);
  const [savedConnectedPatches, setSavedConnectedPatches] = useState([]);


  // const isConnectorInPatch = (connector, unsavedConnectedPatches, savedConnectedPatches) => {
  //   return [...unsavedConnectedPatches, ...savedConnectedPatches].some(
  //     patch => patch.leftConnector.coreEnd === connector.coreEnd || patch.rightConnector.coreEnd === connector.coreEnd
  //   );
  // };

  const isConnectorInPatch = (coreEnd) => {
    return [...unsavedConnectedPatches, ...savedConnectedPatches].some(
      patch => patch.leftConnector.coreEnd === coreEnd || patch.rightConnector.coreEnd === coreEnd
    );
  };

  const findConnectorByCoreEnd = (coreEnd, connectors) => {
    return connectors.find(connector => connector.coreEnd === coreEnd);
  };

  const calculateLineAndControlPoints = (startX, startY, endX, endY, numberControlPoints, controlPointHeight) => {
    const newLinePoints = [];
    const newControlPoints = [];
    //const [startX, startY, endX, endY] = points;

    const totalDistance = Math.hypot(endX - startX, endY - startY);

    // Adjust numberControlPoints based on the distance
    const adjustedControlPoints = totalDistance < 100 ? 3 : numberControlPoints;

    const controlPointDistance = totalDistance / adjustedControlPoints;

    // Unit vector along the line
    const dx = (endX - startX) / totalDistance;
    const dy = (endY - startY) / totalDistance;

    // Perpendicular unit vector for control points
    const perpDx = -dy;
    const perpDy = dx;

    // Calculate line points
    newLinePoints.push({ x: startX, y: startY });
    for (let i = 1; i <= adjustedControlPoints; i++) {
      const newX = startX + i * controlPointDistance * dx;
      const newY = startY + i * controlPointDistance * dy;
      newLinePoints.push({ x: newX, y: newY });
    }
    newLinePoints.push({ x: endX, y: endY });

    // Calculate control points
    for (let i = 1; i <= adjustedControlPoints; i++) {
      const cpX = startX + (i - 0.5) * controlPointDistance * dx;
      const cpY = startY + (i - 0.5) * controlPointDistance * dy;
      // const offset = (i % 2 === 0 ? controlPointHeight : -controlPointHeight);
      const offset = cableIndex % 2 === 0 
      ? (i % 2 === 0 ? controlPointHeight : -controlPointHeight) 
      : (i % 2 === 0 ? -controlPointHeight : controlPointHeight);
      newControlPoints.push({ x: cpX + offset * perpDx, y: cpY + offset * perpDy });
    }

    // if (isTemp) {
    //   setTempCpPoints(newControlPoints);
    //   setTempLnPoints(newLinePoints);
    // }
    // else {
      return { linePoints: newLinePoints, controlPoints: newControlPoints };
    // }

  };

  const handleDragConnectorMove = (e, coreEnd) => {
    // Find the connector using the unique coreEnd
    const connector = [...savedConnectors, ...unsavedConnectors].find(conn => conn.coreEnd === coreEnd);

    const pigtail = [...savedPigtails, ...unsavedPigtails].find(x => x.coreEnd === coreEnd);

    if (!pigtail) return; // Exit if no connector or pigtail found

    const updatedPoints = [...pigtail.line]; // Extract the line points from the connector
    // shallow copy

    const topLeftX = e.target.x();
    const topLeftY = e.target.y();

    const snapX = pigtail.cableIndex % 2 === 0 ? topLeftX + RECT_WIDTH : topLeftX;
    const snapY = topLeftY;

    updatedPoints[2] = pigtail.cableIndex % 2 === 0 ? e.target.x() + 3 : e.target.x() + RECT_WIDTH - 3; // Update the x-coordinate of the end point
    updatedPoints[3] = e.target.y() + RECT_HEIGHT / 2; // Update the y-coordinate of the end point

    const { linePoints: tempLinePoints, controlPoints: tempControlPoints } = calculateLineAndControlPoints(updatedPoints[0],
      updatedPoints[1], updatedPoints[2], updatedPoints[3], 5, 12);

    // Update the pigtail that matches the coreEnd with new tempPoints
    // let's see if i wanna update the connector of the pigtail now or at the DragEnd
    // and also if i wanna empty the linePoints and controlPoints
    setUnsavedPigtails((prevPigtails) =>
      // setUnsavedPigtails takes one parameter (always the old state ) and returns a new array cause "map" returns an array
      prevPigtails.map((pigtail) =>
        // "map" takes one parameter, the element of the array and returns what this element will turn to
        pigtail.coreEnd === coreEnd
          ? {
              ...pigtail,
              tempPoints: updatedPoints,
              tempControlPoints: tempControlPoints,
              tempLinePoints: tempLinePoints
            }
          : pigtail
      )
    );
    
    // Update the connector in the unsavedConnectors state
    setUnsavedConnectors((prevConnectors) =>
      prevConnectors.map((conn) =>
        conn.coreEnd === coreEnd
          ? {
              ...conn,
              topLeftX: topLeftX,
              topLeftY: topLeftY,
              snapX: snapX,
              snapY: snapY
            }
          : conn
      )
    );


    let snapped = false;
    let closestConnector = null;
    let minDistance = Infinity;

    const allConnectors = [...savedConnectors, ...unsavedConnectors];
    // const connectorX = updatedPoints[2];
    // const connectorY = updatedPoints[3];

    // Filter out connectors that are already part of a patch connection
    const usedConnectors = new Set(
      unsavedConnectedPatches.flatMap((patch) => [patch.leftConnector.coreEnd, patch.rightConnector.coreEnd])
    );

    allConnectors.forEach((otherConnector) => {
      // Avoid snapping to the same connector by checking if coreEnd is different
      if (
        coreEnd !== otherConnector.coreEnd &&
        pigtail.cableIndex !== otherConnector.cableIndex &&
        !usedConnectors.has(otherConnector.coreEnd)
      ) {
          const distance = Math.sqrt((connector.snapX - otherConnector.snapX) ** 2 + (connector.snapY - otherConnector.snapY) ** 2);
          // console.log("distance: ", distance);
          if (distance < SNAP_THRESHOLD && distance < minDistance) {
              snapped = true;
              closestConnector = otherConnector;
              minDistance = distance;
          }
          // console.log("distance: ", distance);
      }
    });

    if (snapped) {
      setSnappedConnector(closestConnector);
    } else {
      setSnappedConnector(null);
    }
  };

  const handleDragConnectorEnd = (e, coreEnd) => {
    const connector = [...unsavedConnectors, ...savedConnectors].find(conn => conn.coreEnd === coreEnd);
    const pigtail = [...unsavedPigtails, ...savedPigtails].find( x => x.coreEnd === coreEnd);

    if (!connector || !pigtail) return;

    const updatedPoints = [...pigtail.line];
    // const updatedPoints = connector.linePoints; creates a reference to the original array linePoints in the connector object.
    // This means that any changes made to updatedPoints will directly modify the linePoints array within the connector object.

    // onst updatedPoints = [...connector.linePoints];, you are creating a shallow copy of the linePoints array.
    // This allows you to modify updatedPoints without directly affecting the original array in the connector object until you explicitly update it.

    let topLeftX, topLeftY;

    if (snappedConnector) {

      let leftConnector, rightConnector, topLeftSnapX, topLeftSnapY;

      // if (cableIndex % 2 === 0) {
      //   topLeftX = snappedConnector.snapX - RECT_WIDTH;
      //   topLeftY = snappedConnector.snapY;
      // }
      // else {
      //   topLeftX = snappedConnector.snapX;
      //   topLeftY = snappedConnector.snapY;
      // }

      if (connector.cableIndex % 2 === 0) {
        leftConnector = connector;
        rightConnector = snappedConnector;
        topLeftX = snappedConnector.snapX - RECT_WIDTH;
        topLeftY = snappedConnector.snapY;
        topLeftSnapX = snappedConnector.snapX - RECT_WIDTH;
        topLeftSnapY = snappedConnector.snapY;

      }
      else {
        leftConnector = snappedConnector;
        rightConnector = connector;
        topLeftX = snappedConnector.snapX;
        topLeftY = snappedConnector.snapY;
        topLeftSnapX = snappedConnector.topLeftX;
        topLeftSnapY = snappedConnector.topLeftY;
      }

      // Create a new Patch Connect
      const newPatchConnect = {
        leftConnector: leftConnector,
        rightConnector: rightConnector,
        x: topLeftSnapX,
        y: topLeftSnapY
      };

      // const updateConnectedPatches = (newPatchConnect, unsnapCoreEnd = null) => {
      //   setUnsavedConnectedPatches((prevConnections) => {
      //     if (unsnapCoreEnd) {
      //       // If unsnapCoreEnd is provided, remove the connection involving this coreEnd
      //       return prevConnections.filter(connection => 
      //         connection.leftConnector.coreEnd !== unsnapCoreEnd && 
      //         connection.rightConnector.coreEnd !== unsnapCoreEnd
      //       );
      //     } else {
      //       // Filter out any existing connections with the same coreEnd for left or right connectors
      //       const filteredConnections = prevConnections.filter((connection) => {
      //         return (
      //           connection.leftConnector.coreEnd !== newPatchConnect.leftConnector.coreEnd &&
      //           connection.rightConnector.coreEnd !== newPatchConnect.rightConnector.coreEnd
      //         );
      //       });
      
      //       // Add the new patch connection to the filtered list
      //       return [...filteredConnections, newPatchConnect];
      //     }
      //   });
      // };

      // // Add the new patch connection to unsavedConnectedPatches
      // updateConnectedPatches(newPatchConnect);

      // Add the Patch Connect to the list of patch connections
      setUnsavedConnectedPatches((prevConnections) => {
        const filteredConnections = prevConnections.filter((connection) => {
          // only return the connected patches whose leftConnector AND rightConnector are different from the newPatchConnect right and left connectors
          // mish daroure bas eno
          // filter: Returns a new array containing all elements that satisfy the provided condition.
          return (
            connection.leftConnector.coreEnd !== newPatchConnect.leftConnector.coreEnd &&
            connection.rightConnector.coreEnd !== newPatchConnect.rightConnector.coreEnd
          );
        });

        // Add the new patch connection to the filtered list
        return [...filteredConnections, newPatchConnect];
      });

      // // to update the attribue isConnected of each pigtail after a snapped connection (after a connected patch) and pass it to the patch cord component
      // const updateIsConnected = (leftConnectorCoreEnd, rightConnectorCoreEnd) => {
      //   setUnsavedPigtails(prevPigtails => 
      //     prevPigtails.map(pigtail => {
      //       if (pigtail.coreEnd === leftConnectorCoreEnd || pigtail.coreEnd === rightConnectorCoreEnd) {
      //         return { ...pigtail, isConnected: true };
      //       }
      //       return pigtail;
      //     })
      //   );
      // };

      // to update the attribue isConnected of each pigtail after a snapped connection (after a connected patch) and pass it to the patch cord component
      setUnsavedPigtails(prevPigtails => 
        prevPigtails.map(pigtail => {
          if (pigtail.coreEnd === leftConnector.coreEnd || pigtail.coreEnd === rightConnector.coreEnd) {
            return { ...pigtail, isConnected: true };
          }
          return pigtail;
        })
      );

      // // Update the isConnected attribute for both connectors' pigtails
      // updateIsConnected(leftConnector.coreEnd, rightConnector.coreEnd);

      setSnappedConnector(null);


    } else {
  
      // If there's no snapped connector, the connector was unsnapped
      // Remove the existing connection involving this connector

            //   setUnsavedConnectedPatches((prevConnections) => {
      //     if (unsnapCoreEnd) {
      //       // If unsnapCoreEnd is provided, remove the connection involving this coreEnd
      //       return prevConnections.filter(connection => 
      //         connection.leftConnector.coreEnd !== unsnapCoreEnd && 
      //         connection.rightConnector.coreEnd !== unsnapCoreEnd
      //       );

      setUnsavedConnectedPatches((prevConnections) => {
        // remove the connection involving the coreEnd

        // filter (only keep) the connections with left and right core End connectors different than the connector's core End
        return prevConnections.filter(connection => 
          connection.leftConnector.coreEnd !== connector.coreEnd &&
          connection.rightConnector.coreEnd !== connector.coreEnd
        );
      });

      // right now, I haven't implemented it.

      // updateConnectedPatches(null, connector.coreEnd);
      
      topLeftX = e.target.x();
      topLeftY = e.target.y();

    }

    const snapX = connector.cableIndex % 2 === 0 ? topLeftX + RECT_WIDTH : topLeftX;
    const snapY = topLeftY;

    // e.target.x() is the top Left x of the dragged rectangle
    // e.target.y() is the top Left y of the dragged rectangle

    updatedPoints[2] = connector.cableIndex % 2 === 0 ? topLeftX + 3 : topLeftX + RECT_WIDTH - 3;
    updatedPoints[3] = topLeftY + RECT_HEIGHT / 2;

    const { linePoints: linePoints, controlPoints: controlPoints } = calculateLineAndControlPoints(updatedPoints[0],
      updatedPoints[1], updatedPoints[2], updatedPoints[3], 5, 12);

    const updatedConnector = {
      ...connector,
      topLeftX: topLeftX,
      topLeftY: topLeftY,
      snapX: snapX,
      snapY: snapY,
    };
    
    // Update the pigtail that matches the coreEnd with new tempPoints
    setUnsavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd
          ? {
              ...pigtail,
              line: updatedPoints,
              tempPoints: [],
              tempControlPoints: [],
              tempLinePoints: [],
              linePoints: linePoints,
              controlPoints: controlPoints,
              connector: updatedConnector,
            }
          : pigtail
      )
    );

    ////////////////////////

    // setUnsavedPigtails(prevPigtails => 
    //   prevPigtails.map(pigtail => {
    //     if (pigtail.coreEnd === leftConnector.coreEnd || pigtail.coreEnd === rightConnector.coreEnd) {
    //       return { ...pigtail, isConnected: true };
    //     }
    //     return pigtail;
    //   })
    // );

    // setUnsavedPigtails(prevPigtails =>
    //   prevPigtails.map(pigtail => {
    //     if (pigtail.coreEnd === coreEnd) {
    //       return {
    //         ...pigtail,

    //       }
    //     }
    //     return pigtail;
    //   })
    // );

    // setUnsavedPigtails(prevPigtails =>
    //   prevPigtails.map(pigtail =>
    //     pigtail.coreEnd === coreEnd
    //       ? {
    //           ...pigtail,
    //           tempPoints: updatedPoints,
    //         }
    //       : pigtail
    //   )
    // );

    // setOriginalPoints(updatedPoints);
    // setTempPoints([]); // Clear the temporary points after dragging ends
    // setTempControlPoints([]);
    // setTempLinePoints([]);

    // setSnappedConnector(null); // Reset snapped connector after drag ends

    // // Notify parent of the updated line
    // updateLine(updatedPoints, cableIndex);

    // Notify parent of the updated line and connector position
    // updateLineAndConnector(updatedPoints, {
    //   ...connector,
    //   topLeftX: topLeftX,
    //   topLeftY: topLeftY,
    //   snapX: snapX,
    //   snapY: snapY
    // }, coreEnd);


    // Update the unsaved connectors
    setUnsavedConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.coreEnd === coreEnd
          ? updatedConnector
          : connector
      )
    );
  };


  const handleConnectorSpecSubmit = (selectedConnectorTypeId, selectedPaintTypeId) => {
    const selectedConnectorType = connectorTypes.find(type => type.id === parseInt(selectedConnectorTypeId));
    const selectedPaintType = paintTypes.find(type => type.id === parseInt(selectedPaintTypeId));

    setSelectedConnectorType(selectedConnectorType);
    setSelectedPaintType(selectedPaintType);
  
    // const newConnector = {
    //   connectorType: selectedConnectorType,
    //   paintType: selectedPaintType,
    // };
  
    // // Now you can use `newConnector` in your logic, and it will contain full objects
    // // instead of just IDs
  };

  const getConnectorTypeFromID = (ID) => {
    return connectorTypes.find(type => type.id === parseInt(ID));
  }

  const getConnectorPaintFromID = (ID) => {
    return paintTypes.find(type => type.id === parseInt(ID));
  }

  const toggleMode = () => {
    setIsPatchMode((prevMode) => !prevMode);
    if (!isPatchMode) {
      setShowConnectorWindow(true); // Show the connector specification window
    }
  };

  const updatePigtailTempPoints = (coreEnd, tempPoints) => {

    const { linePoints: tempLinePoints, controlPoints: tempControlPoints } = calculateLineAndControlPoints(tempPoints[0],
      tempPoints[1], tempPoints[2], tempPoints[3], 5, 12);
      
    setUnsavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd ? 
        { 
          // here i can remove the original poinst also if I want ---> i will remove them
          ...pigtail,
          tempPoints: tempPoints,
          tempControlPoints,
          tempLinePoints,
          // line: [], // it did cause an error when i added it
          controlPoints: [],
          linePoints: [],
        }
        : pigtail
      )
    );
  };

  const updatePigtailOriginalPoints = (coreEnd, originalPoints) => {

    const { linePoints, controlPoints } = calculateLineAndControlPoints(originalPoints[0], originalPoints[1], originalPoints[2],
      originalPoints[3], 5, 12);

    setUnsavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd ?
        {
          ...pigtail,
          line: originalPoints,
          linePoints,
          controlPoints,
          tempPoints: [],
          tempControlPoints: [],
          tempLinePoints: [],
        }
        : pigtail
      )
    );
  
    setSavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd ?
        {
          ...pigtail,
          line: originalPoints,
          linePoints,
          controlPoints,
          tempPoints: [],
          tempControlPoints: [],
          tempLinePoints: [],
        }
        : pigtail
      )
    );
  };


  // -------------------------------------------------------------------------------------------------------------

  // find the pigtail of the unique core end given
  const findPigtailByCoreEnd = (coreEnd) => {
    // Combine saved and unsaved pigtails into one array
    const allPigtails = [...savedPigtails, ...unsavedPigtails];
    
    // Find and return the pigtail with the specified coreEnd
    return allPigtails.find(pigtail => pigtail.coreEnd === coreEnd);
  };

  const updateConnectorState = (updatedConnector) => {
    setSavedConnectors((prevSavedConnectors) => {
      return prevSavedConnectors.map(connector =>
        connector.coreEnd === updatedConnector.coreEnd ? updatedConnector : connector
      );
    });
  
    setUnsavedConnectors((prevUnsavedConnectors) => {
      return prevUnsavedConnectors.map(connector =>
        connector.coreEnd === updatedConnector.coreEnd ? updatedConnector : connector
      );
    });
  };

  // -------------------------------------------------------------------------------------------------------------
  // Everything related to Connected Patches:

  // for the connected patches during dragging
  const updatePatchLineAndConnectors = (index, newX, newY, isSaved) => {
    const updateConnections = (connections) => {
      const updatedConnections = [...connections];
      const connection = updatedConnections[index];
  
      const topLeftConX = newX;
      const topLeftConY = newY;
  
      const leftEndX = topLeftConX + 3;
      const leftEndY = topLeftConY + RECT_HEIGHT / 2;
  
      const rightEndX = topLeftConX + (2 * RECT_WIDTH) - 3;
      const rightEndY = leftEndY;
  
      const leftPigtail = findPigtailByCoreEnd(connection.leftConnector.coreEnd);
      const rightPigtail = findPigtailByCoreEnd(connection.rightConnector.coreEnd);
  
      if (leftPigtail) {
        const tempLeftPoints = [leftPigtail.line[0], leftPigtail.line[1], leftEndX, leftEndY];
        updatePigtailTempPoints(connection.leftConnector.coreEnd, tempLeftPoints);
      }
  
      if (rightPigtail) {
        const tempRightPoints = [rightPigtail.line[0], rightPigtail.line[1], rightEndX, rightEndY];
        updatePigtailTempPoints(connection.rightConnector.coreEnd, tempRightPoints);
      }
  
      return updatedConnections;
    };
  
    if (isSaved) {
      setSavedConnectedPatches((prevConnections) => updateConnections(prevConnections));
    } else {
      setUnsavedConnectedPatches((prevConnections) => updateConnections(prevConnections));
    }
  };
  

  // for the connected patches after drag end
  const finalizePatchLineAndConnectors = (index, newX, newY, isSaved) => {
    const finalizeConnections = (connections) => {
      const updatedConnections = [...connections];
      const connection = updatedConnections[index];
  
      const leftConnector = { ...connection.leftConnector };  // Create a new object for leftConnector
      const rightConnector = { ...connection.rightConnector };  // Create a new object for rightConnector
  
      const topLeftConX = newX;
      const topLeftConY = newY;
  
      leftConnector.topLeftX = topLeftConX;
      leftConnector.topLeftY = topLeftConY;
      leftConnector.snapX = topLeftConX + RECT_WIDTH / 2;
      leftConnector.snapY = topLeftConY;
  
      rightConnector.topLeftX = leftConnector.snapX;
      rightConnector.topLeftY = topLeftConY;
      rightConnector.snapX = leftConnector.snapX;
      rightConnector.snapY = topLeftConY;
  
      const leftEndX = topLeftConX + 3;
      const leftEndY = topLeftConY + RECT_HEIGHT / 2;
  
      const rightEndX = topLeftConX + (2 * RECT_WIDTH) - 3;
      const rightEndY = leftEndY;
  
      const leftPigtail = findPigtailByCoreEnd(leftConnector.coreEnd);
      const rightPigtail = findPigtailByCoreEnd(rightConnector.coreEnd);
  
      if (leftPigtail) {

        // not necessary halla2
        // i do both in one function cause i can access all the pigtail's properties

        // const newLeftPigtail = { ...leftPigtail, line: [leftPigtail.line[0], leftPigtail.line[1], leftEndX, leftEndY] };
        // updatePigtailState(newLeftPigtail);
  
        // Update the originalPoints and clear tempPoints in the PatchCord
        updatePigtailOriginalPoints(leftPigtail.coreEnd, [leftPigtail.line[0], leftPigtail.line[1], leftEndX, leftEndY]);
      }
  
      if (rightPigtail) {
        // const newRightPigtail = { ...rightPigtail, line: [rightPigtail.line[0], rightPigtail.line[1], rightEndX, rightEndY] };
        // updatePigtailState(newRightPigtail);
  
        // Update the originalPoints and clear tempPoints in the PatchCord
        updatePigtailOriginalPoints(rightPigtail.coreEnd, [rightPigtail.line[0], rightPigtail.line[1], rightEndX, rightEndY]);
      }
  
      // Update the connector states
      updateConnectorState(leftConnector);
      updateConnectorState(rightConnector);
  
      updatedConnections[index] = { ...connection, leftConnector, rightConnector };
  
      return updatedConnections;
    };
  
    if (isSaved) {
      setSavedConnectedPatches((prevConnections) => finalizeConnections(prevConnections));
    } else {
      setUnsavedConnectedPatches((prevConnections) => finalizeConnections(prevConnections));
    }
  };

  const handlePigtailCut = (splitterId, coreEnd) => {
    // Find the pigtail to cut
    // Add the saved pigtails later also
    const pigtailToCut = unsavedPigtails.find(pigtail => pigtail.coreEnd === coreEnd);

    if (pigtailToCut) {
      // Update inputFibers or outputFibers for the corresponding splitter
      // Add the saved splitters also
      setUnsavedSplitters(prevSplitters =>
        prevSplitters.map(splitter => {
          if (splitter.id === splitterId) {
            // Update the inputFibers or outputFibers of the correct splitter
            const updatedInputFibers = splitter.inputFibers.map(fiber =>
              fiber.id === coreEnd ? { ...fiber, isPatch: false } : fiber
            );
            const updatedOutputFibers = splitter.outputFibers.map(fiber =>
              fiber.id === coreEnd ? { ...fiber, isPatch: false } : fiber
            );

            return {
              ...splitter,
              inputFibers: updatedInputFibers,
              outputFibers: updatedOutputFibers,
            };
          }
          return splitter;
        })
      );

      // Remove the pigtail from unsavedPigtails
      // Remove also from savedPigtails if it's there
      setUnsavedPigtails(prevPigtails => prevPigtails.filter(pigtail => pigtail.coreEnd !== coreEnd));

      // Remove the coreEnd from usedFibers
      setUsedFibers(prevUsedFibers => prevUsedFibers.filter(fiberId => fiberId !== coreEnd));
    }
  };

  // -------------------------------------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------------------------------------
  // Everything related to Splitters :

  const [splitterMode, setSplitterMode] = useState(false);
  const [splitterTypes, setSplitterTypes] = useState([]);

  const [unsavedSplitters, setUnsavedSplitters] = useState([]);
  const [savedSplitters, setSavedSplitters] = useState([]);

  const [cutMode, setCutMode] = useState(false);

  const maxPerRow = 16; // Maximum connectors per row

  // Helper function to find the highest existing fiber ID
  const getHighestFiberId = () => {
    const allFiberIds = Object.keys(fiberPositions).map(Number); // Convert keys to integers
    return allFiberIds.length > 0 ? Math.max(...allFiberIds) : 0;
  };

  // const calculateSplitterDimensions = (numFibers, splitterType) => {
  //   // const connectorSpacing = 40; // Space per connector
  //   // const maxPerRow = 16; // Maximum connectors per row

  //   // Fixed dimensions for types 1, 2, and 3
  //   const fixedWidth = 200;
  //   const fixedHeight = 70;

  //   // If type is 1, 2, or 3, return fixed width and height
  //   if (splitterType === '1' || splitterType === '2' || splitterType === '3') {
  //     return { width: fixedWidth, height: fixedHeight };
  //   }

  //   // For type 4 and 5, dynamically calculate width and height
  //   const rows = numFibers > maxPerRow ? 2 : 1;
  //   const columns = rows === 1 ? numFibers : Math.ceil(numFibers / 2);

  //   // Dynamically calculate width and height based on the number of outputs
  //   const width = columns * RECT_WIDTH;
  //   const height = rows === 1 ? fixedHeight : 140; // Increase height if there are two rows

  //   return { width, height };
  // };

  // Generalized function to generate fiber positions
  const generateFiberPositions = (splitterData, type, startingFiberId) => {
    const numFibers = type === 'input' 
      ? parseInt(splitterData.nbOfInputs, 10) 
      : parseInt(splitterData.nbOfOutputs, 10);

    let newFiberId = startingFiberId; // Start generating new fiber IDs

    const fiberSpacing = splitterData.height / (numFibers + 1);

    const fiberPositions = [];
    const fiberIds = [];
    const newFiberOrigins = {};
    const newFiberPositions = {};
    const newPigtails = [];
    const newConnectors = [];

    // const { width, height } = calculateSplitterDimensions(numFibers, splitterData.type);

    for (let i = 0; i < numFibers; i++) {
      const fiberId = newFiberId++; // Generate a unique fiber ID
      
      // Calculate positions for input and output fibers
      let posX, posY;

      if (splitterData.type === '4' || splitterData.type === '5') {
        // const row = i % 2;  // 0 for top row, 1 for bottom row
        // const col = Math.floor(i / 2);  // Number of columns based on index
        // posX = col * 40 + 20;  // Space between connectors
        // posY = row === 0 ? 20 : 60;  // Top row or bottom row

        const rows = numFibers > maxPerRow ? 2 : 1; // Calculate rows dynamically
        const columns = rows === 1 ? numFibers : Math.ceil(numFibers / 2);  // Calculate columns dynamically

        // Adjust positions based on calculated rows and columns
        const row = i >= columns ? 1 : 0;  // 0 for top row, 1 for bottom row if more than 16 fibers
        const col = row === 0 ? i : i - columns;  // Use remaining index for bottom row after top row is filled

        posX = col * 40 + 20;  // Horizontal position based on the column
        posY = row === 0 ? 20 : 60;  // Vertical position for top (20) and bottom (60) rows
      }

      else {
        posX = type === 'input'
        ? -splitterData.fiberLength
        : splitterData.width + splitterData.fiberLength; // Position fibers depending on type

        posY = (i + 1) * fiberSpacing;
      }

      fiberPositions.push({
        id: fiberId,
        x: posX,
        y: posY,
        realX: posX + splitterData.x,
        realY: posY + splitterData.y,
        // isBlockless: splitterData.type === '2' || splitterData.type === '3',
        isPatch: splitterData.type !== '1',
      });

      fiberIds.push(fiberId); // Collect fiber IDs

      // Update fiber origins and positions
      newFiberOrigins[fiberId] = { splitterId: splitterData.id, type, order: i + 1 };
      newFiberPositions[fiberId] = { x: splitterData.x + posX, y: splitterData.y + posY };

      // Find the pigtails and connectors
      if (splitterData.type !== '1') {

      }
















      posX = type === 'input'
        ? -splitterData.fiberLength
        : splitterData.width + splitterData.fiberLength; // Position fibers depending on type
      posY = (i + 1) * fiberSpacing;

      fiberPositions.push({
        id: fiberId,
        x: posX,
        y: posY,
        realX: posX + splitterData.x,
        realY: posY + splitterData.y,
        // isBlockless: splitterData.type === '2' || splitterData.type === '3',
        isPatch: splitterData.type !== '1',
      });

      fiberIds.push(fiberId); // Collect fiber IDs

      // Update fiber origins and positions
      newFiberOrigins[fiberId] = { splitterId: splitterData.id, type, order: i + 1 };
      newFiberPositions[fiberId] = { x: splitterData.x + posX, y: splitterData.y + posY };

      // If type !== '1', create pigtails and connectors
      if (splitterData.type !== '1') {
        let startX, endX;

        if (splitterData.type === '4' || splitterData.type === '5') {
          // here type 4 or 5, only connectors, no lines
          // Arrange connectors in grid-like layout
          // if numFibers = 
          const columns = Math.ceil(numFibers / 2); // 2 rows and i wanna divide the patches on them
          const row = i % 2; // either 0 or 1
          const col = Math.floor(i / 2);
          const offsetX = row === 0 ? 20 : 60; // Adjust positions based on grid layout
          const offsetY = col * 30 + 20;

        } else {
          // here type 2 or 3 (no fibers but pigtails), i don't include the fiberLength (posX) only pigtailLength
          if (type === 'input') {
            startX = splitterData.x - splitterData.pigtailLength;
            endX = splitterData.x;
          } else {
            startX = splitterData.x + splitterData.width;
            endX = splitterData.x + splitterData.width + splitterData.pigtailLength;
          }
        }

        const yCoord = splitterData.y + posY;
        const patchLine = type === 'input' ? [endX, yCoord, startX, yCoord] : [startX, yCoord, endX, yCoord];

        const { linePoints: linePoints, controlPoints: controlPoints } = calculateLineAndControlPoints(patchLine[0], patchLine[1],
          patchLine[2], patchLine[3], 5, 12);

        const topLeftX = type === 'input' ? startX - RECT_WIDTH + 3 : endX - 3;
        const topLeftY = yCoord - (RECT_HEIGHT / 2);

        const newConnector = {
          connectorType: splitterData.connectorType,
          paintType: splitterData.paintType,
          topLeftX,
          topLeftY,
          snapX: topLeftX + (type === 'input' ? 0 : RECT_WIDTH),
          snapY: topLeftY,
          coreEnd: fiberId,
          cableIndex: type === 'input' ? 1 : 0,
        };

        newPigtails.push({
          coreEnd: fiberId,
          line: patchLine,
          linePoints: linePoints,
          controlPoints: controlPoints,
          connector: newConnector,
          cableIndex: type === 'input' ? 1 : 0,
          isConnected: false,
          isBlockless: splitterData.type === '2' || splitterData.type === '3',
          splitterId: splitterData.id, // Add splitterId to the pigtail
        });

        newConnectors.push(newConnector);
      }
      // end of each iteration of the loop
    }

    // Set fiber origins and positions in the state once after the loop
    setFibersOrigins(prev => ({ ...prev, ...newFiberOrigins }));
    setFiberPositions(prev => ({ ...prev, ...newFiberPositions }));

    // Update pigtails and connectors in parent state if type !== '1'
    if (splitterData.type !== '1') {
      setUnsavedPigtails(prev => [...prev, ...newPigtails]);
      setUnsavedConnectors(prev => [...prev, ...newConnectors]);
    }

    // Update usedFibers all at once for type '2'
    if (splitterData.type === '2' || splitterData.type === '3') {
      setUsedFibers(prevUsedFibers => [
        ...prevUsedFibers,
        ...fiberIds.filter(fiberId => !prevUsedFibers.includes(fiberId))  // Add only unique fiber IDs
      ]);
    }

    // Return both fiberPositions and fiberIds
    return { fiberPositions, fiberIds };
  };

  const handleAddSplitter = (splitterData) => {

    // Consider both saved and unsaved splitters when generating new ID
    const highestId = Math.max(
      ...unsavedSplitters.map(s => s.id),
      ...savedSplitters.map(s => s.id),
      0 // Default value to prevent an error if arrays are empty
    );
  
    const newId = highestId + 1; // Generate a new unique ID

    // Add the height, width, and fiberLength (afterwards these will be dynamic based on the Nb of outputs...)
    splitterData = {
      id: newId,
      ...splitterData,
      height: 70,      // Fixed height
      width: 250,      // Fixed width -> i can make it responsive according to the number of fibers
      fiberLength: 50, // Fixed fiber length
      x: 100,  // Default x coordinate
      y: 100,  // Default y coordinate
      pigtailLength: 50,
    };

    const highestExistingFiberId = getHighestFiberId(); // Find the highest existing fiber ID
    let nextFiberId = highestExistingFiberId + 1;

    // Generate fiber positions and fiber IDs
    const { fiberPositions: inputFibers, fiberIds: inputFiberIds } = generateFiberPositions(splitterData, 'input', nextFiberId);
    nextFiberId += inputFibers.length;
    const { fiberPositions: outputFibers, fiberIds: outputFiberIds } = generateFiberPositions(splitterData, 'output', nextFiberId);

    // Combine all fiber IDs
    const allFiberIds = [...inputFiberIds, ...outputFiberIds];

    const newSplitter = {
      ...splitterData,
      // connectorType: selectedConnectorType, // Use resolved connector type
      // paintType: selectedPaintType,         // Use resolved paint type
      fiberIds: allFiberIds,
      inputFibers,
      outputFibers,
    };

    setUnsavedSplitters([...unsavedSplitters, newSplitter]);
  };


  const handleSplitterDragMove = (id, newX, newY) => {

    // Find the splitter's old position
    const oldSplitter = unsavedSplitters.find(splitter => splitter.id === id);
    const oldX = oldSplitter.x;
    const oldY = oldSplitter.y;
  
    // Calculate the difference in positions
    const deltaX = newX - oldX;
    const deltaY = newY - oldY;

    // Move fibers (input and output)
    // setUnsavedSplitters(prevSplitters =>
    //   prevSplitters.map(splitter => {
    //     if (splitter.id === id) {
    //       // Update the realX and realY of input fibers
    //       const updatedInputFibers = splitter.inputFibers.map(fiber => ({
    //         ...fiber,
    //         realX: fiber.realX + deltaX, // Adjust by new splitter x
    //         realY: fiber.realY + deltaY, // Adjust by new splitter y
    //       }));

    //       // Update the realX and realY of output fibers
    //       const updatedOutputFibers = splitter.outputFibers.map(fiber => ({
    //         ...fiber,
    //         realX: fiber.realX + deltaX, // Adjust by new splitter x
    //         realY: fiber.realY + deltaY, // Adjust by new splitter y
    //       }));

    //       // Return updated splitter with modified fibers
    //       return {
    //         ...splitter,
    //         inputFibers: updatedInputFibers,
    //         outputFibers: updatedOutputFibers,

    //         // i don't wanna change the x and y of the splitter when moving cause i wanna see the oldX and newX
    //         // so i want the change of the move to add it to everything else

    //         // same with the input and output fibers

    //         // x: deltaX,  // Update splitter's new x position
    //         // y: deltaY,  // Update splitter's new y position
    //       };
    //     }
    //     return splitter;
    //   })
    // );
  
    // Create a temporary array to hold the updated smart lines during drag
    const updatedTempLines = [];
  
    // Update tempLines with smart lines connected to this splitter's fibers
    unsavedSmartLines.forEach(smartLine => {
      const { fiberId, endFiberId } = smartLine;
  
      // Create a copy of the smartLine object to modify
      let updatedTempLine = { ...smartLine };

      if (oldSplitter.fiberIds.includes(fiberId) && oldSplitter.fiberIds.includes(endFiberId)) {
        updatedTempLine.line = updatedTempLine.line.map((segment) => {
          // Modify all points in the segment by adding deltaX and deltaY
          return [
            segment[0] + deltaX, // Update startX
            segment[1] + deltaY, // Update startY
            segment[2] + deltaX, // Update endX
            segment[3] + deltaY  // Update endY
          ];
        });
      }

      else if (oldSplitter.fiberIds.includes(fiberId)) {
        // Update all points except for the last two in the line
        updatedTempLine.line = updatedTempLine.line.map((segment, index, array) => {
          if (index === array.length - 1) {
            // This is the last segment, so only modify the first two points
            return [segment[0] + deltaX, segment[1] + deltaY, segment[2], segment[3]];
          } else {
            // Modify all points in the earlier segments
            return [segment[0] + deltaX, segment[1] + deltaY, segment[2] + deltaX, segment[3] + deltaY];
          }
        });
      }
  
      // Check if the end of the smart line belongs to this splitter's fibers
      else if (oldSplitter.fiberIds.includes(endFiberId)) {
        // Update the end of the line for endFiberId
        updatedTempLine.line = updatedTempLine.line.map((segment, index) =>
          index === 0
            ? [segment[0], segment[1], segment[2] + deltaX, segment[3] + deltaY]
            : [segment[0] + deltaX, segment[1] + deltaY, segment[2] + deltaX, segment[3] + deltaY]
        );
      }
  
      // Add the updated smart line object (with line, fiberId, endFiberId) to the tempLines array
      updatedTempLines.push({
        line: updatedTempLine.line,
        fiberId: updatedTempLine.fiberId,
        endFiberId: updatedTempLine.endFiberId
      });
    });
  
    // Update tempLines state with the new lines during dragging
    setTempLines(updatedTempLines);

    // Move pigtails (patchlines) and connectors
    unsavedPigtails.forEach(pigtail => {
      if (oldSplitter.fiberIds.includes(pigtail.coreEnd)) {
        // Create a copy of the line array and update the first two points
        const updatedLine = [...pigtail.line];
        
        // Modify the first two points by adding deltaX and deltaY
        updatedLine[0] = pigtail.line[0] + deltaX;  // Update the x-coordinate of the first point
        updatedLine[1] = pigtail.line[1] + deltaY;  // Update the y-coordinate of the first point

        if (!pigtail.isConnected) {
          // If the pigtail is NOT connected, also modify the end of the line (next two points)
          updatedLine[2] = pigtail.line[2] + deltaX;  // Update the x-coordinate of the second point
          updatedLine[3] = pigtail.line[3] + deltaY;  // Update the y-coordinate of the second point
        }
      
        // Use the updatedLine in your update function

        // here i'm changing the pigtails' tempoints only (not original points or line array)
        // updatePigtailTempPoints(pigtail.coreEnd, updatedLine);
        // const updatePigtailTempPoints = (coreEnd, tempPoints) => {

        const { linePoints: tempLinePoints, controlPoints: tempControlPoints } = calculateLineAndControlPoints(updatedLine[0],
          updatedLine[1], updatedLine[2], updatedLine[3], 5, 12);
          
        setUnsavedPigtails((prevPigtails) =>
          prevPigtails.map((item) =>
            item.coreEnd === pigtail.coreEnd ? 
            { 
              ...item,
              tempPoints: updatedLine,
              tempControlPoints,
              tempLinePoints,
            }
            : item
          )
        );
        // };

        // Find the connector in the unsavedConnectors state by coreEnd
        const connectorOfPigtail = unsavedConnectors.find(connector => connector.coreEnd === pigtail.coreEnd);

        // Update the connector's position based on the deltas
        const updatedConnector = {
          ...connectorOfPigtail,  // Use the values from the unsavedConnectors state
          topLeftX: connectorOfPigtail.topLeftX + deltaX,
          topLeftY: connectorOfPigtail.topLeftY + deltaY,
          snapX: connectorOfPigtail.snapX + deltaX,
          snapY: connectorOfPigtail.snapY + deltaY,
        };
        
        // handleUpdateConnectorInsidePigtails(updatedConnector, pigtail.coreEnd);
        // const handleUpdateConnectorInsidePigtails = (updatedConnector, coreEnd) => {
        setUnsavedPigtails((prevPigtails) =>
          prevPigtails.map((item) =>
            item.coreEnd === pigtail.coreEnd
              ? { ...item, connector: updatedConnector }
              : item
          )
        );
    
    
        setSavedPigtails((prevPigtails) =>
          prevPigtails.map((item) =>
            item.coreEnd === pigtail.coreEnd
              ? { ...item, connector: updatedConnector }
              : item
          )
        );
        // };
      }
    });
  };

  const handleSplitterDragEnd = (id, newX, newY) => {

    setTempLines([]);

    // Find the splitter's old position
    const oldSplitter = unsavedSplitters.find(splitter => splitter.id === id);
    const oldX = oldSplitter.x;
    const oldY = oldSplitter.y;
  
    // Calculate the difference in positions
    const deltaX = newX - oldX;
    const deltaY = newY - oldY;

    // Update splitter's position and fibers after drag ends
    setUnsavedSplitters(prevSplitters =>
      prevSplitters.map(splitter => {
        if (splitter.id === id) {
          // Update the realX and realY of input fibers
          const updatedInputFibers = splitter.inputFibers.map(fiber => ({
            ...fiber,
            realX: fiber.realX + deltaX, // Adjust by new splitter x
            realY: fiber.realY + deltaY, // Adjust by new splitter y
          }));

          // Update the realX and realY of output fibers
          const updatedOutputFibers = splitter.outputFibers.map(fiber => ({
            ...fiber,
            realX: fiber.realX + deltaX, // Adjust by new splitter x
            realY: fiber.realY + deltaY, // Adjust by new splitter y
          }));

          // Return updated splitter with modified fibers
          return {
            ...splitter,
            inputFibers: updatedInputFibers,
            outputFibers: updatedOutputFibers,
            x: newX,  // Update splitter's new x position
            y: newY,  // Update splitter's new y position
          };
        }
        return splitter;
      })
    );
  
    // Update fiber positions
    setFiberPositions(prevFiberPositions => {
      const updatedPositions = { ...prevFiberPositions };
  
      Object.keys(fibersOrigin).forEach(fiberId => {
        const fiber = fibersOrigin[fiberId];
        if (fiber.splitterId === id) {
          // Adjust the fiber's position based on the splitter's movement
          updatedPositions[fiberId] = {
            x: prevFiberPositions[fiberId].x + deltaX,
            y: prevFiberPositions[fiberId].y + deltaY
          };
        }
      });
  
      return updatedPositions;
    });

    // Move pigtails (patchlines) and connectors
    unsavedPigtails.forEach(pigtail => {
      if (oldSplitter.fiberIds.includes(pigtail.coreEnd)) {

        // Create a copy of the line array and update the first two points
        const updatedLine = [...pigtail.line];
        
        // Modify the first two points by adding deltaX and deltaY
        updatedLine[0] = pigtail.line[0] + deltaX;  // Update the x-coordinate of the first point
        updatedLine[1] = pigtail.line[1] + deltaY;  // Update the y-coordinate of the first point
        // updatedLine[2] = pigtail.line[2] + deltaX;  // Update the x-coordinate of the second point
        // updatedLine[3] = pigtail.line[3] + deltaY;  // Update the y-coordinate of the second point

        if (!pigtail.isConnected) {
          // If the pigtail is NOT connected, also modify the end of the line (next two points)
          updatedLine[2] = pigtail.line[2] + deltaX;  // Update the x-coordinate of the second point
          updatedLine[3] = pigtail.line[3] + deltaY;  // Update the y-coordinate of the second point
        }

        // Use the existing function to update original points after dragging ends

        // here i'm changing only the original points (the points of the pigtails inside the patch cord component)
        // and not the line array of the pigtails (which are the reference of the dragging === ya3ne i need to change them akid)

        // temp and original points are for the line on the screen only
        // (the lines i see and don't represent the line array inside pigtails)
        // updatePigtailOriginalPoints(pigtail.coreEnd, updatedLine);

        const { linePoints, controlPoints } = calculateLineAndControlPoints(updatedLine[0],
          updatedLine[1], updatedLine[2], updatedLine[3], 5, 12);

        // Find the connector in the unsavedConnectors state by coreEnd
        const connectorOfPigtail = unsavedConnectors.find(connector => connector.coreEnd === pigtail.coreEnd);
        // add saved connectors also

        // Update the connector's position based on the deltas
        const updatedConnector = {
          ...connectorOfPigtail,  // Use the values from the unsavedConnectors state
          topLeftX: connectorOfPigtail.topLeftX + deltaX,
          topLeftY: connectorOfPigtail.topLeftY + deltaY,
          snapX: connectorOfPigtail.snapX + deltaX,
          snapY: connectorOfPigtail.snapY + deltaY,
        };

        // const updatePigtailOriginalPoints = (coreEnd, originalPoints) => {
        setUnsavedPigtails((prevPigtails) =>
          prevPigtails.map((item) =>
            item.coreEnd === pigtail.coreEnd ? 
            { ...item,
              line: updatedLine,
              controlPoints,
	            linePoints,
              tempPoints: [],
              tempControlPoints: [],
              tempLinePoints: [],
              connector: updatedConnector,
              } 
            : item
          )
        );
      
        setSavedPigtails((prevPigtails) =>
          prevPigtails.map((item) =>
            item.coreEnd === pigtail.coreEnd ? 
            { ...item,
              line: updatedLine,
              controlPoints,
	            linePoints,
              tempPoints: [],
              tempControlPoints: [],
              tempLinePoints: [],
              connector: updatedConnector,
              } 
            : item
          )
        );
        // };

        // const updatedConnector = {
        //   ...pigtail.connector,
        //   topLeftX: pigtail.connector.topLeftX + deltaX,
        //   topLeftY: pigtail.connector.topLeftY + deltaY,
        //   snapX: pigtail.connector.snapX + deltaX,
        //   snapY: pigtail.connector.snapY + deltaY
        // };

        // handleUpdateConnectorInsidePigtails(updatedConnector, pigtail.coreEnd);

        // Use the existing function to update the line and connector
        // handleUpdateLineAndConnector(updatedLine, updatedConnector, pigtail.coreEnd);
        // handleUpdateConnector(updatedConnector, pigtail.coreEnd);

        // const handleUpdateLineAndConnector = (updatedPoints, updatedConnector, pigtail.coreEnd) => {
          // setUnsavedPigtails((prevPigtails) =>
          //   prevPigtails.map((item) =>
          //     item.coreEnd === pigtail.coreEnd
          //       ? { ...item, line: updatedLine, connector: updatedConnector }
          //       : item
          //   )
          // );
      
          // Update the unsaved connectors
          setUnsavedConnectors((prevConnectors) =>
            prevConnectors.map((connector) =>
              connector.coreEnd === pigtail.coreEnd
                ? { ...connector, ...updatedConnector }
                : connector
            )
          );
      
          // setSavedPigtails((prevPigtails) =>
          //   prevPigtails.map((item) =>
          //     item.coreEnd === pigtail.coreEnd
          //       ? { ...item, line: updatedLine, connector: updatedConnector }
          //       : item
          //   )
          // );
      
          // Update the unsaved connectors
          setSavedConnectors((prevConnectors) =>
            prevConnectors.map((connector) =>
              connector.coreEnd === pigtail.coreEnd
                ? { ...connector, ...updatedConnector }
                : connector
            )
          );
        // };
      }
    });

    // Update smart lines connected to this splitter's fibers
    setUnsavedSmartLines(prevSmartLines =>
      prevSmartLines.map(smartLine => {
          const { fiberId, endFiberId } = smartLine;
          
          // Create a copy of the smartLine to modify if necessary
          let updatedSmartLine = { ...smartLine };

          if (oldSplitter.fiberIds.includes(fiberId) && oldSplitter.fiberIds.includes(endFiberId)) {
            updatedSmartLine.line = updatedSmartLine.line.map((segment) => {
              // Modify all points in the segment by adding deltaX and deltaY
              return [
                segment[0] + deltaX, // Update startX
                segment[1] + deltaY, // Update startY
                segment[2] + deltaX, // Update endX
                segment[3] + deltaY  // Update endY
              ];
            });
          }

          else if (oldSplitter.fiberIds.includes(fiberId)) {
            // Update all points except for the last two in the line
            updatedSmartLine.line = updatedSmartLine.line.map((segment, index, array) => {
              if (index === array.length - 1) {
                // This is the last segment, so only modify the first two points
                return [segment[0] + deltaX, segment[1] + deltaY, segment[2], segment[3]];
              } else {
                // Modify all points in the earlier segments
                return [segment[0] + deltaX, segment[1] + deltaY, segment[2] + deltaX, segment[3] + deltaY];
              }
            });
          }

          // Check if the end of the smart line belongs to this splitter's fibers
          else if (oldSplitter.fiberIds.includes(endFiberId)) {
            // console.log("Updating end of line for endFiberId: ", endFiberId);
            updatedSmartLine.line = updatedSmartLine.line.map((segment, index) =>
              index === 0
                ? [segment[0], segment[1], segment[2] + deltaX, segment[3] + deltaY]
                : [segment[0] + deltaX, segment[1] + deltaY, segment[2] + deltaX, segment[3] + deltaY]
            );
          }

          return updatedSmartLine;
        })
      );
  };

  // ------------------------------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------------------------------
  // Everything related to cables, tubes and fibers

    // useEffect(() => {
  //   const cables = getUniqueCables(coreEnds);
  //   console.log("the cables present: ", cables);
  //   const cableTubeMap = getTubesForCables(coreEnds);
  //   console.log("the tubes inside each cable: ", cableTubeMap);
  //   const tubeFiberMap = getFibersForTubes(coreEnds);
  //   console.log("the fibers inside each tube: ", tubeFiberMap);
  // }, [coreEnds]);


  // takes core ends and rearranges them in a map form:
  // cable -> tubes inside -> core ends inside each tube
  const getFibersForCablesAndTubes = (data) => {
    const cableTubeFiberMap = {};

    data.forEach(fiber => {
      const cableId = fiber.origin.map_tube.map_cable;
      const tube = fiber.origin.map_tube;
      const tubeId = tube.id;

      // Initialize cable if not present
      if (!cableTubeFiberMap[cableId]) {
        cableTubeFiberMap[cableId] = {};
      }

      // Initialize tube inside the cable if not present
      if (!cableTubeFiberMap[cableId][tubeId]) {
        cableTubeFiberMap[cableId][tubeId] = {
          tubeInfo: tube,
          fibers: new Set()
        };
      }

      // Add fiber to the set of fibers for the specific tube
      cableTubeFiberMap[cableId][tubeId].fibers.add(fiber);
    });

    // Convert sets to arrays for easier use later
    for (const cableId in cableTubeFiberMap) {
      for (const tubeId in cableTubeFiberMap[cableId]) {
        cableTubeFiberMap[cableId][tubeId].fibers = [...cableTubeFiberMap[cableId][tubeId].fibers];
      }
    }

    return cableTubeFiberMap;
  };


  useEffect(() => {
    console.log("core ends are: ", coreEnds);
    const map = getFibersForCablesAndTubes(coreEnds);
    console.log("the fibers present (after getFibersForCablesAndTubes): ", map);
    setCableTubeFiberMap(map);

    // Initialize bare fiber positions
    // const positions = [];
    // Object.keys(map).forEach(cableId => {
    //   Object.keys(map[cableId]).forEach(tubeId => {
    //     map[cableId][tubeId].fibers.forEach((fiber, fiberIndex) => {
    //       if (fiber.bare) {
    //         const position = getFiberPosition(fiber, cableId, fiberIndex, tubeId);
    //         positions.push(position);

    //         // const { x, y } = getFiberPosition(fiber, cableId, fiberIndex);
    //         // positions.push({ x, y, fiber });

    //       }
    //     });
    //   });
    // });
    // console.log("The positions of the bare fibers are :", positions);
    // setBareFiberPositions(positions);

    organizeFibers(coreEnds);


    const fibers = getFiberPositions(cableTubeFiberMap);
    console.log("fibers positions: ", fibers);
    setFiberPositions(fibers);
    setOriginalFiberPositions(fibers); // Store the original positions
  }, [coreEnds]);


  // Organize the fibers and fill the cables, tubes and core ends states
  const organizeFibers = (data) => {
    const newCables = {};
    const newTubes = {};
    const newFibers = {};
  
    data.forEach(fiber => {
      const cableId = fiber.origin.map_tube.map_cable;
      const tube = fiber.origin.map_tube;
      const tubeId = tube.id;
      const fiberId = fiber.id;
  
      // Organize cables
      if (!newCables[cableId]) {
        newCables[cableId] = {
          cableId,
          tubes: new Set()
        };
      }
      newCables[cableId].tubes.add(tubeId);
  
      // Organize tubes
      if (!newTubes[tubeId]) {
        newTubes[tubeId] = {
          tubeId,
          cableId,
          ...tube,
          fibers: new Set()
        };
      }
      newTubes[tubeId].fibers.add(fiberId);
  
      // Organize fibers
      newFibers[fiberId] = {
        ...fiber,
        tubeId,
        cableId
      };
    });
  
    // Convert sets to arrays for easier use later
    for (const cableId in newCables) {
      newCables[cableId].tubes = [...newCables[cableId].tubes];
    }
  
    for (const tubeId in newTubes) {
      newTubes[tubeId].fibers = [...newTubes[tubeId].fibers];
    }
  
    // Update states
    setCables(newCables);
    setTubes(newTubes);
    setFibersOrigins(newFibers);
  };

  // i'm trying having the states as arrays instead of dicts ----> dicts are better cause faster lookups by the key (id)

  // const organizeFibers = (data) => {
  //   const newCables = [];
  //   const newTubes = [];
  //   const newFibers = [];
  
  //   const cableMap = new Map();
  //   const tubeMap = new Map();
  
  //   data.forEach(fiber => {
  //     const cableId = fiber.origin.map_tube.map_cable;
  //     const tube = fiber.origin.map_tube;
  //     const tubeId = tube.id;
  //     const fiberId = fiber.id;
  
  //     // Organize cables
  //     if (!cableMap.has(cableId)) {
  //       cableMap.set(cableId, {
  //         cableId,
  //         tubes: []
  //       });
  //     }
  //     const cableEntry = cableMap.get(cableId);
  //     if (!cableEntry.tubes.includes(tubeId)) {
  //       cableEntry.tubes.push(tubeId);
  //     }
  
  //     // Organize tubes
  //     if (!tubeMap.has(tubeId)) {
  //       tubeMap.set(tubeId, {
  //         tubeId,
  //         cableId,
  //         ...tube,
  //         fibers: []
  //       });
  //     }
  //     const tubeEntry = tubeMap.get(tubeId);
  //     if (!tubeEntry.fibers.includes(fiberId)) {
  //       tubeEntry.fibers.push(fiberId);
  //     }
  
  //     // Organize fibers
  //     newFibers.push({
  //       ...fiber,
  //       tubeId,
  //       cableId
  //     });
  //   });
  
  //   // Convert maps to arrays
  //   newCables.push(...cableMap.values());
  //   newTubes.push(...tubeMap.values());
  
  //   // Update states
  //   setCables(newCables);
  //   setTubes(newTubes);
  //   setFibersOrigins(newFibers);
  // };

  // Get the positions of the fibers inside the tubes of the cables first
  const getFiberPositions = (cableTubeFiberMap) => {
    // const CABLE_WIDTH = 50;
    // const COLUMN_MARGIN = 600;
    // const FIBER_LENGTH = 70;
    // const BASE_TUBE_HEIGHT = 20;
    
    let currentYOffset = 0; // Initialize y-offset for the cables
	  let secondColumnYOffset = 0; // Initialize y-offset for the second column
  
    const fiberPositions = {};
  
    Object.keys(cableTubeFiberMap).forEach((cableId, cableIndex) => {
      const cableData = cableTubeFiberMap[cableId];
      let currentCableHeight = 0;
      
			const tubes = Object.keys(cableData).map((tubeId, tubeIndex) => {
	      const tubeData = cableData[tubeId];
	      const numFibers = tubeData.fibers.length;
	      // const tubeHeight = Math.max(BASE_TUBE_HEIGHT, numFibers * 10); // Dynamic height based on fibers
        const tubeHeight = Math.max(BASE_TUBE_HEIGHT, numFibers * FIBER_OFFSET); // Dynamic height based on fibers
	      // const tubeY = (cableIndex % 2 === 0 ? currentYOffset : secondColumnYOffset) + currentCableHeight + 10; // first time tubeY = 0
        const tubeY = (cableIndex % 2 === 0 ? currentYOffset : secondColumnYOffset) + currentCableHeight + TUBE_OFFSET / 2; // first time tubeY = 0
	      
	      // currentCableHeight += tubeHeight + 20; // 2 * 10 taba3 tube Y
        currentCableHeight += tubeHeight + TUBE_OFFSET; // 2 * 10 taba3 tube Y
	      
				tubeData.fibers.map((fiber, fiberIndex) => {
	        // const fiberY = tubeY + fiberIndex * 10;
          const fiberY = tubeY + fiberIndex * FIBER_OFFSET + (FIBER_OFFSET / 2);
	        // const fiberStart = cableIndex % 2 === 0 ? 2 * CABLE_WIDTH : COLUMN_MARGIN - CABLE_WIDTH + 20;
          const fiberStart = cableIndex % 2 === 0 ? CABLE_WIDTH + TUBE_WIDTH : COLUMN_MARGIN - CABLE_WIDTH - TUBE_WIDTH - FIBER_LENGTH;
	        // const fiberEnd = cableIndex % 2 === 0 ? fiberStart + FIBER_LENGTH : fiberStart - FIBER_LENGTH;
          const fiberEnd = fiberStart + FIBER_LENGTH;
          const circleX = cableIndex % 2 === 0 ? fiberEnd : fiberStart;
          
          fiberPositions[fiber.id] = {
            x: circleX,
            // y: fiberY + 3,
            y: fiberY,
          };
        });
      });

      // const cableYOffset = cableIndex % 2 === 0 ? currentYOffset : secondColumnYOffset;

      // Update y-offset for the next cable
      if (cableIndex % 2 === 0) {
        currentYOffset += currentCableHeight + CABLE_MARGIN;
      } else {
        secondColumnYOffset += currentCableHeight + CABLE_MARGIN;
      }

    });

    return fiberPositions;
  };


  const getCableIndexByFiberId = (fiberId, cableTubeFiberMap) => {
    for (const [cableId, tubes] of Object.entries(cableTubeFiberMap)) {
      for (const [tubeId, tubeData] of Object.entries(tubes)) {
        const fiberFound = tubeData.fibers.find(fiber => fiber.id === fiberId);
        if (fiberFound) {
          const cableIndex = Object.keys(cableTubeFiberMap).indexOf(cableId);
          return cableIndex; // Return the index of the cable
        }
      }
    }
    return null; // Return null if the fiberId is not found
  };

  // Example usage:
  // const fiberId = 115;
  // const cableIndexx = getCableIndexByFiberId(fiberId, cableTubeFiberMap);
  // console.log(`Cable Index for Fiber ID ${fiberId}:`, cableIndexx);



  // const handleFiberDragStart = (e, startX, startY, fiberId) => {
  //   setIsDragging(true);
  //   //setDragStartPoint({ x: startX, y: startY });
  //   //setTempLine({ startX, startY, endX: startX, endY: startY }); // Initialize tempLine

  //   // Reset unsnapped fiber positions
  //   Object.keys(tempLines).forEach(id => {
  //     if (fiberPositions[id]) {
  //         setFiberPositions(prev => ({
  //             ...prev,
  //             [id]: originalFiberPositions[id]
  //         }));
  //     }
  //   });

  //   // i wanna reset also the fibers of the splitters cause they're not part of the originalPositions => i guess i could
  //   // add them to the original positions

  //   // Clear tempLines state
  //   setTempLines({ [fiberId]: { startX, startY, endX: startX, endY: startY } });
  // };


  const handleFiberDragStart = () => {
    setIsDragging(true);
    //setDragStartPoint({ x: startX, y: startY });
    //setTempLine({ startX, startY, endX: startX, endY: startY }); // Initialize tempLine

    // // Reset unsnapped fiber positions
    // Object.keys(tempLines).forEach(id => {
    //   if (fiberPositions[id]) {
    //       setFiberPositions(prev => ({
    //           ...prev,
    //           [id]: originalFiberPositions[id]
    //       }));
    //   }
    // });

    // Reset unsnapped fiber positions (from tempLines array)
    tempLines.forEach(tempLine => {
      const { fiberId } = tempLine;
      if (fiberPositions[fiberId]) {
        setFiberPositions(prev => ({
          ...prev,
          [fiberId]: originalFiberPositions[fiberId]
        }));
      }
    });

    // Reset tempLines array
    setTempLines([]);

    // i wanna reset also the fibers of the splitters cause they're not part of the originalPositions => i guess i could
    // add them to the original positions

    // Clear tempLines state
    // setTempLines({ [fiberId]: { startX, startY, endX: startX, endY: startY } });
  };
  

  const handleFiberDragMove = (e, startX, startY, fiberId, cableIndex, splitterId = null) => {
    if (isDragging) {
      let newX = e.target.x();
      let newY = e.target.y();

      // Check if it's a splitter fiber (splitterId is not null)
      if (splitterId !== null) {
        // Find the splitter in the unsavedSplitters state
        const splitter = unsavedSplitters.find(splitter => splitter.id === splitterId);
        if (splitter) {
          // Adjust newX and newY relative to the splitter's position
          newX = newX + splitter.x;
          newY = newY + splitter.y;
        }
      }

      // console.log(newX);
      // console.log(newY);

      if (isPatchMode) {

        // Update fiber position
      setFiberPositions(prev => ({
        ...prev,
        [fiberId]: { x: newX, y: newY }
      }));

      setCableIndex(cableIndex);
      const { linePoints: tempLinePoints, controlPoints: tempControlPoints } = calculateLineAndControlPoints(startX, startY, newX, newY, 5, 12);
      // First approach, don't create the pigtail during the drag, just fill the temporary states
      setTempCpPoints(tempControlPoints);
      setTempLnPoints(tempLinePoints);
      // calculateLineAndControlPoints(startX, startY, newX, newY, 5, 12, true);
      // calculateTempLineAndControlPoints(startX, startY, newX, newY, 5, 12);
  

      // Implement snapping logic
      let snapped = false;
      let closestConnector = null;
      let minDistance = Infinity;

      const allConnectors = [...savedConnectors, ...unsavedConnectors];

      // Filter out connectors that are already part of a patch connection
      const usedConnectors = new Set(
        unsavedConnectedPatches.flatMap((patch) => [patch.leftConnector.coreEnd, patch.rightConnector.coreEnd])
      );

      const snapXMove = cableIndex % 2 === 0 ? newX - 3 + RECT_WIDTH : newX + 3 - RECT_WIDTH;
      const snapYMove = newY - RECT_HEIGHT / 2;

      allConnectors.forEach((otherConnector) => {
        if (
          fiberId !== otherConnector.coreEnd &&
          cableIndex !== otherConnector.cableIndex &&
          !usedConnectors.has(otherConnector.coreEnd)
        ) {
          const distance = Math.sqrt((snapXMove - otherConnector.snapX) ** 2 + (snapYMove - otherConnector.snapY) ** 2);
          if (distance < SNAP_THRESHOLD && distance < minDistance) {
            snapped = true;
            closestConnector = otherConnector;
            minDistance = distance;
          }
        }
          // console.log("closest connector: ", closestConnector);
          // console.log("snapped is ", snapped);
      });

      if (snapped && closestConnector) {
        const endX = cableIndex % 2 === 0 ? closestConnector.topLeftX - RECT_WIDTH + 3: closestConnector.snapX + RECT_WIDTH - 3;
        const endY = closestConnector.topLeftY + RECT_HEIGHT / 2;
        // calculateLineAndControlPoints(startX, startY, endX, endY, 5, 12, true);
        const { linePoints: tempLinePoints, controlPoints: tempControlPoints } = calculateLineAndControlPoints(startX, startY, endX, endY, 5, 12);
        setTempCpPoints(tempControlPoints);
        setTempLnPoints(tempLinePoints);
        setSnappedConnector(closestConnector);
      } else {
        // calculateLineAndControlPoints(startX, startY, newX, newY, 5, 12, true);
        setSnappedConnector(null);
      }
    }

    else {

      // Update fiber position
      setFiberPositions(prev => ({
        ...prev,
        [fiberId]: { x: newX, y: newY }
      }));
      
      // Snapping logic
      const SNAP_THRESHOLD = 20;
      let snapped = false;
      let snappedPosition = { x: newX, y: newY };
      let closestFiberId = null;
      let minDistance = Infinity;
  
      Object.keys(fiberPositions).forEach((id) => {
        if (
          parseInt(id, 10) !== fiberId &&
          !usedFibers.includes(parseInt(id, 10))
          // !usedFibers.some(pair => pair.mainFiber === parseInt(id, 10) || pair.splicedFiber === parseInt(id, 10))
          // && !tempLines.hasOwnProperty(id)
        ) {
          const pos = fiberPositions[id];
          const distance = Math.sqrt((newX - pos.x) ** 2 + (newY - pos.y) ** 2);
          if (distance < SNAP_THRESHOLD && distance < minDistance) {
            snapped = true;
            snappedPosition = { x: pos.x, y: pos.y };
            closestFiberId = parseInt(id, 10);
            minDistance = distance;
          }
        }
      });
  
      let tempLine;

      // i need to fix it for the splitter
      let offset = (cableIndex % 2 === 0) ? leftOffset : -rightOffset; // Use appropriate offset
      if (startX === snappedPosition.x) {
        // Adjust offset based on the position (left or right)
        // offset = (cableIndex % 2 === 0) ? offset : -offset;

        // For vertical fibers with the same x-coordinate, create a square-shaped line
        tempLine = [[
          startX, startY,
          startX + offset, startY],
          [startX + offset, startY,
          startX + offset, snappedPosition.y],
          [startX + offset, snappedPosition.y,
          snappedPosition.x, snappedPosition.y]
        ];
      } else {
        tempLine = [[
          startX,
          startY,
          snapped ? snappedPosition.x : newX,
          snapped ? snappedPosition.y : newY,
        ]];
      }
      //setTempLines(prevTempLines => ({ ...prevTempLines, [fiberId]: tempLine }));

      // Update tempLines with the current fiber's temporary line
      // setTempLines({ [fiberId]: tempLine });

      // Update tempLines with the current fiber's temporary line
      setTempLines([{ fiberId, endFiberId: closestFiberId, line: tempLine }]);
    }
  }
  };


  const handleFiberDragEnd = (e, startX, startY, fiberId, color_hex, cableIndex, colorId, splitterId = null) => {
    if (isDragging) {

      setIsDragging(false);

      let newX = e.target.x();
      let newY = e.target.y();

      // Check if it's a splitter fiber (splitterId is not null)
      if (splitterId !== null) {
        // Find the splitter in the unsavedSplitters state
        const splitter = unsavedSplitters.find(splitter => splitter.id === splitterId);
        if (splitter) {
          // Adjust newX and newY relative to the splitter's position
          newX = newX + splitter.x;
          newY = newY + splitter.y;
        }
      }

      if (isPatchMode) {
        // no snapping yet (just created a patch cord)

        let topLeftX, topLeftY, patchLine;

        if (snappedConnector) {
          if (cableIndex % 2 === 0) {
            topLeftX = snappedConnector.snapX - RECT_WIDTH;
            topLeftY = snappedConnector.snapY;
          }
          else {
            topLeftX = snappedConnector.snapX;
            topLeftY = snappedConnector.snapY;
          }

          const endX = cableIndex % 2 === 0 ? snappedConnector.topLeftX - RECT_WIDTH + 3: snappedConnector.snapX + RECT_WIDTH - 3;
          const endY = snappedConnector.topLeftY + RECT_HEIGHT / 2;

          patchLine = [startX, startY, endX, endY]

        } else {
      
          topLeftX = cableIndex % 2 === 0 ? newX - 3 : newX - RECT_WIDTH + 3;
          topLeftY = newY - (RECT_HEIGHT / 2);

          patchLine = [startX, startY, newX, newY];

        }

        // const topLeftX = cableIndex % 2 === 0 ? newX - 3 : newX - RECT_WIDTH + 3;
        // // const topLeftX = newX - (RECT_WIDTH / 2);
        // const topLeftY = newY - (RECT_HEIGHT / 2);

        // const selectedConnectorType = connectorTypes.find(type => type.id === parseInt(selectedConnectorTypeId));
        // const selectedPaintType = paintTypes.find(type => type.id === parseInt(selectedPaintTypeId));

        const snapX = cableIndex % 2 === 0 ? topLeftX + RECT_WIDTH : topLeftX;

        const snapY = topLeftY;

        const newConnector = {
          connectorType: selectedConnectorType,
          paintType: selectedPaintType,
          topLeftX: topLeftX,
          topLeftY: topLeftY,
          snapX: snapX,
          snapY: snapY,
          coreEnd: fiberId,
          cableIndex: cableIndex
        };

        if (snappedConnector) {
          let leftConnector, rightConnector, topLeftSnapX, topLeftSnapY;

          if (cableIndex % 2 === 0) {
            leftConnector = newConnector;
            rightConnector = snappedConnector;

            topLeftSnapX = snappedConnector.snapX - RECT_WIDTH;
            topLeftSnapY = snappedConnector.snapY;
    
          }
          else {
            leftConnector = snappedConnector;
            rightConnector = newConnector;

            topLeftSnapX = snappedConnector.topLeftX;
            topLeftSnapY = snappedConnector.topLeftY;
          }

          const newPatchConnect = {
            leftConnector: leftConnector,
            rightConnector: rightConnector,
            x: topLeftSnapX,
            y: topLeftSnapY
          }

          setUnsavedConnectedPatches((prevConnections) => {
            const filteredConnections = prevConnections.filter((connection) => {
              return (
                connection.leftConnector.coreEnd !== newPatchConnect.leftConnector.coreEnd &&
                connection.rightConnector.coreEnd !== newPatchConnect.rightConnector.coreEnd
              );
            });
            return [...filteredConnections, newPatchConnect];
          });
        

        // setUnsavedPigtails((prevPigtails) => {
        //   return prevPigtails.map((pigtail) => {
        //     if (pigtail.coreEnd === boxSnappedConnector.coreEnd && !pigtail.isConnected) {
        //       console.log("Updated pigtail:", {...pigtail, isConnected: true});
        //       return { ...pigtail, isConnected: true }; // Return a new object with updated isConnected
        //     }
        //     return pigtail; // Return the original object if it doesn't match the condition
        //   });
        // });
      }


        setUnsavedConnectors([...unsavedConnectors, newConnector]);

        // const [startX, startY, endX, endY] = patchLine;

        // const { linePoints, controlPoints } = calculateLineAndControlPoints(startX, startY, endX, endY, 5, 12, false);
        const { linePoints, controlPoints } = calculateLineAndControlPoints(startX, startY, patchLine[2], patchLine[3], 5, 12);


        const newPigtail = {
          coreEnd: fiberId,
          line: patchLine,
          connector: newConnector,
          linePoints,
          controlPoints,
          cableIndex,
          isConnected: snappedConnector ? true : false, // effectively sets isConnected to true if boxSnappedConnector is not null
          //(i.e., a valid connector is snapped), and false otherwise.
          // isConnected: !!boxSnappedConnector,
          // !! converts a truthy or falsy value (like boxSnappedConnector) into a boolean (true or false).
        };

        //const newPatchCord = { line: patchLine, fiberId, cableIndex };

        //const totalPatchCords = [...patchCords, newPatchCord];
        // setUnsavedPigtails([...unsavedPigtails, newPigtail]);

        setUnsavedPigtails((prevPigtails) => {
          // Update the isConnected flag for the pigtails
          const updatedPigtails = prevPigtails.map((pigtail) => {
            if (snappedConnector && pigtail.coreEnd === snappedConnector.coreEnd && !pigtail.isConnected) {
              // console.log("Updated pigtail:", {...pigtail, isConnected: true});
              return { ...pigtail, isConnected: true }; // Return a new object with updated isConnected
            }
            return pigtail; // Return the original object if it doesn't match the condition
          });
        
          // Now, add the new pigtail to the updated list
          return [...updatedPigtails, newPigtail]; // Add newPigtail to the updated state
        });

        //setPatchCords(totalPatchCords);
        setTempCpPoints([]);
        setTempLnPoints([]);

        // i wanna also add the fiber used to the used fiber but let's see first in the database how to handle it
        // Add the fiber ID to the usedFibers array
        setUsedFibers(prevState => [...prevState, fiberId]);

        // select the patch cord also


        setSnappedConnector(null);

      }
      else {

      let finalX = newX;
      let finalY = newY;
      let closestFiberId = null;
      let minDistance = Infinity;

      // Snapping logic
      const SNAP_THRESHOLD = 20;

      Object.keys(fiberPositions).forEach((id) => {
        if (
          parseInt(id, 10) !== fiberId &&
          // !usedFibers.some(pair => pair.mainFiber === parseInt(id, 10) || pair.splicedFiber === parseInt(id, 10))
          !usedFibers.includes(parseInt(id, 10))
          //  && !tempLines.hasOwnProperty(id)
        ) {
          const pos = fiberPositions[id];
          const distance = Math.sqrt((newX - pos.x) ** 2 + (newY - pos.y) ** 2);
          if (distance < SNAP_THRESHOLD && distance < minDistance) {
            finalX = pos.x;
            finalY = pos.y;
            closestFiberId = parseInt(id, 10);
            minDistance = distance;
          }
        }
      });

      if (closestFiberId != null) {

        let newLine;
        let offset = 10; // Initial offset value
        if (startX === finalX) {
          if (cableIndex % 2 === 0) {
            offset = leftOffset;
            setLeftOffset(leftOffset + 10);
          } else {
            offset = -rightOffset;
            setRightOffset(rightOffset + 10);
          }
  
          // For vertical fibers with the same x-coordinate, create a square-shaped line
          newLine = [[startX, startY, startX + offset, startY],
                      [startX + offset, startY, startX + offset, finalY],
                      [startX + offset, finalY, finalX, finalY]];
        } else {
          newLine = [[startX, startY, finalX, finalY]];
        }

        const newSmartLine = { line: newLine, color: color_hex, colorId: colorId, fiberId, endFiberId: closestFiberId };
        const updatedSmartLines = [...unsavedSmartLines, newSmartLine];

        setUnsavedSmartLines(updatedSmartLines);

        // Add the splice to unsavedSplices state
        setUnsavedSplices([...unsavedSplices, { main_core: fiberId, spliced_core: closestFiberId }]);

        // // Remove the tempLine for the snapped fiber
        // setTempLines(prevTempLines => {
        //   const { [fiberId]: _, ...rest } = prevTempLines;
        //   return rest;
        // });
        // Remove the tempLine for the snapped fiber
        setTempLines([]);

        setUsedFibers(prevState => [...prevState, fiberId, closestFiberId]);  // Add the fiber ID and the snapped fiber ID to usedFibers array
        //setUsedFibers(prevState => [...prevState, [fiberId, snappedFiberId]]); // Add the fiber pair to usedFibers array
        // setUsedFibers(prevState => [
        //   ...prevState,
        //   {
        //     mainFiber: fiberId,
        //     splicedFiber: closestFiberId,
        //     saved: false
        //   }
        // ]);  // Add the fiber ID and the snapped fiber ID to usedFibers array

        // Select the new line
        setSelectedSmartLine(fiberId);
      }

      }
    }
};



  // -------------------------------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------------------------------
  // Everything related to SmartLines:

  // Update smart line cause it's in a different component
  const handleUpdateLines = (updatedLines, fiberId) => {
    setUnsavedSmartLines((prevSmartLines) =>
      prevSmartLines.map((smartLine) =>
        smartLine.fiberId === fiberId ? { ...smartLine, line: updatedLines } : smartLine
      )
    );
  };

  // -------------------------------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------------------------------
  // Console log for testing and debugging:

  // useEffect(() => {
  //   console.log("cables state: ", cables);
  // }, [cables]);

  // useEffect(() => {
  //   console.log("tubes state: ", tubes);
  // }, [tubes]);

  useEffect(() => {
    console.log("fiber positions: ", fiberPositions);
  }, [fiberPositions]);

  // useEffect(() => {
  //   console.log("connector types: ", connectorTypes);
  // }, [connectorTypes]);

  // useEffect(() => {
  //   console.log("connector paint types: ", paintTypes);
  // }, [paintTypes]);

  useEffect(() => {
    console.log("fibers origin state: ", fibersOrigin);
  }, [fibersOrigin]);

  // useEffect(() => {
  //   console.log("fiber positions: ", fiberPositions);
  // }, [fiberPositions]);

  // useEffect(() => {
  //   console.log("original Fiber Positions: ", originalFiberPositions);
  // }, [originalFiberPositions]);

  // useEffect(() => {
  //   console.log("unsaved splices: ", unsavedSplices);
  // }, [unsavedSplices]);

  // useEffect(() => {
  //   console.log("unsaved connected patches: ", unsavedConnectedPatches);
  // }, [unsavedConnectedPatches]);

  // useEffect(() => {
  //   console.log("saved connected patches: ", savedConnectedPatches);
  // }, [savedConnectedPatches]);

  useEffect(() => {
    console.log("unsaved patches: ", unsavedConnectors);
  }, [unsavedConnectors]);

  // useEffect(() => {
  //   console.log("saved connectors: ", savedConnectors);
  // }, [savedConnectors]);

    useEffect(() => {
      console.log("unsaved pigtails: ", unsavedPigtails);
    }, [unsavedPigtails]);

  // useEffect(() => {
  //   console.log("saved pigtails: ", savedPigtails);
  // }, [savedPigtails]);

  // useEffect(() => {
  //     console.log("unsaved smartlines: ", unsavedSmartLines);
  // }, [unsavedSmartLines]);

  // useEffect(() => {
  //     console.log("tempLines: ", tempLines);
  // }, [tempLines]);


  // useEffect(() => {
  //   console.log("Connector Types: ", connectorTypes);
  //   console.log("Paint Types: ", paintTypes);
  // }, [connectorTypes, paintTypes])

  // useEffect(() => {
  //   console.log("unsaved connectors : ", unsavedConnectors);
  //   console.log("unsaved pigtails : ", unsavedPigtails);
  // }, [unsavedConnectors, unsavedPigtails])

  // useEffect(() => {
  //   console.log("saved connectors : ", savedConnectors);
  //   console.log("saved pigtails : ", savedPigtails);
  // }, [savedConnectors, savedPigtails])

  // useEffect(() => {
  //   console.log("Selected Connector Type: ", selectedConnectorType);
  //   console.log("Selected Paint Type: ", selectedPaintType);
  // }, [selectedConnectorType, selectedPaintType])

    // useEffect(() => {
  //   console.log("saved connectors: ", savedConnectors);
  // }, [savedConnectors])

  // useEffect(() => {
  //   console.log("saved pigtails: ", savedPigtails);
  // }, [savedPigtails])

  // useEffect(() => {
  //   console.log("updated fiber positions: ", fiberPositions);
  //   const coreEndIds = Object.keys(fiberPositions);
  //   console.log("core end ids: ", coreEndIds);
  // }, [fiberPositions]);

  // useEffect(() => {
  //   console.log("original fiber positions: ", fiberPositions);
  // }, [originalFiberPositions]);

  // useEffect(() => {
  //   console.log("selected smart line: ", selectedSmartLine);
  // }, [selectedSmartLine]);

  // useEffect(() => {
  //   console.log("used fibers: ", usedFibers);
  // }, [usedFibers]);

  // useEffect(() => {
  //   console.log("saved splices: ", savedSplices);
  // }, [savedSplices]);

  // useEffect(() => {
  //   console.log("unsaved splices: ", unsavedSplices);
  // }, [unsavedSplices]);

  // useEffect(() => {
  //   console.log("unsaved smart lines: ", unsavedSmartLines);
  // }, [unsavedSmartLines]);

  // useEffect(() => {
  //   console.log("saved smart lines: ", savedSmartLines);
  // }, [savedSmartLines]);

  // useEffect(() => {
  //   console.log("cable Tube Fiber map: ", cableTubeFiberMap);
  // }, [cableTubeFiberMap]);

  // useEffect(() => {
  //   console.log("saved smartines: ", savedSmartLines);
  // }, [savedSmartLines]);

  useEffect(() => {
    console.log("unsaved splitters: ", unsavedSplitters);
  }, [unsavedSplitters]);

  // -------------------------------------------------------------------------------------------------------




  // const updateConnectedPatches = (newPatchConnect, unsnapCoreEnd = null) => {
  //   setUnsavedConnectedPatches((prevConnections) => {
  //     if (unsnapCoreEnd) {
  //       // If unsnapCoreEnd is provided, remove the connection involving this coreEnd
  //       return prevConnections.filter(connection => 
  //         connection.leftConnector.coreEnd !== unsnapCoreEnd && 
  //         connection.rightConnector.coreEnd !== unsnapCoreEnd
  //       );
  //     } else {
  //       // Filter out any existing connections with the same coreEnd for left or right connectors
  //       const filteredConnections = prevConnections.filter((connection) => {
  //         return (
  //           connection.leftConnector.coreEnd !== newPatchConnect.leftConnector.coreEnd &&
  //           connection.rightConnector.coreEnd !== newPatchConnect.rightConnector.coreEnd
  //         );
  //       });
  
  //       // Add the new patch connection to the filtered list
  //       return [...filteredConnections, newPatchConnect];
  //     }
  //   });
  // };

  // const handleConnectorSpecSubmit = (connectorType, paintType) => {
  //   setSelectedConnectorType(connectorType);
  //   setSelectedPaintType(paintType);
  // };


  // check if the fiber is used to remove the circle
  const isFiberUsed = (fiberId) => {
    // return usedFibers.some(pair => pair.mainFiber === fiberId || pair.splicedFiber === fiberId);
    return usedFibers.includes(fiberId);
  };

  const stageRef = useRef(null); // Add this line to create a reference to the stage


  const renderTooltip = (displayText) => (
    <Tooltip className="button-tooltip">
        {displayText}
    </Tooltip>
  );

  const onResize = (event, direction, ref, delta) => {
    const newWidth = ref.style.width;
    const newHeight = ref.style.height;

    const newWindowHeight = parseInt(newHeight, 10) - 50;
    const newWindowWidth = parseInt(newWidth, 10) - 10;

    if (newWindowHeight >= 250) {
        setWindowHeight(newWindowHeight);
    }

    if (newWindowWidth >= 390) {
        setWindowWidth(newWindowWidth);
    }
  };

  // To Do: for the saved pigtails and change the attribute isConnected to false if need be

  const resetAll = () => {
    setUnsavedSmartLines([]);
    setTempLines([]);
    //setUsedFibers([]);
    // setUsedFibers(prevUsedFibers => prevUsedFibers.filter(fiber => fiber.saved));

    // // Collect all fiber IDs from unsavedSplices
    // const fibersToRemove = unsavedSplices.reduce((acc, splice) => {
    //   acc.push(splice.main_core, splice.spliced_core);
    //   return acc;
    // }, []);

    // Collect all fiber IDs from unsavedSplices and unsavedPigtails
    const fibersToRemove = [
      ...unsavedSplices.reduce((acc, splice) => {
        acc.push(splice.main_core, splice.spliced_core);
        return acc;
      }, []),
      ...unsavedPigtails.map(pigtail => pigtail.coreEnd)
    ];

    // Filter out these fiber IDs from usedFibers
    setUsedFibers(prevUsedFibers =>
      prevUsedFibers.filter(fiberId => !fibersToRemove.includes(fiberId))
    );

    setFiberPositions(originalFiberPositions); // Reset to original positions
    setUnsavedSplices([]);

    // Remove any unsavedConnectedPatches that contain one of the unsaved connectors (pigtails)
    setUnsavedConnectedPatches(prevConnections =>
      prevConnections.filter(connection =>
        !unsavedPigtails.some(pigtail =>
          pigtail.coreEnd === connection.leftConnector.coreEnd || pigtail.coreEnd === connection.rightConnector.coreEnd
        )
      )
    );

    setUnsavedPigtails([]);
    setUnsavedConnectors([]);

    // setUnsavedConnectedPatches([]);

    setUnsavedSplitters([]);

    setCutMode(false);
  };


  const toggleFullScreen = () => {
    const newValue = !isFullScreen;
    setIsFullScreen(newValue);

    setWindowHeight(window.innerHeight - 50);
    setWindowWidth(window.innerWidth - 10);

    adjustDimensionsAfterFullscreen(newValue, "konva-box-modal", "konva-box-window-resizable", setWindowHeight, setWindowWidth, defaultWindowHeight, defaultWindowWidth)
  };

// const getUniqueCables = (data) => {
//   const cables = []
//   data.forEach(item => {
//     // hon for origin == maptube akid
//     if(!cables.includes(item.origin.map_tube.map_cable)) {
//       cables.push(item.origin.map_tube.map_cable);
//     }
//   });
//   return cables;
// };

// // Function to get the number of tubes for each cable
// const getTubesForCables = (data) => {
//   const cableTubeMap = {};
//   data.forEach(item => {
//     if (!cableTubeMap[item.origin.map_tube.map_cable]) {
//       cableTubeMap[item.origin.map_tube.map_cable] = new Set();
//     }
//     console.log("cableTubeMap[item.origin.map_tube.map_cable]: ", cableTubeMap[item.origin.map_tube.map_cable]);
//     console.log("item.origin.map_tube: ", item.origin.map_tube);
//     console.log("cableTubeMap: ", cableTubeMap);
//     console.log(cableTubeMap.has(item.origin.map_tube));
//     console.log("----------------------------------------------------------------")
//     // if (item.origin.map_tube != cableTubeMap[item.origin.map_tube.map_cable]) {
//       cableTubeMap[item.origin.map_tube.map_cable].add(item.origin.map_tube);
//     // }
//   });
//   return cableTubeMap;
// }



// Function to get the number of tubes for each cable
// const getTubesForCables = (data) => {
//   const cableTubeMap = {};
//   data.forEach(item => {

//     const cableId = item.origin.map_tube.map_cable;
//     const tube = item.origin.map_tube;
//     const tubeId = tube.id;

//     if (!cableTubeMap[cableId]) {
//       cableTubeMap[cableId] = new Set();
//     }

//     // Check if any tube in the set has the same id as the current tube
//     const isDuplicate = [...cableTubeMap[cableId]].some(tube => tube.id === tubeId);

//     // Add the tube to the set if it's not already present
//     if (!isDuplicate) {
//       cableTubeMap[cableId].add(tube);
//     }

//   });
//   return cableTubeMap;
// }


// Function to get the fibers inside each tube
// const getFibersForTubes = (data) => {
//   const tubeFiberMap = {};

//   data.forEach(item => {

//     const tubeId = item.origin.map_tube.id;
//     // const fiberId = item.id;

//     if (!tubeFiberMap[tubeId]) {
//       tubeFiberMap[tubeId] = new Set(); // tubeId : { }
//     }

//     // Check if any fiber in the set has the same id as the current fiber
//     // const isDuplicate = [...tubeFiberMap[tubeId]].some(fiber => fiber.id === fiberId);

//     // Only add the fiber if it doesn't already exist in the set
//     // if (!isDuplicate) {
//     //   tubeFiberMap[tubeId].add(item);
//     // }

//     tubeFiberMap[tubeId].add(item);

//   });

//   return tubeFiberMap;
// };

  const handleWheel = (e) => {
    handleZoom(e, setStageScale, setStagePosition);
  };

  const handleUpdateConnector = (updatedConnector, coreEnd) => {

    // Update the unsaved connectors
    setUnsavedConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.coreEnd === coreEnd
          ? { ...connector, ...updatedConnector }
          : connector
      )
    );

    // Update the unsaved connectors
    setSavedConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.coreEnd === coreEnd
          ? { ...connector, ...updatedConnector }
          : connector
      )
    );
  };

  // const handleUpdateConnectorInsidePigtails = (updatedConnector, coreEnd) => {
  //   setUnsavedPigtails((prevPigtails) =>
  //     prevPigtails.map((pigtail) =>
  //       pigtail.coreEnd === coreEnd
  //         ? { ...pigtail, connector: updatedConnector }
  //         : pigtail
  //     )
  //   );


  //   setSavedPigtails((prevPigtails) =>
  //     prevPigtails.map((pigtail) =>
  //       pigtail.coreEnd === coreEnd
  //         ? { ...pigtail, connector: updatedConnector }
  //         : pigtail
  //     )
  //   );
  // };


  const handleUpdateLineAndConnector = (updatedPoints, updatedConnector, coreEnd) => {
    setUnsavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd
          ? { ...pigtail, line: updatedPoints, connector: updatedConnector }
          : pigtail
      )
    );

    // Update the unsaved connectors
    setUnsavedConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.coreEnd === coreEnd
          ? { ...connector, ...updatedConnector }
          : connector
      )
    );

    setSavedPigtails((prevPigtails) =>
      prevPigtails.map((pigtail) =>
        pigtail.coreEnd === coreEnd
          ? { ...pigtail, line: updatedPoints, connector: updatedConnector }
          : pigtail
      )
    );

    // Update the unsaved connectors
    setSavedConnectors((prevConnectors) =>
      prevConnectors.map((connector) =>
        connector.coreEnd === coreEnd
          ? { ...connector, ...updatedConnector }
          : connector
      )
    );
  };

  // to update the attribute isConnected of each pigtail after a snapped connection (after a connected patch) and pass it to the patch cord component
  const updateIsConnected = (leftConnectorCoreEnd, rightConnectorCoreEnd) => {
    setUnsavedPigtails(prevPigtails => 
      prevPigtails.map(pigtail => {
        if (pigtail.coreEnd === leftConnectorCoreEnd || pigtail.coreEnd === rightConnectorCoreEnd) {
          return { ...pigtail, isConnected: true };
        }
        return pigtail;
      })
    );
  };

  return (
    <>
      <Modal 
          className="konva-modal"
          id="konva-box-modal"
          show={props.show}
          onHide={props.onHide}
          backdrop={false}
          keyboard={false}
          fullscreen={isFullScreen}
          size="lg"
          animation={false}
          style={{width: "fit-content", height: "fit-content", zIndex: zIndex}}
          // style={isFullScreen ? {width: "", height: "", transform: "translate(-50.01%, -50%)", zIndex: zIndex} : {width: "fit-content", height: "fit-content", zIndex: zIndex}}
          dialogAs={DraggableModalDialog}
          nodeRef={nodeRef}
      >
        <Resizable className="konva-window-resizable" id="konva-box-window-resizable" onResize={onResize}>
          <Modal.Header className="konva-window-header drag-handle" closeButton ref={nodeRef}>
              <Container className="konva-window-toolbar">
                  { showBoxWindowBackButton && <OverlayTrigger placement={"top"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip("Back To Pole")}>
                    <div className={"toolbar-button"} onClick={handleBackButtonPress}>
                      <BackButton></BackButton>
                    </div>
                  </OverlayTrigger>}
                  {/* <OverlayTrigger placement={isFullScreen ? "bottom" : "top"} delay={{ show: 250, hide: 0 }} overlay={renderTooltip(isFullScreen ? "Exit Fullscreen" : "Fullscreen")}> */}
                      {/* <Button className="toolbar-button" style={{borderTopLeftRadius: "5px"}} onClick={toggleFullScreen}>{isFullScreen ? <ExitFullscreen></ExitFullscreen> : <Fullscreen></Fullscreen>}</Button>
                  </OverlayTrigger> */}
                  {/* <div style={{display: "flex", justifyContent: "center", paddingLeft: "12.5px", alignItems: "center"}}>Box: {box.name}</div> */}

                  <Button variant="danger" size="sm" style={{ marginLeft: '10px' }} onClick={resetAll}>Reset</Button>

                  <Button
                    variant="success"
                    size="sm"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      saveSplicesAndSmartLines();
                      saveConnectorsAndPigtails();
                    }}
                  >
                    Save
                  </Button>

                  <Button
                    variant={isPatchMode ? "primary" : "warning"}
                    size="sm"
                    style={{ marginLeft: '10px' }}
                    onClick={toggleMode}
                  >
                    {isPatchMode ? "Patch Mode" : "Fiber Mode"}
                  </Button>

                  <Button
                    variant='info'
                    size="sm"
                    style={{marginLeft: '10px'}}
                    onClick={() => {
                      setSplitterMode(true);
                    }}
                  >
                    Add New Splitter
                  </Button>

                  {unsavedSplitters.some(splitter => splitter.type === '2') && (
                    <Button
                      variant='light'
                      size='sm'
                      onClick={() => setCutMode((prevMode) => !prevMode)}
                      style={{marginLeft: '10px'}}
                    >
                      Cut Pigtails
                    </Button>
                  )}

              </Container>
              {showSuccessMessage && (
                              <Alert variant="success" style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1050 }}>
                                Saved successfully!
                              </Alert>
              )}
          </Modal.Header>

          <Modal.Body className="konva-window-body">
              {isLoading ? (
                  <Placeholder animation="glow">
                      <Placeholder style={{width: windowWidth, height: windowHeight, backgroundColor: "white"}} xs={12}/>
                  </Placeholder>
              ) : (
              <Stage
                ref={stageRef}
                className="konva-stage"
                width={windowWidth}
                height={windowHeight}
                draggable
                scaleX={stageScale}
                scaleY={stageScale}
                x={stagePosition.x}
                y={stagePosition.y}
                onWheel={handleWheel}
                // style={{ backgroundColor: "black" }}  // Change this color to the desired background color
                onClick={(e) => {
                  if (e.target === e.target.getStage()) {
                    setSelectedSmartLine(null);
                  }
                }}
              >
                <Layer className="konva-layer">
                  {/* Render grid lines */}

                  <Rect
                    x={-5000}
                    y={-5000}
                    width={10000} // Make sure the width and height cover your whole stage
                    height={10000}
                    fill="#f0f0f0"  // Set the desired background color
                    listening={false}  // Prevent the rect from capturing mouse events
                  />

                  {[...Array(200).keys()].map((x, index) => (
                      <Line
                          x={-5000}
                          y={-5000}
                          key={`vertical-${index}`}
                          points={[index * 50, 0, index * 50, 9950]} // value should be 50 * number in array - 50
                          stroke="#ccc"
                          strokeWidth={0.5}
                      />
                  ))}
                  {[...Array(200).keys()].map((y, index) => (
                      <Line
                          x={-5000}
                          y={-5000}
                          key={`horizontal-${index}`}
                          points={[0, index * 50, 9950, index * 50]} // value should be 50 * number in array - 50
                          stroke="#ccc"
                          strokeWidth={0.5}
                      />
                  ))}
                  
                  {/* <SmartLine initialLines={initialPoints} stageRef={stageRef} /> */}

                  {/* Include CableLine component */}
                  {/* <CableLine initialPoints={initialPoints} /> */}

                  {/* {Object.keys(fiberPositions).map((fiberId) => {
                    const pos = fiberPositions[fiberId];
                    return (
                      <Circle
                        key={fiberId}
                        x={pos.x}
                        y={pos.y}
                        radius={4}
                        fill="red"
                      />
                    );
                  })} */}

                  {Object.keys(cableTubeFiberMap).map((cableId, cableIndex) => {
                    const cableData = cableTubeFiberMap[cableId];
                    let currentCableHeight = 0;

                    const tubes = Object.keys(cableData).map((tubeId, tubeIndex) => {
                      const tubeData = cableData[tubeId];
                      const numFibers = tubeData.fibers.length;
                      // const tubeHeight = Math.max(BASE_TUBE_HEIGHT, numFibers * 10); // Dynamic height based on fibers
                      const tubeHeight = Math.max(BASE_TUBE_HEIGHT, numFibers * FIBER_OFFSET); // Dynamic height based on fibers
                      const tubeY = (cableIndex % 2 === 0 ? currentYOffset : secondColumnYOffset) + currentCableHeight + (TUBE_OFFSET / 2); // first time tubeY = 10 (currentYOffset and currentCableHeight = 0)

                      currentCableHeight += tubeHeight + TUBE_OFFSET;

                      return (
                        <React.Fragment key={tubeId}>

                          {/* This Rect is for the tube */}
                          <Rect
                            // x={cableIndex % 2 === 0 ? CABLE_WIDTH + 10 : COLUMN_MARGIN - CABLE_WIDTH + 20}
                            x={cableIndex % 2 === 0 ? CABLE_WIDTH : COLUMN_MARGIN - CABLE_WIDTH - TUBE_WIDTH}
                            y={tubeY}
                            // width={CABLE_WIDTH - 10 }
                            width={TUBE_WIDTH }
                            height={tubeHeight}
                            fill={tubeData.tubeInfo.tube_color_hex}
                            stroke="black"
                          />

                          {tubeData.fibers.map((fiber, fiberIndex) => { // fiberIndex starts with 0
                            // const fiberY = tubeY + fiberIndex * 10;
                            const fiberY = tubeY + fiberIndex * FIBER_OFFSET + (FIBER_OFFSET / 2);
                            // const fiberStart = cableIndex % 2 === 0 ? 2 * CABLE_WIDTH : COLUMN_MARGIN - CABLE_WIDTH + 20;
                            const fiberStart = cableIndex % 2 === 0 ? CABLE_WIDTH + TUBE_WIDTH : COLUMN_MARGIN - CABLE_WIDTH - TUBE_WIDTH - FIBER_LENGTH;
                            // const fiberEnd = cableIndex % 2 === 0 ? fiberStart + FIBER_LENGTH : fiberStart - FIBER_LENGTH;
                            const fiberEnd =fiberStart + FIBER_LENGTH
                            const circleX = cableIndex % 2 === 0 ? fiberEnd : fiberStart;
                            // const fiberKey = `${tubeId}-${fiberIndex}`;

                            return (
                              <React.Fragment key={fiber.id}>
                                <Line
                                  // key={fiber.id}
                                  // points={[fiberStart, fiberY + 3, fiberEnd, fiberY + 3]}
                                  points={[fiberStart, fiberY, fiberEnd, fiberY]}
                                  stroke={fiber.origin.core_color_hex}
                                  strokeWidth={3}
                                />

                                {/* {!usedFibers.includes(fiber.id) && ( */}
                                {!isFiberUsed(fiber.id) && (
                                  <Circle
                                    // id={`circle-${fiber.id}`} // Add this line to set the ID for each circle
                                    x={fiberPositions[fiber.id]?.x || circleX}
                                    // y={fiberPositions[fiber.id]?.y || fiberY + 3}
                                    y={fiberPositions[fiber.id]?.y || fiberY}
                                    // x={fiberPositions[fiber.id]?.x || 50}
                                    // y={fiberPositions[fiber.id]?.y || 50}
                                    radius={3}
                                    fill={fiber.origin.core_color_hex}
                                    draggable
                                    // onDragStart={(e) => handleFiberDragStart(e, circleX, fiberY, fiber.id)}
                                    onDragStart={handleFiberDragStart}
                                    onDragMove={(e) => handleFiberDragMove(e, circleX, fiberY, fiber.id, cableIndex)}
                                    onDragEnd={(e) => handleFiberDragEnd(e, circleX, fiberY, fiber.id, fiber.origin.core_color_hex, cableIndex, fiber.origin.core_color_id)}
                                  />
                                )}
                              </React.Fragment>
                            );
                          })}

                        </React.Fragment>
                      );
                    });

                    const cableYOffset = cableIndex % 2 === 0 ? currentYOffset : secondColumnYOffset;

                    // Update y-offset for the next cable
                    if (cableIndex % 2 === 0) {
                      currentYOffset += currentCableHeight + CABLE_MARGIN;
                    } else {
                      secondColumnYOffset += currentCableHeight + CABLE_MARGIN;
                    }
                    // currentYOffset += currentCableHeight + CABLE_MARGIN;

                    
                    // This Rect is for the cable
                    return (
                      <React.Fragment key={cableId}>
                        {tubes}
                        <Rect
                        // x={cableIndex % 2 === 0 ? 10 : 10 + COLUMN_MARGIN}
                        x={cableIndex % 2 === 0 ? 0 : COLUMN_MARGIN - CABLE_WIDTH}
                        // y={currentYOffset - currentCableHeight - CABLE_MARGIN}
                        y={cableYOffset}
                        width={CABLE_WIDTH}
                        height={currentCableHeight}
                        fill='black'
                        stroke='black'
                        />
                      </React.Fragment>
                    );
                  })}

                  {/* {Object.values(tempLines).map((tempLine, index) => (
                    <Line
                      key={`temp-${index}`}
                      points={tempLine}
                      stroke="gray"
                      strokeWidth={2}
                      tension={0.5}
                      dash={[5, 3]} // The first value is the length of the dash, and the second value is the length of the gap.
                    />
                  ))} */}

                  
                  {tempLines.map((tempLineObj) => {
                    const flatPoints = tempLineObj.line.flat(); // Flatten the array of arrays into a flat array of numbers

                    return (
                      <Line
                        key={tempLineObj.fiberId}
                        points={flatPoints} // Pass the flattened array to points
                        stroke="gray"
                        strokeWidth={2}
                        tension={0.5}
                        dash={[5, 3]} // The first value is the length of the dash, and the second value is the length of the gap
                      />
                    );
                  })}
                  
                  {/* {tempLine && (
                    <Line
                      points={tempLine}
                      stroke="gray"
                      strokeWidth={2}
                      tension={0.5}
                      dash={[5, 3]} // The first value is the length of the dash, and the second value is the length of the gap.
                    />
                  )} */}

                  {savedSmartLines.map((lineObj) => (
                    <SmartLine
                      key={lineObj.fiberId}
                      initialLines={lineObj.line}
                      lineColor={lineObj.color}
                      stageRef={stageRef}
                      updateLines={(updatedLines) => handleUpdateLines(updatedLines, lineObj.fiberId)}
                      onClick={() => setSelectedSmartLine(lineObj.fiberId)}
                      showCircles={selectedSmartLine === lineObj.fiberId} // Only show circles if this smart line is selected
                      isUnsaved={false}
                    />
                  ))}

                    {unsavedSmartLines.map((lineObj) => (
                    <SmartLine
                      key={lineObj.fiberId}
                      initialLines={lineObj.line}
                      lineColor={lineObj.color}
                      stageRef={stageRef}
                      updateLines={(updatedLines) => handleUpdateLines(updatedLines, lineObj.fiberId)}
                      onClick={() => setSelectedSmartLine(lineObj.fiberId)}
                      showCircles={selectedSmartLine === lineObj.fiberId} // Only show circles if this smart line is selected
                      isUnsaved={true}
                    />
                  ))}

                  {/* {[...unsavedSmartLines, ...savedSmartLines].map((lineObj, index) => (
                    <SmartLine
                      key={index}
                      initialLines={lineObj.line}
                      lineColor={lineObj.color}
                      stageRef={stageRef}
                      updateLines={(updatedLines) => handleUpdateLines(updatedLines, lineObj.fiberId)}
                      onClick={() => setSelectedSmartLine(index)}
                      showCircles={selectedSmartLine === index} // Only show circles if this smart line is selected
                      isUnsaved={index < unsavedSmartLines.length} // Distinguish unsaved lines
                    />
                  ))} */}


                  {unsavedPigtails.map((lineObj, index) => (
                    <Pigtail
                      key={index}
                      // key={lineObj.coreEnd} : it is unique
                      // initialPoints={lineObj.line}

                      linePoints={lineObj.linePoints}
                      controlPoints={lineObj.controlPoints}

                      tempPoints = {lineObj.tempPoints}
                      tempControlPoints = {lineObj.tempControlPoints}
                      tempLinePoints = {lineObj.tempLinePoints}

                      cableIndex={lineObj.cableIndex}
                      isSaved={false} // Since these are unsaved pigtails
                      // coreEnd={lineObj.coreEnd}
                      connector={lineObj.connector} // This will now contain the full connectorType and paintType objects

                      isBlockless={lineObj.isBlockless}
                      cutMode={cutMode}

                      splitterId={lineObj.splitterId}

                      isConnectorInPatch={isConnectorInPatch(lineObj.coreEnd)}

                      snappedConnector={snappedConnector}

                      handleDragMove={(e) => handleDragConnectorMove(e, lineObj.coreEnd)}
                      handleDragEnd={(e) => handleDragConnectorEnd(e, lineObj.coreEnd)}

                      RECT_WIDTH={RECT_WIDTH}
                      RECT_HEIGHT={RECT_HEIGHT}

                      // or I could pass the coreEnd here
                      // handlePigtailCut={() => handlePigtailCut(lineObj.coreEnd)}
                      handlePigtailCut={handlePigtailCut}
                    />
                  ))}

                  {/* {savedPigtails.map((lineObj, index) => (
                    <Pigtail
                      key={index}
                      initialPoints={lineObj.line}
                      cableIndex={lineObj.cableIndex}
                      isSaved={true} // Since these are saved pigtails
                      coreEnd={lineObj.coreEnd}
                      connector={lineObj.connector} // Pass the connector object

                      savedConnectors={savedConnectors}
                      unsavedConnectors={unsavedConnectors}

                      tempPoints={lineObj.tempPoints}
                      originalPoints={lineObj.originalPoints}

                      unsavedConnectedPatches={unsavedConnectedPatches}
                      savedConnectedPatches={savedConnectedPatches}

                      updateLineAndConnector={(updatedPoints, updatedConnector, coreEnd) =>
                        handleUpdateLineAndConnector(updatedPoints, updatedConnector, coreEnd)
                      }

                      // updateConnectedPatches={updateConnectedPatches}

                      updateIsConnected={updateIsConnected}
                    />
                  ))} */}

                  {/* Here when i drag a fiber to create a pigtail */}
                  {/* tempCpPoints and tempLnPoints are states */}
                  {tempCpPoints.length > 0 && tempLnPoints.length > 1 && (
                    <Shape
                      stroke="gray" // Set the stroke color to gray for the preview
                      strokeWidth={2}
                      dash={[10, 5]}
                      sceneFunc={(ctx, shape) => {
                          ctx.beginPath();
                          ctx.moveTo(tempLnPoints[0].x, tempLnPoints[0].y);

                          for (let i = 0; i < tempCpPoints.length; i++) {
                            const cp = tempCpPoints[i];
                            const next = tempLnPoints[i + 1];
                            ctx.quadraticCurveTo(cp.x, cp.y, next.x, next.y);
                          }

                          ctx.strokeShape(shape);

                          // Draw the dashed rectangle at the last point
                          const lastPoint = tempLnPoints[tempLnPoints.length - 1];
                          
                          // Adjust rectX based on cableIndex (top Left point for the left cable and top left for the right cable)
                          const rectX = cableIndex % 2 === 0 ? lastPoint.x - 3 : lastPoint.x - RECT_WIDTH + 3;

                          const rectY = lastPoint.y - RECT_HEIGHT / 2; // Centering the rectangle vertically

                          ctx.setLineDash([10, 5]); // Set the line dash pattern for the rectangle
                          ctx.strokeRect(rectX, rectY, RECT_WIDTH, RECT_HEIGHT); // Draw the rectangle
                        }
                      }
                    />
                  )}

                  {/* {unsavedConnectedPatches.map((patch, index) => {
                    const handleDragMove = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      // Update the position of the patch in unsavedConnectedPatches
                      updatePatchPosition(index, newX, newY);
                    };

                    const handleDragEnd = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      // Finalize the position after dragging ends
                      finalizePatchPosition(index, newX, newY);
                    };

                    return (
                      <React.Fragment key={index}>
                        // {/* Rectangle */}
                        {/* <Rect
                          x={patch.x}
                          y={patch.y}
                          width={2 * RECT_WIDTH}
                          height={RECT_HEIGHT}
                          fill="red"
                          stroke="red"
                          strokeWidth={2}
                          draggable
                          onDragMove={handleDragMove}
                          onDragEnd={handleDragEnd}
                        /> */}
                        {/* Black vertical line at the center */}
                        {/* <Line
                          points={[
                            patch.x + RECT_WIDTH, patch.y, // Start point (top-center)
                            patch.x + RECT_WIDTH, patch.y + RECT_HEIGHT // End point (bottom-center)
                          ]}
                          stroke="black"
                          strokeWidth={2}
                          listening={false} // Make sure the line is not draggable independently
                        />
                      </React.Fragment>
                    );
                  })} */}

                  {unsavedConnectedPatches.map((connection, index) => {
                    const handleGroupDragMove = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      updatePatchLineAndConnectors(index, newX, newY, false);
                    };

                    const handleGroupDragEnd = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      finalizePatchLineAndConnectors(index, newX, newY, false);
                    };

                    return (
                      <Group
                        key={index}
                        x={connection.x}
                        y={connection.y}
                        draggable
                        onDragMove={handleGroupDragMove}
                        onDragEnd={handleGroupDragEnd}
                      >

                      {/* Left half of the rectangle */}
                      <Rect
                        width={RECT_WIDTH} // Half the total width
                        height={RECT_HEIGHT}
                        fill={connection.leftConnector.paintType.color}
                        // stroke={connection.leftConnector.paintType.color}
                        stroke="#F3CB34"
                        strokeWidth={0.5}
                        dash={[10, 5]} // Apply dashed style
                      />

                      {/* Right half of the rectangle */}
                      <Rect
                        x={RECT_WIDTH} // Position this half to the right
                        width={RECT_WIDTH} // Half the total width
                        height={RECT_HEIGHT}
                        fill={connection.rightConnector.paintType.color}
                        // stroke={connection.rightConnector.paintType.color}
                        stroke="#F3CB34"
                        strokeWidth={0.5}
                        dash={[2, 1]} // Apply dashed style
                      />

                        {/* Vertical line at the center */}
                        <Line
                          points={[
                            RECT_WIDTH, 0, // Start point (top-center)
                            RECT_WIDTH, RECT_HEIGHT // End point (bottom-center)
                          ]}
                          stroke="#F3CB34"
                          strokeWidth={1}
                        />
                      </Group>
                    );
                  })}

                  {savedConnectedPatches.map((connection, index) => {
                    const handleGroupDragMove = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      updatePatchLineAndConnectors(index, newX, newY, true);
                    };

                    const handleGroupDragEnd = (e) => {
                      const newX = e.target.x();
                      const newY = e.target.y();

                      finalizePatchLineAndConnectors(index, newX, newY, true);
                    };

                    return (
                      <Group
                        key={index}
                        x={connection.x}
                        y={connection.y}
                        draggable
                        onDragMove={handleGroupDragMove}
                        onDragEnd={handleGroupDragEnd}
                      >

                      {/* Left half of the rectangle */}
                      <Rect
                        width={RECT_WIDTH} // Half the total width
                        height={RECT_HEIGHT}
                        fill={connection.leftConnector.paintType.color}
                        stroke={connection.leftConnector.paintType.color}
                        strokeWidth={2}
                      />

                      {/* Right half of the rectangle */}
                      <Rect
                        x={RECT_WIDTH} // Position this half to the right
                        width={RECT_WIDTH} // Half the total width
                        height={RECT_HEIGHT}
                        fill={connection.rightConnector.paintType.color}
                        stroke={connection.rightConnector.paintType.color}
                        strokeWidth={2}
                      />

                        {/* Vertical line at the center */}
                        <Line
                          points={[
                            RECT_WIDTH, 0, // Start point (top-center)
                            RECT_WIDTH, RECT_HEIGHT // End point (bottom-center)
                          ]}
                          stroke="#F3CB34"
                          strokeWidth={2}
                        />
                      </Group>
                    );
                  })}


                  {unsavedSplitters.map(splitter => (
                    <Splitter
                      key={splitter.id}
                      {...splitter}
                      onDragSplitterMove={handleSplitterDragMove}
                      onDragSplitterEnd={handleSplitterDragEnd}

                      handleFiberDragStart={handleFiberDragStart}
                      handleFiberDragMove={handleFiberDragMove}
                      handleFiberDragEnd={handleFiberDragEnd}
                      isFiberUsed={isFiberUsed}

                    />
                  ))}

                </Layer>
              </Stage>
            )}
          </Modal.Body>
        </Resizable>
      </Modal>

      <ConnectorSpecificationWindow
      show={showConnectorWindow}
      onHide={() => setShowConnectorWindow(false)}
      onSubmit={handleConnectorSpecSubmit}
      connectorTypes={connectorTypes}
      paintTypes={paintTypes}
      />


      <SplitterSpecificationWindow
        show={splitterMode}
        onHide={() => setSplitterMode(false)}
        onSubmit={handleAddSplitter}
        splitterTypes={splitterTypes}
        connectorTypes={connectorTypes}
        paintTypes={paintTypes}
      />

    </>
  );
};

export default BoxContentWindow;