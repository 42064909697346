import React, { useState, useEffect } from "react";
import PoleProperties from "./ToolProperties/PoleProperties";
import CableProperties from "./ToolProperties/CableProperties";
import BoxProperties from "./ToolProperties/BoxProperties";

const Propbar = (props) => {
    const { selectedTool, setSelectedTool, filledPropertiesList, setFilledPropertiesList, itemPoles, itemCables, itemBoxes, saveTempPoles, removeTempPoles, saveTempCables, removeTempCables, saveTempBoxes, removeTempBoxes, selectedPole, selectedCable, selectedBox, setTriggerPropertiesClear, triggerPropertiesClear } = props;
    const [shouldClearChanges, setShouldClearChanges] = useState(false);

    useEffect(() => {
        if (triggerPropertiesClear) {
            cancelChanges(false);
            setTriggerPropertiesClear(false);
        }
    }, [triggerPropertiesClear]);

    const submitChanges = () => {
        if (selectedTool === "Pole") {
            saveTempPoles();
        } else if (selectedTool === "Cable") {
            saveTempCables();
        } else if (selectedTool === "Box") {
            saveTempBoxes();
        }
    };

    const cancelChanges = (shouldResetTool) => {
        if (selectedTool === "Pole") {
            removeTempPoles(true);
        } else if (selectedTool === "Cable") {
            removeTempCables(true);
        } else if (selectedTool === "Box") {
            removeTempBoxes(true);
        }

        clearChanges();

        if (shouldResetTool) {
            setSelectedTool("Select");
        }
    };

    const clearChanges = () => {
        setShouldClearChanges(true);
    }

    const renderProperties = () => {
        switch (selectedTool) {
            case 'Pole':
                return <PoleProperties
                    filledPropertiesList={filledPropertiesList}
                    setFilledPropertiesList={setFilledPropertiesList}
                    itemPoles={itemPoles}
                    shouldClearChanges={shouldClearChanges}
                    setShouldClearChanges={setShouldClearChanges}
                    selectedPole={selectedPole}
                ></PoleProperties>;
            case 'Cable':
                return <CableProperties
                    filledPropertiesList={filledPropertiesList}
                    setFilledPropertiesList={setFilledPropertiesList}
                    itemCables={itemCables}
                    shouldClearChanges={shouldClearChanges}
                    setShouldClearChanges={setShouldClearChanges}
                    selectedCable={selectedCable}
                ></CableProperties>;
            case 'Box':
                return <BoxProperties
                    filledPropertiesList={filledPropertiesList}
                    setFilledPropertiesList={setFilledPropertiesList}
                    itemBoxes={itemBoxes}
                    shouldClearChanges={shouldClearChanges}
                    setShouldClearChanges={setShouldClearChanges}
                    selectedBox={selectedBox}
                ></BoxProperties>;
            default:
                return null;
        }
    };

    if (selectedTool === "Select" ) {
        return null; // Hide the Propbar if no tool is selected
    }

    return (
        <aside className="propbar">
            <div className="propbar-info">
                <h1>Attributes</h1>
                <p>{selectedTool}</p>
            </div>
            <form className="propform">
                {renderProperties()}
                <div className="submitContainer">
                    <button
                        type="button"
                        className={"propbarbtns submitButton"}
                        onClick={() => submitChanges()}
                    >
                    Submit
                    </button>
                    <button
                        type="button"
                        className={"propbarbtns cancelButton"}
                        onClick={() => cancelChanges(true)}
                    >
                    Cancel
                    </button>
                </div>
            </form>
        </aside>
    );
};

export default Propbar;
