import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import ModalDialog from 'react-bootstrap/ModalDialog';

function DraggableModalDialog({ nodeRef, ...props }) {
  return (
    <Draggable nodeRef={nodeRef} handle=".drag-handle">
      <ModalDialog {...props} ref={nodeRef} />
    </Draggable>
  );
}

DraggableModalDialog.propTypes = {
  nodeRef: PropTypes.object,
};

export default DraggableModalDialog;