import { useEffect, useRef, useState } from "react";

export const ColorSelection = (props) => {
    const { filledPropertiesList, setFilledPropertiesList, shouldClearChanges, selectedColorData } = props;
    const [colorMenuVisible, setColorMenuVisible] = useState(false);
    const [customColorVisible, setCustomColorVisible] = useState(false);
    const [rgbColor, setRgbColor] = useState({ red: 0, green: 0, blue: 0 });
    const [selectedColor, setSelectedColor] = useState(null);
    const colorMenuRef = useRef(null);


    useEffect(() => {
        if (selectedColorData) {
            setColor(selectedColorData);
        }
    }, [selectedColorData]);


    useEffect(() => {
        if (shouldClearChanges) {
            setSelectedColor(null);
        }
    }, [shouldClearChanges]);

    const closeColorMenu = () => {
        setColorMenuVisible(false);
        setCustomColorVisible(false);
    };

    const toggleColorMenu = () => {
        if (!colorMenuVisible) {
            setColorMenuVisible(true);
            setCustomColorVisible(false);
        } else if (customColorVisible) {
            setColorMenuVisible(true);
            setCustomColorVisible(false);
        } else {
            setColorMenuVisible(false);
        }
    };

    const toggleCustomColor = () => {
        setCustomColorVisible(true);
        setColorMenuVisible(false);
    };

    const handleClickOutside = (event) => {
        if (colorMenuRef.current && !colorMenuRef.current.contains(event.target)) {
            setColorMenuVisible(false);
            setCustomColorVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleColorChange = (color, value) => {
        setRgbColor((prev) => ({ ...prev, [color]: value }));
    };

    const setColor = (color, r, g, b) => {
        let finalColor = null;
        if (color !== null) {
            finalColor = color;
            setSelectedColor(color);
        } else {
            finalColor = rgbToHex(r, g, b);
            setSelectedColor(finalColor);
        }
        setFilledPropertiesList((prev) => ({
            ...prev,
            color: finalColor
        }));
        closeColorMenu();
    };

    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    const rgbToHex = (r, g, b) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    };

    return (
        <div className="right-container" ref={colorMenuRef}>
            <button className={`choose-color-btn ${(colorMenuVisible || customColorVisible) ? 'active' : selectedColor !== null ? 'filled' : ''}`} type="button" onClick={toggleColorMenu}>Color</button>
            <button className={`custom-color-btn ${(customColorVisible) ? 'active' : ''}`} type="button" onClick={toggleCustomColor} style={{ display: (colorMenuVisible || customColorVisible) ? 'block' : 'none' }}>
                <i className="edit-icon">✎</i>
            </button>
            <div className="choose-color-menu" style={{ display: colorMenuVisible ? 'grid' : 'none' }}>
                {['#0000ff', '#ff8800', '#14aa00', '#5a3000', '#8e8e8e', '#ffffff', '#ff0000', '#000000', '#fff200', '#4f005c', '#ff00cc', '#00ddff'].map((color) => (
                    <div key={color} className="color-square" style={{ backgroundColor: color }} data-color={color} onClick={() => { setColor(color) }}></div>
                ))}
            </div>
            <CustomColorPicker
                visible={customColorVisible}
                rgbColor={rgbColor}
                onColorChange={handleColorChange}
                setColor={setColor}
            />
        </div>
    );
};

export const CustomColorPicker = ({ visible, rgbColor, onColorChange, setColor }) => {
    return (
        <div className="custom-color-picker" style={{ display: visible ? 'flex' : 'none' }}>
            {['red', 'green', 'blue'].map((color) => (
                <div key={color} className="color-control">
                    <label htmlFor={`${color}-range`}>
                        {color.charAt(0).toUpperCase()}: <span id={`${color}-value`}>{rgbColor[color]}</span>
                    </label>
                    <input
                        type="range"
                        min="0"
                        max="255"
                        id={`${color}-range`}
                        className={`${color}-range`}
                        value={rgbColor[color]}
                        onChange={(e) => onColorChange(color, parseInt(e.target.value))}
                    ></input>
                </div>
            ))}
            <div className="preview-apply">
                <button id="apply-color" type="button" onClick={() => setColor(null, rgbColor.red, rgbColor.green, rgbColor.blue)}>Apply</button>
                <div className="color-preview" style={{ backgroundColor: `rgb(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue})` }}></div>
            </div>
        </div>
    );
};