import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SplitterSpecificationWindow = ({ show, onHide, onSubmit, splitterTypes, connectorTypes, paintTypes }) => {

  const availableColors = ['gray', 'black', 'red', 'blue', 'green', 'yellow'];

  const [splitterName, setSplitterName] = useState('');
  const [splitterInfo, setSplitterInfo] = useState('');
  const [nbOfInputs, setNbOfInputs] = useState(1);
  const [nbOfOutputs, setNbOfOutputs] = useState(4);
  // implement percentage for each output fiber later
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [isEqual, setIsEqual] = useState(true);
  const [canClose, setCanClose] = useState(false);
  const [selectedConnectorType, setSelectedConnectorType] = useState('');
  const [selectedPaintType, setSelectedPaintType] = useState('');

  const handleSplitterTypeChange = (e) => {
    const selectedTypeValue = e.target.value;
    setSelectedType(selectedTypeValue);

    // Clear connector type and paint type if switching back to type 1
    if (selectedTypeValue === '1') {
      setSelectedConnectorType('');
      setSelectedPaintType('');
    }
  };

  const handleColorChange = (e) => setSelectedColor(e.target.value);
  const handleNameChange = (e) => setSplitterName(e.target.value);
  const handleInfoChange = (e) => setSplitterInfo(e.target.value);
  const handleNbOfInputsChange = (e) => setNbOfInputs(e.target.value);

  const handleNbOfOutputsChange = (e) => {
    const value = e.target.value;
    setNbOfOutputs(value);
    
    // Automatically set `isEqual` based on the value of `nbOfOutputs`
    if (value > 6) {
      setIsEqual(false); // Auto set to false when nbOfOutputs > 6
    } else {
      setIsEqual(true); // Auto set to true when nbOfOutputs <= 6
    }
  };

  const handleIsEqualChange = (e) => {
    // Only allow the user to toggle the `isEqual` field manually if nbOfOutputs is <= 6
    if (nbOfOutputs <= 6) {
      setIsEqual(e.target.checked);
    }
  };

  // const handleSubmit = () => {
  //   if (splitterName && nbOfInputs && nbOfOutputs && selectedColor && selectedType) {
  //     onSubmit({
  //       name: splitterName,
  //       info: splitterInfo,
  //       nbOfInputs,
  //       nbOfOutputs,
  //       color: selectedColor,
  //       type: selectedType,
  //       isEqual,
  //     });
  //     onHide();
  //   }
  // };

  const handleSubmit = () => {
    // Ensure that all required fields are filled
    if (!splitterName || !nbOfInputs || !nbOfOutputs || !selectedColor || !selectedType) {
      alert("Please fill out all the required fields.");
      return;
    }

    // If the selected type is not '1', connectorType and paintType are required
    if (selectedType !== '1' && (!selectedConnectorType || !selectedPaintType)) {
      alert("Please select both connector type and paint type.");
      return;
    }

    const splitterData = {
      name: splitterName,
      info: splitterInfo,
      nbOfInputs,
      nbOfOutputs,
      color: selectedColor,
      type: selectedType,
      isEqual,
    };

    // Include connector type and paint type if splitter type is not 1
    if (selectedType !== '1') {
      const connectorTypeObj = connectorTypes.find(type => type.id === parseInt(selectedConnectorType));
      const paintTypeObj = paintTypes.find(type => type.id === parseInt(selectedPaintType));

      splitterData.connectorType = connectorTypeObj;  // Full connectorType object
      splitterData.paintType = paintTypeObj;          // Full paintType object
    }

    onSubmit(splitterData);
    onHide();
  };

  // useEffect(() => {
  //   // Enable closing when required fields are filled
  //   if (splitterName && nbOfInputs && nbOfOutputs && selectedColor && selectedType) {
  //     setCanClose(true);
  //   } else {
  //     setCanClose(false);
  //   }
  // }, [splitterName, nbOfInputs, nbOfOutputs, selectedColor, selectedType]);

  useEffect(() => {
    // Enable close only when required fields are filled
    if (splitterName && nbOfInputs && nbOfOutputs && selectedColor && selectedType) {
      if (selectedType !== '1') {
        // For types other than '1', connectorType and paintType must also be filled
        if (selectedConnectorType && selectedPaintType) {
          setCanClose(true);
        } else {
          setCanClose(false);
        }
      } else {
        setCanClose(true);
      }
    } else {
      setCanClose(false);
    }
  }, [splitterName, nbOfInputs, nbOfOutputs, selectedColor, selectedType, selectedConnectorType, selectedPaintType]);

  return (
    <Modal 
        show={show} 
        onHide={() => canClose && onHide()} // Only allow closing if valid
        centered 
        style={{ zIndex: 2000 }} // Increase z-index to ensure it appears above the BoxContentWindow
        size="md" 
        backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton={canClose}>
        <Modal.Title>Add Splitter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSplitterName">
            <Form.Label>Splitter Name</Form.Label>
            <Form.Control
              type="text"
              value={splitterName}
              onChange={handleNameChange}
              placeholder="Enter splitter name"
              // style={{
              //   color: 'black',          // Ensure typed text is black
              //   textAlign: 'left',       // Ensure text and placeholder are left-aligned
                
              //   // jawad bizabbit l CSS cause akid e5din css henne
              // }}
            />
          </Form.Group>

          <Form.Group controlId="formSplitterInfo" className="mt-3">
            <Form.Label>Splitter Info</Form.Label>
            <Form.Control
              as="textarea"
              value={splitterInfo}
              onChange={handleInfoChange}
              placeholder="Enter splitter info (optional)"
            />
          </Form.Group>

          <Form.Group controlId="formNbOfInputs" className="mt-3">
            <Form.Label>Number of Inputs</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={nbOfInputs}
              onChange={handleNbOfInputsChange}
            />
          </Form.Group>

          <Form.Group controlId="formNbOfOutputs" className="mt-3">
            <Form.Label>Number of Outputs</Form.Label>
            <Form.Control
              type="number"
              min={1}
              value={nbOfOutputs}
              onChange={handleNbOfOutputsChange}
            />
          </Form.Group>

          <Form.Group controlId="formColor" className="mt-3">
            <Form.Label>Color</Form.Label>
            <Form.Control as="select" value={selectedColor} onChange={handleColorChange}>
              <option value="">Select a color</option>
              {availableColors.map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formType" className="mt-3">
            <Form.Label>Type</Form.Label>
            <Form.Control as="select" value={selectedType} onChange={handleSplitterTypeChange}>
              <option value="">Select a type</option>
              {splitterTypes.map((type) => (
                <option key={type.id} value={type.id}>
                {/* <option key={type.id} value={type.name}> */}
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Conditionally render connector type and paint type fields if type is not 1 */}
          {selectedType !== '1' && (
            <>
              <Form.Group controlId="formConnectorType" className="mt-3">
                <Form.Label>Connector Type</Form.Label>
                <Form.Control 
                  as="select" 
                  value={selectedConnectorType} 
                  onChange={(e) => setSelectedConnectorType(e.target.value)}
                >
                  <option value="">Select a connector type</option>
                  {connectorTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formPaintType" className="mt-3">
                <Form.Label>Connector Paint Type</Form.Label>
                <Form.Control 
                  as="select" 
                  value={selectedPaintType} 
                  onChange={(e) => setSelectedPaintType(e.target.value)}
                >
                  <option value="">Select a paint type</option>
                  {paintTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type} - {type.color}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          )}

          <Form.Group controlId="formIsEqual" className="mt-3">
            <Form.Check 
              type="checkbox" 
              label="Is Equal" 
              checked={isEqual} 
              onChange={handleIsEqualChange} 
              disabled={nbOfOutputs > 6} // Disable checkbox when nbOfOutputs > 6
            />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit} disabled={!canClose}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SplitterSpecificationWindow;
