import React, {useEffect, useContext} from 'react';
import { useDataContext } from '../../context/DataContext';
import AuthContext from '../../context/AuthContext';
import Map from '../../components/Map';
import { Container } from 'react-bootstrap';

function MapPage() {

  // const { itemPoles, itemCables, itemBoxes, selectedCompanyId } = useDataContext();
  const { itemPoles, itemCables, itemBoxes } = useDataContext();
  let {authTokens} = useContext(AuthContext)


  // useEffect(() => {
  //   // getPoles();
  //   console.log(itemPoles);
  // }, [itemPoles]);

  if (!itemPoles && !itemCables && !itemBoxes) {
    return <div>Loading...</div>; // Add loading indicator if itemPoles is undefined
  }

  return (
    <Container fluid className="map-page-container">
      <Map
          itemPoles={itemPoles}
          authTokens={authTokens}
          itemCables={itemCables}
          itemBoxes={itemBoxes}
          // id = {selectedCompanyId}
      />
    </Container>
  );
}

export default MapPage;