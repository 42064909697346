import { useEffect, useRef, useState } from "react";
import { ColorSelection } from "../ColorPicker";

const CableProperties = (props) => {
    const {
        filledPropertiesList,
        setFilledPropertiesList,
        itemCables,
        shouldClearChanges,
        setShouldClearChanges,
        selectedCable
    } = props;

    const [filledInputs, setFilledInputs] = useState({ name: false, info: false });
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("Type");
    const [inputValues, setInputValues] = useState({ name: '', info: '' });
    const [selectedColor, setSelectedColor] = useState(null);

    // Populate fields when the selected cable changes
    useEffect(() => {
        if (selectedCable) {
            const { id, name, info, item_cable, color } = selectedCable;

            setSelectedColor(color);
            setInputValues({ name: name || '', info: info || '' });
            handleSelectItemClick(itemCables.find(item => item.id === item_cable));

            setFilledInputs({ name: !!name, info: !!info });
            setFilledPropertiesList({ id: id, name: name || '', info: info || '', item_cable: parseInt(item_cable) || '' });
        }
    }, [selectedCable, itemCables, setFilledPropertiesList]);

    useEffect(() => {
        if (shouldClearChanges) {
            setInputValues({ name: '', info: '' });
            setSelectedItem("Type");
            setFilledInputs({ name: false, info: false });
            setFilledPropertiesList([]);
            setShouldClearChanges(false);
        }
    }, [shouldClearChanges, setFilledPropertiesList, setShouldClearChanges]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prev) => ({
            ...prev,
            [name]: value
        }));
        setFilledInputs((prev) => ({
            ...prev,
            [name]: value.trim() !== ""
        }));
        setFilledPropertiesList((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectToggle = () => setSelectOpen((prev) => !prev);

    const handleSelectItemClick = (item) => {
        setSelectedItem(item.type + " " + item.mode + " " + item.tubes_count + "/" + item.fibers_count);
        setSelectOpen(false);
        setFilledPropertiesList((prev) => ({
            ...prev,
            item_cable: parseInt(item.id)
        }));
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!event.target.closest('.custom-select')) {
                setSelectOpen(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <>
            {['name', 'info'].map((inputName) => (
                <input
                    key={inputName}
                    type="text"
                    className={`props propstxt ${filledInputs[inputName] ? 'filled' : ''}`}
                    name={inputName}
                    placeholder={inputName === 'name' ? "Name" : "Info"}
                    autoComplete="off"
                    value={inputValues[inputName]}
                    onChange={handleInputChange}
                />
            ))}
            <div className="props">
                <div className={`custom-select ${selectOpen ? 'active' : ''}`}>
                    <div
                        className={`select-selected ${selectOpen ? 'active' : ''}`}
                        onClick={handleSelectToggle}
                    >
                        <span className={selectedItem !== 'Type' ? 'select-filled' : ''}>{selectedItem}</span>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="svgicons" viewBox="0 0 24 24">
                                <path d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
                            </svg>
                        </div>
                    </div>
                    <div className={`select-items ${selectOpen ? 'active' : ''}`}>
                        {itemCables.map((item) => (
                            <div key={item.id} onClick={() => handleSelectItemClick(item)}>
                                {item.type} {item.mode} {'\n'}
                                {item.tubes_count} tubes {'\n'}
                                {item.fibers_count} fibers
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ColorSelection filledPropertiesList={filledPropertiesList} setFilledPropertiesList={setFilledPropertiesList} shouldClearChanges={shouldClearChanges} selectedColorData={selectedColor} />
        </>
    );
};

export default CableProperties;