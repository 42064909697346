import { Modal, Form, Button } from 'react-bootstrap';
import AuthContext from '../context/AuthContext'
import { useContext, useState } from 'react'

const LoginModal = (props) => {

	let {loginUser, signUpUser, error, clearErrors} = useContext(AuthContext)
	let [isLoggingIn, setIsLoggingIn] = useState(true); 

	const executeLogin = async (event) => {
		event.preventDefault();
		if (await loginUser(event) === true) {
			props.onHide();
		}
	}

	const executeSignUp = async (event) => {
		event.preventDefault();
		if (await signUpUser(event) === true) {
			setIsLoggingIn(true);
		}
	}

	const statusChanged = (isLoggingIn) => {
		clearErrors();
		setIsLoggingIn(isLoggingIn);
	}

  return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			backdrop="static"
			keyboard={false}
			centered
			size="md"
		>
			<Modal.Header closeButton>
				<Modal.Title>{isLoggingIn ? "Log In" : "Sign Up"}</Modal.Title>
			</Modal.Header>
			<Modal.Body hidden={!isLoggingIn}>
				<Form onSubmit={executeLogin}>
					<Form.Group className="mb-3">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" name="email" placeholder="Enter email"/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" name="password" placeholder="Enter Password"/>
					</Form.Group>
					<p>Not registered? <Button variant="link" onClick={() => statusChanged(false)} style={{padding: "0px", borderBottomWidth: "6px"}}>Sign Up</Button></p>
					<Button className="loginButton" variant="primary" type="submit">Submit</Button>
				</Form>
				{error && <p className="error-message">{error}</p>}
			</Modal.Body>
			<Modal.Body hidden={isLoggingIn}>
				<Form onSubmit={executeSignUp}>
					<Form.Group className="mb-3">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" name="email" placeholder="Enter email"/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>First Name</Form.Label>
						<Form.Control type="text" name="first_name" placeholder="Enter first name"/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Last Name</Form.Label>
						<Form.Control type="text" name="last_name" placeholder="Enter last name"/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Enter Password" name="password"/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Confirm Password</Form.Label>
						<Form.Control type="password" placeholder="Repeat Password" name="confirm_password"/>
					</Form.Group>
					<p>Already registered? <Button variant="link" onClick={() => statusChanged(true)} style={{padding: "0px", borderBottomWidth: "6px"}}>Log In</Button></p>
					<Button className="loginButton" variant="primary" type="submit">Submit</Button>
				</Form>
				{error && <p hidden={isLoggingIn} className="error-message">{error}</p>}
			</Modal.Body>
		</Modal>
  );
}

export default LoginModal;