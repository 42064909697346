import React, { useState, useEffect } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import KonvaPoleWindow from './KonvaPoleWindow';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import BoxContentWindow from './BoxContentWindow';
import Toolbar from './Toolbar';
import Propbar from './Propbar';
import ContextMenu from './ContextMenu';
import buildingPin from '../assets/city-variant.svg';
import manholePin from '../assets/manhole.svg';
import polePin from "../assets/Pole.svg";

const BASE_URL = process.env.REACT_APP_API_URL;

const Map = (props) => {

    // const { id } = useParams();

    const { itemPoles, authTokens, itemCables, itemBoxes } = props;

    // Extract the company ID from local storage as a fallback
    const id = localStorage.getItem("selectedCompanyId");

    const [isLoading, setIsLoading] = useState(true);
    const [selectedWindow, setSelectedWindow] = useState(null);
    const [selectedTool, setSelectedTool] = useState("Select");
    const [filledPropertiesList, setFilledPropertiesList] = useState([]);
    const [isEdittingPole, setIsEdittingPole] = useState(false);
    const [isEdittingCable, setIsEdittingCable] = useState(false);
    const [isEdittingBox, setIsEdittingBox] = useState(false);
    const [triggerPropertiesClear, setTriggerPropertiesClear] = useState(false);
    const [showBoxWindowBackButton, setShowBoxWindowBackButton] = useState(false);


    useEffect(() => {
        console.log("bla bla bla", authTokens)
    }, [authTokens]);

    const [poles, setPoles] = useState([]);
    const [tempPoles, setTempPoles] = useState([]);
    const [cables, setCables] = useState([]);
    const [tempCables, setTempCables] = useState([]);
    const [boxes, setBoxes] = useState([]);
    const [tempBoxes, setTempBoxes] = useState([]);

    const [addingCable, setAddingCable] = useState(false);
    const [cableRoutes, setCableRoutes] = useState([]);
    const [tempRoutes, setTempRoutes] = useState([]);
    const [currentRoute, setCurrentRoute] = useState([]);
    const [selectedPole, setSelectedPole] = useState(null);
    const [selectedCable, setSelectedCable] = useState(null);
    const [selectedBox, setSelectedBox] = useState(null);

    // Konva Pole Window
    const [poleWindow, setPoleWindow] = useState(false);
    const [boxWindow, setBoxWindow] = useState(false);

    const [startPole, setStartPole] = useState(null);
    const [endPole, setEndPole] = useState(null);

    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        options: []
    });

	const handleMarkerRightClick = (leafletEvent, pole, cable) => {
		const { originalEvent } = leafletEvent;
		originalEvent.preventDefault();

		const isPole = pole !== null;

        if (selectedTool === "Select") {
            setContextMenu({
                visible: true,
                x: originalEvent.clientX,
                y: originalEvent.clientY,
                options: isPole ? [
                    { label: 'Open', onClick: () => {handleMarkerDblClick(pole, null)} },
                    { label: 'Move', onClick: () => console.log('Move', pole) },
                    { label: 'Edit', onClick: () => {
                        setSelectedTool("Pole");
                        setTimeout(() => {
                            setIsEdittingPole(true);
                            setSelectedPole(pole);
                        }, 0);
                    }},
                    { label: 'Delete', onClick: () => console.log('Delete', pole) }
                ] :
                [
                    { label: 'Move', onClick: () => console.log('Move', cable) },
                    { label: 'Edit', onClick: () => {
                        setSelectedTool("Cable");
                        setTimeout(() => {
                            setIsEdittingCable(true);
                            setSelectedCable(cable);
                        }, 0);
                    }},
                    { label: 'Cut', onClick: () => console.log('Cut', cable) },
                    { label: 'Duplicate', onClick: () => console.log('Duplicate', cable) },
                    { label: 'Delete', onClick: () => console.log('Delete', cable) }
                ]
            });
        }
	};

	const handleContextMenu = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		document.addEventListener('contextmenu', handleContextMenu);
		return () => {
				document.removeEventListener('contextmenu', handleContextMenu);
		};
	}, []);

    useEffect(() => {
        if (id) {
            getPoles();
            getCables();
            getBoxes();
        }
    }, [id]);

    useEffect(() => {
        console.log(poles);
    }, [poles]);

    useEffect(() => {
        const routes = cables.map(cable => cable.route.coordinates);
        setCableRoutes(routes);
    }, [cables]);

    const [boxInfo, setBoxInfo] = useState({
        name: '',
        info: '',
        company: parseInt(id),
        item_box: itemBoxes[0].id,
        color: 'red',
        connected_pole: null
    });

    const [cableInfo, setCableInfo] = useState({
        route: [],
        name: "",
        info: "",
        item_cable: itemCables[0].id,
        // item_cable: null,
        company: parseInt(id),
        start_pole: null,
        end_pole: null
    })

    const [cableReady, setCableReady] = useState(false);


    useEffect(() => {
        // console.log("The current route is:", currentRoute);
        if (endPole != null) { // bkoun hon kabaset 3ala last pole
            // setCableRoutes([...cableRoutes, currentRoute]);
            setCableInfo({
                ...cableInfo,
                route: currentRoute
            });
            console.log("route updated: " + currentRoute);
            // setStartPole(null);
            // submitCable();
            setCableReady(true);
            // setEndPole(null);
            // toggleAddingCable();
            // console.log(cableRoutes);
        }
    }, [currentRoute]);

    useEffect(() => {
        if (cableReady) {
            submitTempCable();
            setCableReady(false);
            setStartPole(null);
            setEndPole(null);
            toggleAddingCable();
            // console.log(cableRoutes);
        }
    }, [currentRoute, cableReady])

    // used for single click events
    const handleMarkerClick = (pole, cable) => {

        if (selectedTool === "Cable") {
            if (pole !== null) {
                if (!isEdittingCable) {
                    if (filledPropertiesList.name == null || filledPropertiesList.info == null || filledPropertiesList.item_cable == null) {
                        console.log("please fill in the required fields first");
                        return;
                    }
        
                    if (startPole == null && endPole == null) {
                        // Start creating a cable with the start pole
                        setStartPole(pole);
                        setCableInfo({
                            ...cableInfo,
                            start_pole: pole.id
                        });
                        setCurrentRoute([pole.coords]); // Start with the first point as the clicked pole
                    }
                    else if (startPole != null && endPole == null) {
                        // setEndPole(pole);
                        setCableInfo({
                            ...cableInfo,
                            end_pole: pole.id
                        });
                        setCurrentRoute((prevRoute) => [...prevRoute, pole.coords]); // pole.coords is an array so [...prevRoute, [pole.location]] 8alat
                        setEndPole(pole);
                    }
                }
            } else {
                // editting mode for cable
                setIsEdittingCable(true);
                setSelectedCable(cable);
            }
        } else if (selectedTool === "Pole") {
            // editting mode for pole
            setIsEdittingPole(true);
            setSelectedPole(pole);
        }
    }

    // used for dbl click events
    const handleMarkerDblClick = (pole, cable) => {

		const isPole = pole !== null;

		if (isPole) {
			if (selectedTool === "Select") {
				setPoleWindow(true);
				setSelectedPole(pole);
        	}
		} else {
			// TODO add cable dbl click functionality
		}
    }

    const toggleAddingCable = () => {
        if (addingCable) {
            if (endPole != null) {
                setAddingCable(false);
                setCurrentRoute([]);
            }
            else {
                console.log("You can't end a cable without a pole...");
            }
        }
        else {
            setAddingCable(true);
        }
    }

    // Konva Window Functions
    const closeKonvaPoleWindow = () => {
        setPoleWindow(false);
        setSelectedTool("Select");
    }

    const closeKonvaBoxWindow = () => {
        setBoxWindow(false);
        setShowBoxWindowBackButton(false);
    }

    const handleBoxChange = (e) => {
        setBoxInfo({
            ...boxInfo,
            [e.target.id]: e.target.value,
        });
    };

    const handleMapClick = ({ latlng }) => { // i can add here if close to pole by certain distance for cables and boxes
        if (selectedTool === "Pole" && latlng) {

            // only add temp poles on map if not currently editting a pole
            if (!isEdittingPole) {
                const { lat, lng } = latlng;
                submitTempPole(lat, lng);
            }
  
        }
        else if (selectedTool === "Cable" && latlng) {

            if (!isEdittingCable) {
                if (filledPropertiesList.name == null || filledPropertiesList.info == null || filledPropertiesList.item_cable == null) {
                    console.log("please fill in the required fields first");
                    return;
                }
    
                if (startPole != null && endPole == null) { // this is the middle of the cable
                    const { lat, lng } = latlng;
                    // console.log(lat, lng);
                    // console.log(currentRoute);
                    setCurrentRoute((prevRoute) => [...prevRoute, [lat, lng]]);
                }
                else if (startPole == null && endPole == null) { // start a cable without a pole ---> ba3den add a pole awwal shi
                    console.log("You can't start a cable without a pole... ");
                }
            }
        }
    }

    const getPoles = async () => {
        try {
            const response = await fetch(`${BASE_URL}/map/poles/${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPoles(data);
            // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching poles:', error);
            setIsLoading(false);
        }
    }

    const getCables = async () => {
        try {
            const response = await fetch(`${BASE_URL}/map/cables/${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCables(data);
            // setIsLoading(false);
        } catch (error) {
            console.error('Error fetching cables:', error);
            setIsLoading(false);
        }
    }

    const getBoxes = async () => {
        try {
            const response = await fetch(`${BASE_URL}/map/boxes/${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setBoxes(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching boxes:', error);
            setIsLoading(false);
        }
    }

    const submitTempPole = (lat, lng) => {

        const poleData = {
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            item_pole: filledPropertiesList.type,
            color: filledPropertiesList.color,
            company: parseInt(id),
            location: [lat, lng]
        };

        if (poleData.name == null || poleData.info == null || poleData.item_pole == null || poleData.color == null) {
            console.log("fields haven't been filled in for the pole")
            return;
        }

        setTempPoles([...(tempPoles || []), poleData]);
    }

    const savePoleChanges = () => {
        if (isEdittingPole) {
            upsertPole();
        } else {
            saveTempPoles();
        }
    }

    const upsertPole = async () =>  {
        console.log("upserting pole changes");

        const poleData = {
            id: filledPropertiesList.id,
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            item_pole: filledPropertiesList.type,
            color: filledPropertiesList.color,
        };

        console.log(poleData);
        
        try {
            // TODO add update/upsert endpoint
            const response = await fetch(`${BASE_URL}/map/poles/update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                },
                body: JSON.stringify(poleData),
            })

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPoles([...(poles || []), data]);
        } catch(error) {
            console.error('Error upserting pole:', error);
        }

        resetPoleInfo(true);
    }

    const saveTempPoles = () => {
        
        try {
            tempPoles.forEach(async item => {
                const response = await fetch(`${BASE_URL}/map/poles/create/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + String(authTokens.access)
                    },
                    body: JSON.stringify(item),
                })

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                setPoles([...(poles || []), data]);
            });
        } catch(error) {
            console.error('Error saving temp poles:', error);
        }

        resetPoleInfo(true);
    }

    const submitTempCable = () => {

        const cableToSave = {
            ...cableInfo,
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            color: filledPropertiesList.color,
            item_cable: filledPropertiesList.item_cable,
        }

        setTempCables([...(tempCables || []), cableToSave]);
        setTempRoutes([...tempRoutes, cableToSave.route]);
        
    }

    const saveCableChanges = () => {
        if (isEdittingCable) {
            upsertCable();
        } else {
            saveTempCables();
        }
    }

    const upsertCable = async () =>  {
        console.log("upserting cable changes");

        const cableData = {
            id: filledPropertiesList.id,
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            color: filledPropertiesList.color,
            item_cable: filledPropertiesList.item_cable,
        }

        console.log(cableData);
        
        try {
            // TODO add update/upsert endpoint
            const response = await fetch(`${BASE_URL}/map/cables/update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                },
                body: JSON.stringify(cableData),
            })

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCables([...(cables || []), data]);
        } catch(error) {
            console.error('Error upserting cable:', error);
        }

        resetCableInfo(true);
    }

    const saveTempCables = async () => {

        try {
            tempCables.forEach(async item => {
                const response = await fetch(`${BASE_URL}/map/cables/create/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + String(authTokens.access)
                    },
                    body: JSON.stringify(item),
                });
        
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
        
                    const data = await response.json();
                    setCables([...(cables || []), data]);
                    setCableRoutes([...(cableRoutes || []), tempRoutes]);
            })
        } catch(error) {
            console.error('Error saving temp cables:', error);
        }

        resetCableInfo(false);
    }

    const submitTempBox = () => {

        const boxToSave = {
            ...boxInfo,
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            color: filledPropertiesList.color,
            item_box: filledPropertiesList.item_box,
            connected_pole: selectedPole.id
        }

        if (boxToSave.name == null || boxToSave.info == null || boxToSave.item_box == null || boxToSave.color == null) {
            console.log("fields haven't been filled in for the box")
            return;
        }

        console.log("item box id is ");
        console.log(filledPropertiesList.item_box);

        setTempBoxes([...(tempBoxes || []), boxToSave]);
    }

    const saveBoxChanges = () => {
        if (isEdittingBox) {
            upsertBox();
        } else {
            saveTempBoxes();
        }
    }

    const upsertBox = async () =>  {
        console.log("upserting box changes");

        const boxData = {
            id: filledPropertiesList.id,
            name: filledPropertiesList.name,
            info: filledPropertiesList.info,
            color: filledPropertiesList.color,
            item_box: filledPropertiesList.item_box,
        }

        console.log(boxData);
        
        try {
            // TODO add update/upsert endpoint
            const response = await fetch(`${BASE_URL}/map/box/update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                },
                body: JSON.stringify(boxData),
            })

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setBoxes([...(boxes || []), data]);
        } catch(error) {
            console.error('Error upserting box:', error);
        }

        resetBoxInfo(true);
    }

    const saveTempBoxes = async () => {
        try {
            tempBoxes.forEach(async box => {

                const response = await fetch(`${BASE_URL}/map/boxes/create/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + String(authTokens.access)
                    },
                    body: JSON.stringify(box),
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
                setBoxes([...(boxes || []), data]);
                // console.log('Cable created successfully:', data);
    
                // Update connected_boxes count for the pole in frontend
                const updatedPoles = poles.map(p => {
                    if (p.id === selectedPole.id) {
                        return { ...p, connected_boxes: p.connected_boxes + 1 };
                    }
                    return p;
                });
                setPoles(updatedPoles);

            })
        } catch(error) {
            console.error('Error creating box:', error);
        }

        console.log(boxes);

        resetBoxInfo(false);
    }

    const discardTempChanges = () => {
        // reset temp 
        resetCableInfo(true);
        resetPoleInfo(true);
        resetBoxInfo(true);

        setSelectedPole(null);
        setSelectedCable(null);
        setSelectedBox(null);
    }

    const resetCableInfo = (shouldClear) => {
        setTempCables([]);
        setCurrentRoute([]);
        setTempRoutes([]);
        resetProperties(shouldClear);
        setIsEdittingCable(false);
    }

    const resetPoleInfo = (shouldClear) => {
        setTempPoles([]);
        resetProperties(shouldClear);
        setIsEdittingPole(false);
    }

    const resetBoxInfo = (shouldClear) => {
        setTempBoxes([]);
        resetProperties(shouldClear);
        setIsEdittingBox(false);
    }

    const resetProperties = (shouldClear) => {
        if (shouldClear) { 
            setFilledPropertiesList([]);
            setTriggerPropertiesClear(true);
        }
    }

    const customPoleIcon = (poleType, color, isTemporary) => {
        let iconUrl = null;

        switch (poleType) {
            case 1:
                iconUrl = polePin;
                break;
            case 2:
                iconUrl = buildingPin;
                break;
            default:
                iconUrl = manholePin;
                break;
        }

        const iconSize = [30, 30];
        const popupAnchor = [0, -12];
        const className = `custom-pole-icon-${poleType}-${color.replace("#", "")}${isTemporary ? `-temp` : ``}`;

        let style = `
            .${className} {
                background-color: white;
                width: ${iconSize[0]}px;
                height: ${iconSize[1]}px;
                display: inline-block;
                background-image: url(${iconUrl});
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                border: 4px ${isTemporary ? "dotted" : "ridge"} ${color};
            }
        `;
    
        // Creates a <style> element in the document's <head> section and sets its content to the generated CSS styles.
        const styleTag = document.createElement('style');
        styleTag.textContent = style;
        document.head.appendChild(styleTag);
    
        const iconContainer = L.divIcon({
          className: className,
          iconSize: iconSize,
          popupAnchor: popupAnchor
        });
    
        return iconContainer;
    };

    const customBoxIcon = (color) => {
        const iconUrl = "./img/box_1.png";
        const iconSize = [22, 22];
        const popupAnchor = [0, -12];
        const className = `custom-box-icon-${color}`;

        const style = `
            .${className} {
                background-color: ${color};
                border-radius: 50%;
                width: ${iconSize[0]}px;
                height: ${iconSize[1]}px;
                display: inline-block;
                background-image: url(${iconUrl});
                background-size: cover;
            }
        `;

        const styleTag = document.createElement('style');
        styleTag.textContent = style;
        document.head.appendChild(styleTag);

        const iconContainer = L.divIcon({
            className: className,
            iconSize: iconSize,
            popupAnchor: popupAnchor
          });

        return iconContainer;

    }

    const MapEvents = () => {
        useMapEvents({
            click: handleMapClick,
        });

        return null;
    }

    // if (!id) {
    //     return <div>Loading...</div>;
    // }

    useEffect(() => {
        if (!id) {
            console.error("No ID found in URL or localStorage.");
        }
    }, [id]);

    if (!id) {
        return <div>Error: No company ID found. Please select a company.</div>;
    }


    if (isLoading) {
        return <div>Loading...</div>;
    }

    const showBoxWindow = () => {
        setBoxWindow(true);
        setSelectedWindow("boxWindow");
    };

    const showPoleWindow = () => {
        setPoleWindow(true);
        setSelectedWindow("poleWindow");
    };

    const getFooterText = () => {
        if (poleWindow && selectedWindow === "poleWindow") {
            return "Pole: " + selectedPole.name;
        } else if (boxWindow && selectedWindow === "boxWindow") {
            return "Box: " + selectedBox.name;
        } else {
            return "";
        }
    }

    const tempPolylineOptions = (color) => {
        return {
            color: color,
            weight: 5,
            opacity: 0.7,
            dashArray: '10, 10',
        }
    };

    return (
        <Container fluid className="map-container">
            <Toolbar
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                discardTempChanges={discardTempChanges}
                isWindowOpen={poleWindow || boxWindow ? true : false}
            ></Toolbar>
            <MapContainer
                center={[33.75, 36]}
                zoom={9}
                minZoom={3}
                maxBounds={[[-80, -190], [90, 190]]}
                maxBoundsViscosity={1}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                <MapEvents />
                {/* map saved poles */}
                {poles.map((pole, index) => (
                    pole.coords && (
                        <Marker
                            key={index}
                            position={pole.coords}
                            icon={customPoleIcon(pole.item_pole, pole.color, false)}
                            eventHandlers={{
                                click: () => handleMarkerClick(pole, null),
                                dblclick: () => handleMarkerDblClick(pole, null),
                                contextmenu: (e) => handleMarkerRightClick(e, pole, null),
                              }}>
                            {/* <Popup>{`${pole.name}`}</Popup> */}
                        </Marker>
                    )
                ))}
                {/* map temp poles */}
                {tempPoles.map((pole, index) => (
                    pole.location && (
                        <Marker
                            key={index}
                            position={pole.location}
                            icon={customPoleIcon(pole.item_pole, pole.color, true)}
                        >
                        </Marker>
                    )
                ))}
                {cables.map((cable) => (
                    <Polyline
						positions={cable.route.coordinates}
						color={cable.color}
						eventHandlers={{
							click: () => handleMarkerClick(null, cable),
							dblclick: () => handleMarkerDblClick(null, cable),
							contextmenu: (e) => handleMarkerRightClick(e, null, cable),
						}}
					/>
                ))}
                {tempCables.map((cable) => (
                    <Polyline positions={cable.route} pathOptions={tempPolylineOptions(cable.color)} />
                ))}
                <Polyline positions={currentRoute} pathOptions={tempPolylineOptions(filledPropertiesList.color)} />
            </MapContainer>
            <Propbar
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                filledPropertiesList={filledPropertiesList}
                setFilledPropertiesList={setFilledPropertiesList}
                itemPoles={itemPoles}
                itemCables={itemCables}
                itemBoxes={itemBoxes}
                saveTempPoles={savePoleChanges}
                removeTempPoles={resetPoleInfo}
                saveTempCables={saveCableChanges}
                removeTempCables={resetCableInfo}
                saveTempBoxes={saveBoxChanges}
                removeTempBoxes={resetBoxInfo}
                selectedPole={selectedPole}
                selectedCable={selectedCable}
                selectedBox={selectedBox}
                setTriggerPropertiesClear={setTriggerPropertiesClear}
                triggerPropertiesClear={triggerPropertiesClear}
            ></Propbar>
            <div onMouseDown={() => setSelectedWindow("poleWindow")}>
                {poleWindow && <KonvaPoleWindow 
                                    show={poleWindow}
                                    onHide={closeKonvaPoleWindow}
                                    pole={selectedPole}
                                    boxes={boxes}
                                    tempBoxes={tempBoxes}
                                    closeKonvaPoleWindow={closeKonvaPoleWindow}
                                    showBoxWindow={showBoxWindow}
                                    setShowBoxWindowBackButton={setShowBoxWindowBackButton}
                                    setIsEdittingBox={setIsEdittingBox}
                                    setSelectedBox={setSelectedBox}
                                    zIndex={selectedWindow === "poleWindow" ? 1057 : 1055}
                                    selectedTool={selectedTool}
                                    setSelectedTool={setSelectedTool}
                                    discardTempChanges={discardTempChanges}
                                    submitTempBox={submitTempBox}
                                />}
            </div>
            <div onMouseDown={() => setSelectedWindow("boxWindow")}>
                {boxWindow && <BoxContentWindow
                                    show={boxWindow}
                                    onHide={closeKonvaBoxWindow}
                                    closeKonvaBoxWindow={closeKonvaBoxWindow}
                                    showPoleWindow={showPoleWindow}
                                    box={selectedBox}
                                    pole={selectedPole}
                                    authTokens={authTokens}
                                    company={id}
                                    showBoxWindowBackButton={showBoxWindowBackButton}
                                    zIndex={selectedWindow === "boxWindow" ? 1057 : 1055}
                                />
                }
            </div>
            <div className="map-footer">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>{getFooterText()}</div>
            </div>
            {contextMenu.visible && 
                <ContextMenu 
                    x={contextMenu.x} 
                    y={contextMenu.y}
					options={contextMenu.options}
                    onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                />
            }
        </Container>
  )
}

export default Map