import React from "react";
import { ReactComponent as Sun } from "../assets/Sun.svg";
import { ReactComponent as Moon } from "../assets/Moon.svg";

const ThemeSlider = () => {

	const setDarkMode = () => {
		document.querySelector("body").setAttribute("data-theme", "dark");
		localStorage.setItem("selectedTheme", "dark");
	};

	const setLightMode = () => {
		document.querySelector("body").setAttribute("data-theme", "light");
		localStorage.setItem("selectedTheme", "light");
	};

	const selectedTheme = localStorage.getItem("selectedTheme");

	if (selectedTheme === "dark") {
		setDarkMode();
	}

	const toggleTheme = e => {
		if (e.target.checked) setDarkMode();
		else setLightMode();
	};

	return (
		<div className='theme-slider'>
			<input
				className='theme-slider-input'
				type='checkbox'
				id='theme-slider-toggle'
				onChange={toggleTheme}
				defaultChecked={selectedTheme === "dark"}
			/>
			<label className="theme-slider-label" htmlFor="theme-slider-toggle">
				<div className="circle">
					<Sun></Sun>
					<Moon></Moon>                       
				</div>
			</label>
		</div>
	);
};

export default ThemeSlider;
