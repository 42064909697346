import React from 'react'

const ContactUsPage = () => {
  return (
    <div>
      <text>This is the contact us page</text>
    </div>
  )
}

export default ContactUsPage