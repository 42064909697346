import React, { useEffect, useState, createContext, useContext, useRef } from "react";
import AuthContext from './AuthContext'

const CompanyContext = createContext();
export const useCompanyContext = () => useContext(CompanyContext);

const BASE_URL = process.env.REACT_APP_API_URL;

export const CompanyDataProvider = ({ children }) => {
    const [companies, setCompanies] = useState([]);
    const {authTokens} = useContext(AuthContext)
    const fetchInitiatedRef = useRef(false); // Track fetch initiation

    async function fetchCompanies() {

        if (!authTokens || fetchInitiatedRef.current) {
            return;
        }

        fetchInitiatedRef.current = true;

        try {
            const response = await fetch(`${BASE_URL}/api/user-memberships/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch companies');
            }

            const data = await response.json();
            setCompanies(data);

            if (data.length > 0) {
                const selectedCompanyId = localStorage.getItem("selectedCompanyId");
                const companyIds = new Set(data.map(company => company.company.id.toString()));
                // if companies is not empty, and the previous selected company is not part of the fetched companies, set default company to be the first one
                if (!companyIds.has(selectedCompanyId)) {
                    localStorage.setItem("selectedCompanyId", data[0].company.id); // check if this works, should set default selected company to the first one
                }
            } else {
                localStorage.setItem("selectedCompanyId", null);
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        } finally {
            fetchInitiatedRef.current = false; // Reset fetch initiation flag
        }
    }

    // fetch companies 
    useEffect(() => {
        if (authTokens) {
            fetchCompanies();
        } else {
            setCompanies([]);
        }
    }, [authTokens]);

    return (
        <CompanyContext.Provider value={{ companies }}>
            {children}
        </CompanyContext.Provider>
    );
};