import React from 'react'
import { Container, Image } from 'react-bootstrap'
import imgwlcm1 from '../../assets/imgwlcm1.jpeg';
import imgwlcm2 from '../../assets/imgwlcm2.jpeg';
import imgwlcm3 from '../../assets/imgwlcm3.jpeg';
import imgwlcm4 from '../../assets/imgwlcm4.jpeg';
import imgwlcm5 from '../../assets/imgwlcm5.jpeg';

const AboutUsPage = () => {
  return (
    <Container className='about-us-page'>
      <Container className='info-block'>
        <b className='heading'>Streamline Your Network Management with Fibereon</b>
        <Container className='img-text-container'>
          <Image className="about-us-img" src={imgwlcm1} fluid />
          <Container className='img-text'>
            Fibereon is your all-in-one solution for effectively managing and documenting fiber optic cables and splices.
            Say goodbye to the complexities of manual mapping and documentation processes.
            With Fibereon, you can effortlessly streamline your network management tasks, ensuring optimal performance and efficiency every step of the way.
          </Container>
        </Container>
      </Container>
      <Container className='info-block'>
        <b className='heading'>Efficiency Redefined: Why Choose Fibereon?</b>
        <Container className='img-text-container'>
          <Container className='img-text'>
            Experience the power of Fibereon and discover why it's the preferred choice for network operators worldwide.
            Our innovative tools are designed to simplify even the most intricate network mapping and documentation tasks, saving you time, reducing costs, and minimizing downtime.
            With Fibereon, efficiency is redefined, giving you the competitive edge you need in today's fast-paced world.
          </Container>
          <Image className="about-us-img" src={imgwlcm2} />
        </Container>
      </Container>
      <Container className='info-block'>
        <b className='heading'>Empowering Your Network Infrastructure</b>
        <Container className='img-text-container'>
          <Image className="about-us-img" src={imgwlcm3} />
          <Container className='img-text'>
            At Fibereon, we're dedicated to empowering organizations with the tools they need to unlock the full potential of their network infrastructure.
            Our comprehensive solutions are backed by years of industry expertise and a commitment to innovation, ensuring that you have everything you need to succeed in an ever-evolving digital landscape.
            Experience the Fibereon difference and take control of your network like never before.
          </Container>
        </Container>
      </Container>
      <Container className='info-block'>
        <b className='heading'>Simplify Complex Networks with Fibereon</b>
        <Container className='img-text-container'>
          <Container className='img-text'>
            Navigating complex network infrastructures can be daunting, but with Fibereon, it doesn't have to be.
            Our intuitive platform is designed to simplify even the most intricate networks, providing you with the clarity and insights you need to make informed decisions and optimize your operations.
            Say hello to simplicity with Fibereon.
          </Container>
          <Image className="about-us-img" src={imgwlcm4} />
        </Container>
      </Container>
      <Container className='info-block'>
        <b className='heading'>Join the Fibereon Community Today</b>
        <Container className='img-text-container'>
          <Image className="about-us-img" src={imgwlcm5} />
          <Container className='img-text'>
            Ready to revolutionize your network management experience? Join the Fibereon community today and discover a world of possibilities.
            Whether you're a small business or a large enterprise, Fibereon has the tools and expertise to meet your unique needs and exceed your expectations.
            Take the first step towards a smarter, more efficient network with Fibereon.
          </Container>
        </Container>
      </Container>
    </Container>
  )
}

export default AboutUsPage